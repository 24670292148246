import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Divider, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, Select, Typography } from "@material-ui/core";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";

const customReactSelectStyles = {
  menu: (base) => ({
    ...base,
    zIndex: 11, // Set your desired zIndex value
  }),
};

// NOTE: Originally duplicated from @/modules/common/components/selectDisplayDiseaseType
// with extra additions; genders, patient statuses
const SelectDisplayDiseaseType = ({
  masterGenders,
  selectedGenders = [],
  setSelectedGenders = () => {},
  selectedPatientStatuses = [],
  setSelectedPatientStatuses = () => {},
  epidemSelected,
  setEpidemSelected,
}) => {
  const { t, i18n } = useTranslation();
  const [diseaseData, setDiseaseData] = useState([]);

  useEffect(() => {
    axios
      .get("/api/epidemic")
      .then((response) => {
        if (response.data && Array.isArray(response.data.data)) {
          setDiseaseData(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching disease data:", error);
      });
  }, []);

  const handleChangeDisease = (option) => {
    setEpidemSelected(option.value);
  };

  const epidemOptions = useMemo(() => {
    if (!diseaseData) return [];
    return diseaseData.map((disease) => ({
      label: disease[`epidem_report_name_${i18n.language}`],
      value: disease.epidem_report_id,
    }));
  }, [diseaseData, i18n.language]);

  const epidemValueToOption = useMemo(() => {
    return epidemOptions.reduce(
      (obj, option) => ({
        ...obj,
        [option.value]: option,
      }),
      {}
    );
  }, [epidemOptions]);

  const epidemValues = useMemo(() => {
    if (!epidemSelected) return null;
    return epidemValueToOption[epidemSelected];
  }, [epidemValueToOption, epidemSelected]);

  const handleChangePatientStatuses = (values) => {
    setSelectedPatientStatuses(values);
  };

  const handleChangeGenders = (values) => {
    setSelectedGenders(values);
  };

  const genderOptions = useMemo(() => {
    return Object.entries(masterGenders).map(([key, value]) => ({ label: t(value), value: key }));
  }, [masterGenders, t]);

  useEffect(() => {
    setSelectedGenders((genders) =>
      genders.map((gender) => genderOptions.find((option) => option.value === gender.value))
    );
  }, [i18n.language, genderOptions, setSelectedGenders]);

  const statusOptions = useMemo(() => (
    [
      {
        value: "treated",
        label: t("TREATED"),
      },
      {
        value: "death",
        label: t("DEAD"),
      },
      {
        value: "unknown",
        label: t("UNKNOWN"),
      },
    ]
  ), [t]);

  useEffect(() => {
    setSelectedPatientStatuses((statuses) =>
      statuses.map((status) => statusOptions.find((option) => option.value === status.value))
    );
  }, [i18n.language, setSelectedPatientStatuses, statusOptions]);

  return (
    <Paper style={{ flex: 1, margin: "0 10px", padding: "20px" }}>
      <Typography variant="h5" align="center">
        {t("DATA_DISPLAY_FORMAT")}
      </Typography>
      <Divider style={{ margin: "10px 0" }} />
      <FormLabel style={{ display: "block", marginTop: "8px", marginBottom: "4px" }}>{t("SELECT_DISEASE")}</FormLabel>
      <ReactSelect
        placeholder={t("SELECT_DISEASE")}
        value={epidemValues}
        onChange={handleChangeDisease}
        options={epidemOptions}
        styles={customReactSelectStyles}
      />
      <FormLabel style={{ display: "block", marginTop: "8px", marginBottom: "4px" }}>{t("SELECT_GENDER")}</FormLabel>
      <ReactSelect
        placeholder={t("SELECT_GENDER")}
        value={selectedGenders}
        onChange={handleChangeGenders}
        options={genderOptions}
        isMulti
        closeMenuOnSelect={false}
        styles={customReactSelectStyles}
      />
      <FormLabel style={{ display: "block", marginTop: "8px", marginBottom: "4px" }}>{t("SELECT_STATUS")}</FormLabel>
      <ReactSelect
        placeholder={t("SELECT_STATUS")}
        value={selectedPatientStatuses}
        onChange={handleChangePatientStatuses}
        isMulti
        options={statusOptions}
        closeMenuOnSelect={false}
        styles={customReactSelectStyles}
      />
    </Paper>
  );
};

export default SelectDisplayDiseaseType;
