import { useContext, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import ReactSelect from "react-select";
import { Button, Typography, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AuthContext } from "@/App";
import trend1 from '@/assets/trend1.jpg';
import trend2 from '@/assets/trend2.jpg';
import { useTranslation } from "react-i18next";

// import * as React from 'react';

const News = () => {
  const { t, i18n } = useTranslation();
  const [selectedValues, setSelectedValues] = useState([]);
  const [diseaseData, setDiseaseData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const [selectedGTrend, setSelectedGTrend] = useState([]);

  const renderGoogleTrendsWidget = (type, keyword, geo, time, containerId) => {
    const containerEl = document.getElementById(containerId);
    if(!containerEl) return;
    containerEl.innerHTML = "";
    window.trends.embed.renderExploreWidgetTo(
      containerEl,
      type,
      {
        comparisonItem: [{ keyword: keyword, geo: geo, time: time }],
        category: 0,
        property: "",
      },
      {
        exploreQuery: `geo=${geo}&q=${keyword}&hl=en&date=${time}`,
        guestPath: "https://trends.google.com:443/trends/embed/",
      },
    );
  };

  // Function to render all Google Trends widgets
  const renderAllGoogleTrendsWidgets = () => {
    const queryString = selectedGTrend.length > 0 ? "" + selectedGTrend.join(" ") : "Epidemic";
    renderGoogleTrendsWidget("TIMESERIES", queryString, "TH", "today 12-m", "widget-timeseries");
    renderGoogleTrendsWidget("GEO_MAP", queryString, "TH", "today 12-m", "widget-geomap");
    // Add more widgets as needed
  };

  const loadGoogleTrendsScript = () => {
    if (!window.trends) {
      const script = document.createElement("script");
      script.src = "https://ssl.gstatic.com/trends_nrtr/3523_RC02/embed_loader.js";
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => {
        renderAllGoogleTrendsWidgets();
      };
    }
  };

  // Load the Google Trends script when the component mounts
  const loaded = useRef(false);
  useEffect(() => {
    if(!loaded.current) {
      loaded.current = true;
      loadGoogleTrendsScript();
    }
  }, []);

  useEffect(() => {
    if (window.trends) {
      console.log(selectedGTrend);
      renderAllGoogleTrendsWidgets();
    }
  }, [selectedGTrend]);

  const handleChangeIframe = ({ value }) => {
    setShowIframe(false);
    setSelectedValue(value);
  };

  const getIframeSrc = () => {
    const baseUrls = {
      bing: "https://bing.com/search?q=",
      webcrawler: "https://www.webcrawler.com/serp?q=",
      google: "https://www.google.com/search?q=",
      sd: "https://www.sciencedirect.com/search?qs=",
      pubmed: "https://pubmed.ncbi.nlm.nih.gov/?term=",
      gs: "https://scholar.google.com/scholar?hl=en&as_sdt=0%2C5&btnG=&q=",
      googletrend: "https://trends.google.com/trends/explore?geo=TH&hl=en&q=",
    };
    return baseUrls[selectedValue] || "";
  };

  useEffect(() => {
    axios
      .get("/api/epidemic", {
        headers: {
          Authorization:
            "ya29.a0AfB_byClA5ly7MsmkrDp-tETkJ4ZrJUP_Gh7sNhANQUIV7QsUhDBO79HPA8Ukg1oiN10Sl1Zw1Fonw3hUBcNCA_vK0Z95k5zyXLTf4SpfCJcCgLyDc1dNShndjsXZSDoqIDASt7HJsTMqTLCNDxi0bDeyF7ozzUJUdW9aCgYKARkSARESFQGOcNnCzndUPp9S-7xiD1wMRErtsw0171",
        },
      })
      .then((response) => {
        if (response.data && Array.isArray(response.data.data)) {

          setDiseaseData([{
            epidem_report_name_en: 'Epidemic',
            epidem_report_name_th: 'โรคระบาด',
          },{
            epidem_report_name_en: 'Pandemic',
            epidem_report_name_th: 'โรคระบาดใหญ่',
          }].concat(response.data.data.map(epidemic => ({
            ...epidemic,
            epidem_report_name_th: epidemic.epidem_report_name_th.split('[')[0].trim(),
          }))));
        }
      })
      .catch((error) => {
        console.error("Error fetching disease data:", error);
      });
  }, []);

  const handleChange = (option) => {
    setShowIframe(false);
    setSelectedValues([option.value]);
  };

  const handleChangeGTrend = (option) => {
    // setShowIframe(false);
    setSelectedGTrend([option.value]);
  };

  const { checkAndRedirect } = useContext(AuthContext);

  const handleButtonClick = () => {
    checkAndRedirect()
      .then(() => {
        const url = getIframeSrc(selectedValue);
        if (selectedValue == "bing" || selectedValue == "webcrawler") {
          setShowIframe(true);
        } else if (url) {
          // Construct the full URL with the selected value for searching
          const fullUrl = `${url}${encodeURIComponent(selectedValues.join(" "))}`;
          window.open(fullUrl, "_blank");
        }
      })
      .catch(() => {});
  };

  const queryString = selectedValues.length > 0 ? "" + selectedValues.join(" ") : "";

  const diseaseOptions = useMemo(
    () =>
      // diseaseData.push({
      //   label: 'Epidemic',
      //   value: 'Epidemic',
      // },{
      //   label: 'Pandemic',
      //   value: 'Pandemic',
      // }),
      diseaseData.map((disease) => ({
        label: disease[`epidem_report_name_${i18n.language}`],
        value: disease[`epidem_report_name_${i18n.language}`],
      })),
    [diseaseData, i18n.language]
  );

  const diseaseValueToOption = useMemo(
    () =>
      diseaseOptions.reduce(
        (obj, option) => ({
          ...obj,
          [option.value]: option,
        }),
        {}
      ),
    [diseaseOptions]
  );

  const diseaseValues = useMemo(
    () => selectedValues.map((value) => diseaseValueToOption[value]),
    [selectedValues, diseaseValueToOption]
  );

  const gTrendValues = useMemo(
    () => selectedGTrend.map((value) => diseaseValueToOption[value]),
    [selectedGTrend, diseaseValueToOption]
  );

  const searchServiceOptions = useMemo(
    () => [
      { label: "Webcrawler", value: "webcrawler" },
      { label: "Bing", value: "bing" },
      { label: "Google", value: "google" },
      { label: "Google Scholar", value: "gs" },
      { label: "PubMed", value: "pubmed" },
      { label: "Google Trend", value: "googletrend" },
    ],
    []
  );

  const searchServiceValue = useMemo(
    () => searchServiceOptions.find((option) => option.value === selectedValue),
    [searchServiceOptions, selectedValue]
  );

  const handleGoogleTrendEpidemic = () => {
    window.open('https://trends.google.com/trends/explore?geo=TH&hl=en&q=epidemic', '_blank');
  };

  const handleGoogleTrendPandemic = () => {
    window.open('https://trends.google.com/trends/explore?geo=TH&hl=en&q=pandemic', '_blank');
  };

  const handleSocialReport = () => {
    window.open('https://docs.google.com/spreadsheets/d/1oQJizlht7OaOcmpKp_63ayA6H4oFf55Q/edit?usp=drive_link', '_blank');
  };
  
  return (
    <div>
      <div style={{ width: "100%", margin: "0 auto" }}>
        <Accordion defaultExpanded style={{ marginBottom: "20px"}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h4" style={{ marginBottom: "0px" }}>
              {t('SEARCH_NEWS_AND_RESEARCHES')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
          <div>
          {/* react-select Select */}
          <div style={{
              display: "flex",
              gap: "8px",
              width: "100%"
            }}
          >
            <ReactSelect
              styles={{
                container: (baseStyles) => ({
                  ...baseStyles,
                  flex: "0 1 480px",
                  height: "fit-content",
                  minWidth: "400px"
                }),
              }}
              options={diseaseOptions}
              value={diseaseValues}
              onChange={handleChange}
              placeholder={t('KEYWORD_PLACEHOLDER')}
            />
            <ReactSelect
              styles={{
                container: (baseStyles) => ({
                  ...baseStyles,
                  flex: "0 1 240px",
                  height: "fit-content",
                  minWidth: "250px"
                }),
              }}
              options={searchServiceOptions}
              value={searchServiceValue}
              onChange={handleChangeIframe}
              placeholder={t('SEARCH_ENGINE_PLACEHOLDER')}
            />
            <Button
              style={{
                flex: "0 1 auto",
                alignSelf: "flex-start",
              }}
              variant="contained"
              color="primary"
              onClick={handleButtonClick}
              disabled={!selectedValues || !selectedValue}
            >
              {t('SEARCH')}
            </Button>
            
          </div>

          {selectedValue && showIframe && (selectedValue === "bing") && (
            <div>
              <p>
              *คลิกขวาในรายการที่ต้องการแล้วเลือก แท็บใหม่ หรือ Open Link in New Tab
              </p>
              <iframe
                src={`${getIframeSrc()}${queryString}`}
                width="100%"
                height="600px"
                frameBorder="0"
                title="Search Iframe"
              ></iframe>
            </div>
          )}
          {selectedValue && showIframe && (selectedValue === "webcrawler") && (
            <iframe
              src={`${getIframeSrc()}${queryString}`}
              width="100%"
              height="600px"
              frameBorder="0"
              title="Search Iframe"
            ></iframe>
          )}
          </div>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded style={{ marginBottom: "20px"}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h4" style={{ marginBottom: "0px" }}>
              {t('GOOGLE_TRENDS')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
          <div style={{flex: "1"}}>
            <div style={{
                display: "flex",
                gap: "8px",
                width: "100%"
              }}
            >
              <ReactSelect
                styles={{
                  container: (baseStyles) => ({
                    ...baseStyles,
                    flex: "0 1 480px",
                    height: "fit-content",
                    minWidth: "400px"
                  }),
                }}
                options={diseaseOptions}
                value={gTrendValues}
                onChange={handleChangeGTrend}
                placeholder={t('GOOGLE_TRENDS_PLACEHOLDER')}
              />
              </div>
            <div style={{ maxWidth: '100%' }}>
              <p>{t('EPIDEMIC_TREND_12_MONTHS_AGO')}</p>
              <div id="widget-timeseries" />
              <div id="widget-geomap" />
            </div>
            </div>
            </AccordionDetails>
          </Accordion>

        
        {/* <Button
            style={{
              flex: "0 1 auto",
              alignSelf: "flex-start",
            }}
            variant="contained"
            color="primary"
            onClick={handleGoogleTrendEpidemic}
          >
            Epidemic
          </Button>
          <Button
            style={{
              flex: "0 1 auto",
              alignSelf: "flex-start",
              marginLeft: '10px'
            }}
            variant="contained"
            color="primary"
            onClick={handleGoogleTrendPandemic}
          >
            Pandemic
          </Button> */}

        <Accordion defaultExpanded style={{ marginBottom: "20px"}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h4" style={{ marginBottom: "0px" }}>
              {t('ECONOMIC_REPORT')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
              <div>
            <iframe src="https://www.nesdc.go.th/ewt_news.php?nid=12567" width="1010px" height="1250px" frameBorder="0" title="GDP"></iframe>
          </div>
          </AccordionDetails>
        </Accordion>
      {/* Google Trends widgets containers */}
      {/* <div id="widget-timeseries" className="trends-widget"></div>
      <div id="widget-geomap" className="trends-widget"></div> */}

        <Accordion defaultExpanded style={{ marginBottom: "50px"}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h4" style={{ marginBottom: "0px" }}>
              {t('SOCIAL_REPORT')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
              <div>
              <Button
                style={{
                  flex: "0 1 auto",
                  alignSelf: "flex-start",
                }}
                variant="contained"
                color="primary"
                onClick={handleSocialReport}
              >
                {t('SOCIAL_REPORT')}
            </Button>
            {/* <iframe src="https://docs.google.com/spreadsheets/d/1oQJizlht7OaOcmpKp_63ayA6H4oFf55Q/edit?usp=drive_link" width="1200px" height="1250px" frameBorder="0" title="GDP"></iframe> */}
          </div>
          </AccordionDetails>
        </Accordion>
    </div>
    </div>
  );
};

export default News;