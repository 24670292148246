import { Tab, withStyles } from "@material-ui/core";

// Define the custom styles
const StyledTab = withStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    color: 'black',
    marginLeft: '10px',  // Add margin to the left
    marginRight: '10px', // Add margin to the right
    padding: '10px',     // Adjust padding if necessary
    border: '1px solid #ccc', // Add border for a box appearance
    borderRadius: '4px',     // Rounded corners
    "&$selected": {
      backgroundColor: 'grey',
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: 'darkgrey',
      },
    },
    "&:first-child": {
      marginLeft: 0,    // Remove the left margin for the first tab
    },
    "&:last-child": {
      marginRight: 0,   // Remove the right margin for the last tab
    }
  },
  selected: {},
}))(Tab);

export default StyledTab;
