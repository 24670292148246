import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import axios from "axios";
import { Bar, Chart } from "react-chartjs-2";
import { AuthContext } from "@/App";
import ReactSelect from "react-select";
import { FormLabel } from "@material-ui/core";
import {
  EpidemicReportTableMD5,
  EpidemicReportTableMD5Gender,
  EpidemicReportTableMD5Group,
  EpidemicReportTableMD5GroupDynamic,
  EpidemicReportTableMD5Area,
  EpidemicReportTableMD5Age,
  EpidemicReportTableMD5Nationality,
  MyPieChart,
} from "./components";
import { TableNotFound } from "@/modules/common/components";
import { useTranslation } from 'react-i18next';
import nationality from "@/modules/interested/routes/page/tags/nationality";
import { thToKey } from "@/modules/common/utils/i18n";
import { useKeyTranslate } from "@/modules/common/utils/useKeyTranslate";

function CombinedEpidemicCharts({
  selectedYearValue,
  selectedWeekValue,
  selectedMonthValue,
  selectedEndYearValue,
  selectedEndWeekValue,
  selectedEndMonthValue,
  selectedTime,
  selectedOutputType,
  selectedFilterTag,
  searchTrigger,
  selectedDisplay,
  selectedCompareOption,
  setDownloadData,
  selectedStatus,
  setSelectedStatus,
  selectedOptions,
  setSelectedOptions,
}) {
  const [jsonData, setJsonData] = useState(null);
  const [jsonData2, setJsonData2] = useState(null);
  const [customLegend, setCustomLegend] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [isPredicted, setIsPredicted] = useState(false);
  const { t, i18n } = useTranslation();

  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedEpidemic, setSelectedEpidemic] = useState([]);
  const [dead, setDead] = useState([]);
  const [infected, setInfected] = useState([]);

  const keyTranslate = useKeyTranslate(selectedFilterTag, t);
  const KEY = selectedOutputType === "1" ? `epidem_report_name_${i18n.language}` : `epidem_group_name_${i18n.language}`

  useEffect(() => {
    const dead = jsonData?.data
    .filter(item => item[KEY] === selectedEpidemic?.label) // Filter items
    .map(item => item.details.map(d => d.death));

    const infected = jsonData?.data
    .filter(item => item[KEY] === selectedEpidemic?.label) // Filter items
    .map(item => item.details.map(d => d.infected));

    setDead(dead);
    setInfected(infected);
    // console.log(dead, infected);
  }, [selectedEpidemic]);

  const customReactSelectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 11, // Set your desired zIndex value
    }),
  };

  const [selectedGender, setSelectedGender] = useState([{
    "value": "ชาย",
    "label": t('MALE'),
    "key": "male"
  }, {
      "value": "หญิง",
      "label": t('FEMALE'),
      "key": "female"
  }]);

  const [selectedAge, setSelectedAge] = useState([
    {
      value: "0-4",
      label: "0-4"
    },
    {
      value: "5-14",
      label: "5-14"
    },
    {
      value: "15-24",
      label: "15-24"
    },
    {
      value: "25-34",
      label: "25-34"
    },
    {
      value: "35-44",
      label: "35-44"
    },
    {
      value: "45-54",
      label: "45-54",
    },
    {
      value: "55-64",
      label: "55-64",
    },
    {
      value: "65+",
      label: "65+"
    }
  ]
);

const [selectedNationality, setSelectedNationality] = useState([
  {
    value: "1 - ไทย",
    label: "1 - ไทย"
  },
  {
    value: "2 - จีน/ฮ่องกง/ไต้หวัน",
    label: "2 - จีน/ฮ่องกง/ไต้หวัน"
  },
  {
    value: "3 - พม่า",
    label: "3 - พม่า"
  },
  {
    value: "4 - มาเลเซีย",
    label: "4 - มาเลเซีย"
  },
  {
    value: "5 - กัมพูชา",
    label: "5 - กัมพูชา"
  },
  {
    value: "6 - ลาว",
    label: "6 - ลาว",
  },
  {
    value: "7 - เวียดนาม",
    label: "7 - เวียดนาม",
  },
  {
    value: "8 - อื่น ๆ",
    label: "8 - อื่น ๆ"
  },
  {
    value: "รวมต่างชาติ",
    label: "รวมต่างชาติ"
  }
]
);


  // This is a multiplexed selected options from various scenario
  const multiplexedSelectedOptions = useMemo(() => {
    // eslint-disable-next-line eqeqeq
    if(selectedOutputType == "1") {
      // Individual
      switch(selectedFilterTag) {
        case "gender": return selectedGender;
        case "age": return selectedAge;
        case "nationality": return selectedNationality;
        case "area":
        case "health-area":
          return selectedArea;
        default:
          console.error("Invalid selected filter tag");
      }
    } else {
      // Group
      return selectedOptions;
    }
  }, [selectedOutputType, selectedFilterTag, selectedGender, selectedAge, selectedNationality, selectedArea, selectedOptions]);
  
  const noSelectedStatusOrHaving = useCallback(
    (value) => {
      return selectedStatus.length === 0 || !!selectedStatus.find((status) => status.value === value);
    },
    [selectedStatus]
  );

  const updateCustomLegend = useCallback(() => {
    const isPredicted = getIsPredicted();
    const legendHtml = (
      <div style={{ textAlign: 'center' }}>
        {noSelectedStatusOrHaving('infected_header') && (
          <>
            <span style={{ display: 'inline-block', marginRight: '10px' }}>
              <span style={{ backgroundColor: 'darkred', width: '20px', height: '20px', display: 'inline-block' }}></span>{" "}
              {t('CASE')}
            </span>
            {isPredicted && 
            <span style={{ display: 'inline-block', marginRight: '10px' }}>
              <span style={{ backgroundColor: '#c58d8d', width: '20px', height: '20px', display: 'inline-block' }}></span>{" "}
              {t('EXPECTED_INFECTED')}
            </span>
            }
          </>
        )}
        {noSelectedStatusOrHaving('deaths_header') && (
          <>
            <span style={{ display: 'inline-block' }}>
              <span style={{ backgroundColor: 'grey', width: '20px', height: '20px', display: 'inline-block' }}></span>{" "}
              {t('DEAD')}
            </span>
            {isPredicted && 
            <span style={{ display: 'inline-block' }}>
              <span style={{ backgroundColor: 'lightgrey', width: '20px', height: '20px', display: 'inline-block' }}></span>{" "}
              {t('EXPECTED_DEAD')}
            </span>
            }
          </>
        )}
      </div>
    );
    setCustomLegend(legendHtml);
  }, [noSelectedStatusOrHaving]);

  const getTimeFrame = (time) => {
    if (time === "1") return "year";
    if (time === "2") return "month";
    if (time === "3") return "week";
    return "week"; // default value
  };

  const getOutputType = (type) => {
    return type === "1" ? "indiv" : "group";
  };

  const genders = ["", "ชาย", "หญิง"];

  useEffect(() => {
    updateCustomLegend();
  }, [updateCustomLegend, i18n.language]);

  const getIsPredicted = () => {
    return jsonData?.data?.some(group =>
      group.details.some(detail => detail.isPrediction)
    );
  }

  const getGroupDetail = async (groupId) => {
    const requestBody = {
      timeFrame: getTimeFrame(selectedTime), // year, month, week
      startYear: parseInt(selectedYearValue),
      startWeek: parseInt(selectedWeekValue),
      startMonth: parseInt(selectedMonthValue),
      endYear: parseInt(selectedEndYearValue),
      endWeek: parseInt(selectedEndWeekValue),
      endMonth: parseInt(selectedEndMonthValue),
      filterTag: selectedFilterTag, // all, gender, nationality, age, or area
      outputType: "indiv", // indiv or group
      filterEpidemByGroupId: groupId,
      isCompareOption: selectedCompareOption == "2",
    };

    try {
      const response = await axios.post("/api/overviews", requestBody);
      return {
        data: response.data,
      };
    } catch (error) {
      console.error("Error fetching group details:", error);
      return {
        data: `Details for group ${groupId}`,
      };
    }
  };

  useEffect(() => {
    setJsonData(null);
    setJsonData2(null);
    setDownloadData(null);
  }, [searchTrigger, selectedTime, selectedFilterTag, selectedCompareOption, selectedOutputType]);

  const { checkAndRedirect } = useContext(AuthContext);

  useEffect(() => {
    let timeoutId;
    checkAndRedirect()
      .then(() => {
        const requestBody = {
          timeFrame: getTimeFrame(selectedTime), // year, month, week
          startYear: parseInt(selectedYearValue),
          startWeek: parseInt(selectedWeekValue),
          startMonth: parseInt(selectedMonthValue),
          endYear: parseInt(selectedEndYearValue),
          endWeek: parseInt(selectedEndWeekValue),
          endMonth: parseInt(selectedEndMonthValue),
          filterTag: selectedFilterTag, // all, gender, nationality, age, or area
          outputType: getOutputType(selectedOutputType), // indiv or group
          isCompareOption: selectedCompareOption == "2",
        };

        if (parseInt(selectedYearValue) > 0) {
          setIsLoading(true);

          timeoutId = setTimeout(() => {
            if (isLoading) {
              setIsLoading(false);
            }
          }, 5000);

          axios
            .post("/api/overviews/total-infected", requestBody)
            .then((response) => {
              const data = { data: [{ details: response.data.data }] };
              setJsonData2(data);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
          axios
            .post("/api/overviews", requestBody)
            .then((response) => {
              setDownloadData(response.data);
              setJsonData(response.data);            
            })
            .catch((error) => {
              setIsLoading(false);
              console.error("Error fetching data:", error);
            })
            .finally(() => {
              setIsLoading(false); // Stop loading
            });
        }
        // Fetch data from the API when the component mounts
      })
      .catch(() => {});
    return () => timeoutId && clearTimeout(timeoutId);
  }, [searchTrigger]);

  if (isLoading && searchTrigger > 0) {
    return (
      <div className="loading-overlay">
        <div className="spinner"></div>
      </div>
    ); // Replace with your spinner component or styling
  }

  let weeks = [];
  let infectedCounts = [];
  let deathCounts = [];
  let groupIds = [];
  let groupNameMap = {th: {}, en: {}};
  let weeks2 = [];
  let infectedCounts2 = [];
  let deathCounts2 = [];
  let groupIds2 = [];
  let groupNameMap2 = {th: {}, en: {}};
  let categories2 = [];

  let pieData = {};

  let infectedBackgroundColors = [];
  let infectedBackgroundColors2 = [];
  let deathBackgroundColors = [];
  let deathBackgroundColors2 = [];

  if (!jsonData || !jsonData.data || !Array.isArray(jsonData.data)) {
    return <TableNotFound />;
  }

  if (!jsonData2 || !jsonData2.data || !Array.isArray(jsonData2.data)) {
    return <TableNotFound />;
  }

  const handleChangeEpidemic = (values) => {
    setSelectedEpidemic(values);
  }

   const _groupValues = Array.from(
    new Set(jsonData.data.flatMap(report => report[KEY]))
  );

  const prepareOptions = (arr) => {
    return arr.map(item => ({ label: item, value: item }));
  }  

  const BarDropdown = () => {
    return <div> 
      <ReactSelect
        placeholder={t('DISPLAY')}
        value={selectedEpidemic}
        onChange={handleChangeEpidemic}
        options={prepareOptions(_groupValues)}
        isMulti={false}
        closeMenuOnSelect={true}
        styles={customReactSelectStyles}
        isClearable={true}
      />
      </div>
  }

  const tagToField = {
    gender: 'gender',
    age: 'age_range',
    nationality: 'nationality',
    'health-area': 'health_area',
    area: 'area',
  }

  jsonData.data.forEach((group) => {

    // Sample last 3 weeks
    const detailData = group.details;
    let sortKey = 0
    detailData.forEach((detail) => {
      let dataLabel = "";
      sortKey = 0;
      if (selectedTime === "1") {
        sortKey = detail.year
      } else if (selectedTime === "2") {
        sortKey = detail.month_id * 10000 + detail.year
      } else {
        sortKey = detail.week * 10000 + detail.year
      }

      sortKey += (detail.sort_id || 0) * 1000000;
      if (getOutputType(selectedOutputType) === "indiv") {
        if (selectedTime === "1") {
          dataLabel = `${detail.year}`;
        } else if (selectedTime === "2") {
          dataLabel = `${detail.year}/${t(thToKey?.[detail.month])}`;
        } else {
          dataLabel = `${detail.year}/${detail.week}`;
        }
      } else {
        if (selectedTime === "1") {
          if (selectedFilterTag == "gender") {
            dataLabel = `${detail.year}_${genders[detail.gender]}`;
          } else if (selectedFilterTag == "age") {
            dataLabel = `${detail.year}_${detail.age_range}`;
          } else if (selectedFilterTag == "nationality") {
            dataLabel = `${detail.year}_${detail.nationality}`;
          } else if (selectedFilterTag == "area") {
            dataLabel = `${detail.year}_${detail.area}`;
          }  else if (selectedFilterTag == "health-area") {
            dataLabel = `${detail.year}_${detail.health_area}`;
          } else {
            dataLabel = `${detail.year}`;
          }
        } else if (selectedTime === "2") {
          if(selectedFilterTag === 'all') {
             dataLabel = `${detail.year}/${t(thToKey?.[detail.month])}`;
          } else {
            const field = tagToField[selectedFilterTag];
            let groupValueLabel = detail[field];
            if(selectedFilterTag === 'gender') {
              groupValueLabel = genders[detail[field]];
            }
             dataLabel = `${detail.year}/${t(thToKey?.[detail.month])}_${groupValueLabel}`;
          }
        } else {
          if(selectedFilterTag === 'all') {
            dataLabel = `${detail.year}/${detail.week}`;
          } else {
            const field = tagToField[selectedFilterTag];
            let groupValueLabel = detail[field];
            if(selectedFilterTag === 'gender') {
              groupValueLabel = genders[detail[field]];
            }
            dataLabel = `${detail.year}/${detail.week}_${groupValueLabel}`;
          }
        }
      }
      detail.dataLabel = dataLabel;
      detail.sortKey = sortKey;

      if (selectedFilterTag == "gender" && multiplexedSelectedOptions.some(gender => genders[detail.gender]?.includes(gender?.value))) {
        const genderKey = genders[detail.gender];
        // Check if the gender already exists in pieData
        if (!pieData[genderKey]) {
            // If not, initialize it
            pieData[genderKey] = { 'infected': 0, 'death': 0 };
        }
        // Add the current detail's counts to the total
        pieData[genderKey].infected += detail.infected;
        pieData[genderKey].death += detail.death;
      } else if (selectedFilterTag == "age" && multiplexedSelectedOptions.some(age => detail.age_range?.includes(age?.value))) {
        const genderKey = detail.age_range;
        // Check if the gender already exists in pieData
        if (!pieData[genderKey]) {
            // If not, initialize it
            pieData[genderKey] = { 'infected': 0, 'death': 0 };
        }
        // Add the current detail's counts to the total
        pieData[genderKey].infected += detail.infected;
        pieData[genderKey].death += detail.death;
      } else if (selectedFilterTag == "nationality" && multiplexedSelectedOptions.some(nationality => detail.nationality?.includes(nationality?.value))) {
        const genderKey = detail.nationality;
        // Check if the gender already exists in pieData
        if (!pieData[genderKey]) {
            // If not, initialize it
            pieData[genderKey] = { 'infected': 0, 'death': 0 };
        }
        // Add the current detail's counts to the total
        pieData[genderKey].infected += detail.infected;
        pieData[genderKey].death += detail.death;
      } else if (selectedFilterTag == "area" && multiplexedSelectedOptions.some(area => detail.area === area?.value)) {
        const genderKey = detail.area;
        // Check if the gender already exists in pieData
        if (!pieData[genderKey]) {
            // If not, initialize it
            pieData[genderKey] = { 'infected': 0, 'death': 0 };
        }
        // Add the current detail's counts to the total
        pieData[genderKey].infected += detail.infected;
        pieData[genderKey].death += detail.death;
      } else if (selectedFilterTag == "health-area" && multiplexedSelectedOptions.some(area => detail.health_area?.includes(area?.value))) {
        const genderKey = detail.health_area;
        // Check if the gender already exists in pieData
        if (!pieData[genderKey]) {
            // If not, initialize it
            pieData[genderKey] = { 'infected': 0, 'death': 0 };
        }
        // Add the current detail's counts to the total
        pieData[genderKey].infected += detail.infected;
        pieData[genderKey].death += detail.death;
      } else if (selectedFilterTag == "all") {
        const genderKey = t('OVERVIEW');
        // Check if the gender already exists in pieData
        if (!pieData[genderKey]) {
            // If not, initialize it
            pieData[genderKey] = { 'infected': 0, 'death': 0 };
        }
        // Add the current detail's counts to the total
        pieData[genderKey].infected += detail.infected;
        pieData[genderKey].death += detail.death;
      }

      if (detail.isPrediction) {
        infectedBackgroundColors.push("#c58d8d"); // Color for predicted infected
        deathBackgroundColors.push("lightgrey");
        // deathCounts2.push({ value: detail.death, isPrediction: true });
      } else {
        infectedBackgroundColors.push("darkred"); // Default color for actual infected data
        // deathCounts2.push({ value: detail.death, isPrediction: false });
        deathBackgroundColors.push("grey");
      }
    });

    if (selectedCompareOption != 1) {
      detailData.sort((a,b) => a.sortKey - b.sortKey);
    }

    detailData.forEach((detail) => {
      weeks.push(detail.dataLabel);
      infectedCounts.push(detail.infected);
      deathCounts.push(detail.death);
    });
    
    const name_en = group.epidem_report_name_en == undefined ? group.epidem_group_name_en : group.epidem_report_name_en;
    const name_th = group.epidem_report_name_th == undefined ? group.epidem_group_name_th : group.epidem_report_name_th;
    const gid = group.epidem_report_id == undefined ? group.epidem_group_id : group.epidem_report_id;
    groupNameMap["th"][gid] = name_th
    groupNameMap["en"][gid] = name_en
    groupIds.push(gid);

    // Add spacing for visual separation between groups
    weeks.push("");
    infectedCounts.push(null);
    deathCounts.push(null);
    // deathCounts.push(Math.max(...deathCounts) * 1.4);
    infectedBackgroundColors.push("rgba(0,0,0,0)"); // Transparent for spacing
    deathBackgroundColors.push("rgba(0,0,0,0)");
  });


  jsonData2.data.forEach((group) => {
    // Sample last 3 weeks
    const detailData = group.details;
    let sortKey = 0

    detailData.forEach((detail) => {
      sortKey = 0;
      let dataLabel = "";
      if (selectedTime === "1") {
        dataLabel = `${detail.year}`
        sortKey = detail.year
      } else if (selectedTime === "2") {
        dataLabel = `${detail.year}/${t(thToKey?.[detail.month])}`;
        sortKey = detail.month_id * 10000 + detail.year
      } else {
        dataLabel = `${detail.year}/${detail.week}`
        sortKey = detail.week * 10000 + detail.year
      }
      sortKey += (detail.sort_id||0) * 1000000;
      if (selectedFilterTag == "gender") {
        dataLabel = `${dataLabel}_${genders[detail.gender]}`
      } else if (selectedFilterTag == "age") {
        dataLabel = `${dataLabel}_${detail.age_range}`
      } else if (selectedFilterTag == "nationality") {
        dataLabel = `${dataLabel}_${detail.nationality}`
      } else if (selectedFilterTag == "area") {
        dataLabel = `${dataLabel}_${detail.area}`
      }
      else if (selectedFilterTag == "health-area") {
        dataLabel = `${dataLabel}_${detail.health_area}`
      }
      detail.sortKey = sortKey;
      detail.dataLabel = dataLabel
      if (detail.isPrediction) {
        infectedBackgroundColors2.push("#c58d8d"); // Color for predicted infected
        deathBackgroundColors2.push("lightgrey");
        // deathCounts2.push({ value: detail.death, isPrediction: true });
      } else {
        infectedBackgroundColors2.push("darkred"); // Default color for actual infected data
        // deathCounts2.push({ value: detail.death, isPrediction: false });
        deathBackgroundColors2.push("grey");
      }
    });
    if (selectedCompareOption != 1) {
      detailData.sort((a,b) => a.sortKey - b.sortKey);
    }

    detailData.forEach((detail) => {
      weeks2.push(detail.dataLabel);
      infectedCounts2.push(detail.infected);
      deathCounts2.push(detail.death);
    });

    const name_en = group.epidem_report_name_en == undefined ? group.epidem_group_name_en : group.epidem_report_name_en;
    const name_th = group.epidem_report_name_th == undefined ? group.epidem_group_name_th : group.epidem_report_name_th;
    const gid = group.epidem_report_id == undefined ? group.epidem_group_id : group.epidem_report_id;
    groupNameMap2["th"][gid] = name_th;
    groupNameMap2["en"][gid] = name_en;
    groupIds2.push(gid);

    // Add spacing for visual separation between groups
    // weeks2.push("");
    // infectedCounts2.push(0);
    // deathCounts2.push(0);
    // deathCounts2.push(Math.max(...deathCounts2) * 3);
    // infectedBackgroundColors2.push("rgba(0,0,0,0)"); // Transparent for spacing
    // deathBackgroundColors2.push("rgba(0,0,0,0)"); 
  });

  const data = {
    labels: weeks,
    datasets: [
      {
        label: t('CASE'),
        data: infectedCounts,
        type: selectedFilterTag == "all" && selectedCompareOption == "1" ? "line": "scatter",
        backgroundColor: infectedBackgroundColors,
        pointRadius: 5,
        borderWidth: 4,
        fill: false,
        yAxisID: "y-axis-1",
        categoryPercentage: 0.8,
        barPercentage: 0.6,
        order: 0,
        relatedStatus: "infected_header",
      },
      {
        label: t('DEAD'),
        data: deathCounts,
        backgroundColor: deathBackgroundColors,
        borderWidth: 1,
        type: "bar",
        categoryPercentage: 0.8,
        barPercentage: 0.6,
        yAxisID: "y-axis-2",
        order: 1,
        relatedStatus: "deaths_header",
      },
    ],
  };

  // TODO: Wrap in useMemo after conditional return is getting rid
  // Copied from data2
  let filteredData = data;

  let filteredLabels = data.labels.map((label, index) => ({
    label,
    index
  }))
  filteredLabels = filteredLabels.filter(({label}) => {
    if(label === "") return true;
    if (selectedFilterTag === "gender") {
      return selectedOptions.some(option => label.includes(option.value));
    } else if (selectedFilterTag === "age") {
      return selectedOptions.some(option => label.includes(option.value));
    } else if (selectedFilterTag === "nationality") {
      return selectedOptions.some(option => label.includes(option.value));
    } else if (selectedFilterTag === "area") {
      return selectedOptions.some(option => label.split('_')[1].trim() === option.value); // Edge case north eastern
    } else if (selectedFilterTag === "health-area") {
      return selectedOptions.some(option => label.includes(option.value));
    } else {
      // Handle other cases or return a default value, like false, if no other conditions are met
      return [];
    }
  });
  const filteredLabelIndices = filteredLabels.map(({index}) => index);

  const filteredDatasets = data.datasets.map(dataset => {
    return {
      ...dataset,
      data: filteredLabelIndices.map(index => dataset.data[index]),
      backgroundColor: filteredLabelIndices.map(index => dataset.backgroundColor[index])
    }
  })
  if (selectedFilterTag === 'all') {
    filteredLabels = filteredLabels.map(({label}) => label);
  } else {
    filteredLabels = filteredLabels.map(({label = ''}) => {
      if(label === '') return '';
      const [first = '', second = ''] = label.split('_') || [];
      return `${first}_${keyTranslate(second.trim())}`
    });
  }
  filteredData = {
    labels: filteredLabels,
    datasets: filteredDatasets
  }
  if (selectedStatus.length > 0) {
    filteredData = {
      ...filteredData,
      datasets: filteredData.datasets.filter((dataset) => {
        return selectedStatus.some((status) => status.value === dataset.relatedStatus);
      }),
    };
  }

  const data2 = {
    labels: weeks2,
    categories: categories2,
    datasets: [
      {
        label: t('CASE'),
        data: infectedCounts2,
        type: selectedFilterTag == "all" && selectedCompareOption == "1" ? "line": "scatter",
        backgroundColor: infectedBackgroundColors2,
        borderColor: infectedBackgroundColors2,
        pointRadius: 5,
        borderWidth: 4,
        fill: false,
        yAxisID: "y-axis-1",
        categoryPercentage: 0.8,
        barPercentage: 0.6,
        order: 0,
        relatedStatus: "infected_header",
      },
      {
        label: t('DEAD'),
        data: deathCounts2,
        backgroundColor: deathBackgroundColors2,
        borderWidth: 1,
        type: "bar",
        categoryPercentage: 0.8,
        barPercentage: 0.6,
        yAxisID: "y-axis-2",
        order: 1,
        relatedStatus: "deaths_header"
      },
    ],
  };

  const filteredLabels2 = data2.labels.filter(label => {
    if (selectedFilterTag === "gender") {
      return selectedGender.some(gender => label.includes(gender.value));
    } else if (selectedFilterTag === "age") {
      return selectedAge.some(age => label.includes(age.value));
    } else if (selectedFilterTag === "nationality") {
      return selectedNationality.some(nationality => label.includes(nationality.value));
    } else if (selectedFilterTag === "area") {
      return selectedArea.some(area => label.split('_')[1].trim() === area.value); // Edge case northeastern
    } else if (selectedFilterTag === "health-area") {
      return selectedArea.some(area => label.includes(area.value));
    } else {
      // Handle other cases or return a default value, like false, if no other conditions are met
      return [];
    }
  });


  // Get the indices of the filtered labels in the original labels array
  const filteredLabelIndices2 = filteredLabels2.map(label =>
    data2.labels.indexOf(label)
  );
  
  // Assuming data2.datasets is an array of dataset objects, here's how you'd filter each one
  const filteredDatasets2 = data2.datasets.map(dataset => {
    const filteredData = dataset.data.filter((_, index) =>
      filteredLabelIndices2.includes(index)
    );
    return { ...dataset, data: filteredData };
  });

  let filteredData2 = data2;
  if(selectedFilterTag !== "all") {
    filteredData2 = {
      labels: filteredLabels2.map(label => {
        if(label === '') return '';
        const [first = '', second = ''] = label.split('_') || [];
        return `${first}_${keyTranslate(second.trim())}`
      }),
      datasets: filteredDatasets2
    };
  } 

  if(dead?.length > 0  && infected?.length > 0 && selectedFilterTag === "all" && getOutputType(selectedOutputType) === "indiv") {
    console.log(dead, infected)
    filteredData2.datasets.forEach(dataset => {
      if (dataset.relatedStatus === 'infected_header') {
        dataset.data = infected[0];
      } else if (dataset.relatedStatus === 'deaths_header') {
        dataset.data = dead[0];
      }
    });
  } 
  
  if (selectedStatus.length > 0) {
    filteredData2 = {
      ...filteredData2,
      datasets: filteredData2.datasets.filter((dataset) => {
        return selectedStatus.some((status) => status.value === dataset.relatedStatus);
      }),
    };
  }

  const separationPlugin = {
    id: "separationPlugin",
    afterDraw: (chart, args, options) => {
      const ctx = chart.ctx;
      // Set font and alignment
      ctx.font = "bold 14px Sukhumvit";
      ctx.textAlign = "end";
      ctx.textBaseline = "bottom";

      const weeks = chart.options.weeks;
      
      const groupIds = chart.options.groupIds.slice(); // create a copy so that we don't modify the original array

      // Render group IDs and vertical separation lines
      chart.getDatasetMeta(0).data.forEach((bar, index) => {
        if (weeks[index] === "") {
          const position = bar.getCenterPoint();
          const secondLastBar = chart.getDatasetMeta(0).data[index];
          const xPos = secondLastBar._model.x;
          // ctx.fillText(`Group: ${groupIds.shift()}`, xPos, 60);
          // ctx.fillText(epidemGroupNameMap[`${groupIds.shift()}`], xPos, 60);

          ctx.save();
          ctx.translate(xPos, 40);
          ctx.rotate(-Math.PI / 2);
          ctx.fillStyle = "darkgrey";
          ctx.fillText(groupNameMap[i18n.language][`${groupIds.shift()}`], 0, 0);
          ctx.restore();

          ctx.save();
          ctx.beginPath();
          ctx.moveTo(position.x, chart.chartArea.top);
          ctx.lineTo(position.x, chart.chartArea.bottom);
          ctx.strokeStyle = "#000";
          ctx.lineWidth = 1;
          ctx.stroke();
          ctx.restore();
        }
      });
    },
  };

  const options2 = {
    legend: {
      display: false, // Disable default legend
    },
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
          beginAtZero: true,
          min: 0,
          scaleLabel: {
            display: true,
            labelString: t('CASE'),
          },
        },
        {
          type: "linear",
          display: true,
          position: "right",
          id: "y-axis-2",
          beginAtZero: true,
          min: 0,
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
          scaleLabel: {
            display: true,
            labelString: t('DEAD'),
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: t('TIME_PERIOD'),
          },
        },
      ],
      x: {
        stacked: true,
      },
    },
    weeks: weeks2,
    groupIds: groupIds2,
    maintainAspectRatio: false,
  };

  const options = {
    legend: {
      display: false, // Disable default legend
    },
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
          beginAtZero: true,
          min: 0,
          scaleLabel: {
            display: true,
            labelString: t('CASE'),
          },
        },
        {
          type: "linear",
          display: true,
          position: "right",
          id: "y-axis-2",
          beginAtZero: true,
          min: 0,
          scaleLabel: {
            display: true,
            labelString: t('DEAD'),
          },
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: t('TIME_PERIOD'),
          },
        },
      ],
      x: {
        stacked: true,
      },
    },
    weeks: filteredData.labels,
    groupIds: groupIds,
    maintainAspectRatio: false,
  };

  let pieChartLabels = Object.keys(pieData);
  if(selectedFilterTag === "nationality") {
    pieChartLabels = pieChartLabels.filter(label => label !== "รวมต่างชาติ");
  }
  const infectedData = pieChartLabels.map(key => pieData[key].infected);
  const deathData = pieChartLabels.map(key => pieData[key].death);

  return (
    <div>
      <div style={{ width: "100%", overflowX: "scroll", maxHeight: "800px" }}>
        {getOutputType(selectedOutputType) === "indiv" && selectedFilterTag === "all" && (
          <EpidemicReportTableMD5 data={jsonData.data} selectedDisplay={selectedDisplay} selectedCompareOption={selectedCompareOption} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} />
        )}
        {getOutputType(selectedOutputType) === "indiv" && selectedFilterTag === "gender" && (
          <EpidemicReportTableMD5Gender data={jsonData.data} selectedDisplay={selectedDisplay} selectedCompareOption={selectedCompareOption} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} selectedGender={selectedGender} setSelectedGender={setSelectedGender} />
        )}
        {getOutputType(selectedOutputType) === "indiv" && selectedFilterTag === "age" && (
          <EpidemicReportTableMD5Age data={jsonData.data} selectedDisplay={selectedDisplay} selectedCompareOption={selectedCompareOption} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} selectedAge={selectedAge} setSelectedAge={setSelectedAge} />
        )}
        {getOutputType(selectedOutputType) === "indiv" && selectedFilterTag === "nationality" && (
          <EpidemicReportTableMD5Nationality data={jsonData.data} selectedDisplay={selectedDisplay} selectedCompareOption={selectedCompareOption} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} selectedNationality={selectedNationality} setSelectedNationality={setSelectedNationality}/>
        )}
        {getOutputType(selectedOutputType) === "indiv" && (selectedFilterTag === "area" || selectedFilterTag === "health-area") && (
          <EpidemicReportTableMD5Area data={jsonData.data} selectedDisplay={selectedDisplay} selectedCompareOption={selectedCompareOption} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} selectedArea={selectedArea} setSelectedArea={setSelectedArea}/>
        )}
        {getOutputType(selectedOutputType) === "group" && selectedFilterTag === "all" && (
          <EpidemicReportTableMD5Group
            data={jsonData.data}
            getGroupDetail={getGroupDetail}
            selectedDisplay={selectedDisplay}
            selectedCompareOption={selectedCompareOption}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
          />
        )}
        {getOutputType(selectedOutputType) === "group" &&
          ["gender", "age", "nationality", "area", "health-area"].includes(selectedFilterTag) && (
            <EpidemicReportTableMD5GroupDynamic
              data={jsonData.data}
              getGroupDetail={getGroupDetail}
              groupField={tagToField?.[selectedFilterTag]}
              selectedDisplay={selectedDisplay}
              selectedCompareOption={selectedCompareOption}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
            />
          )}
      </div>
      <div style={{ paddingTop: "50px" }}></div>
      <div style={{ minWidth: "600px", height: "650px" }}>
        {getOutputType(selectedOutputType) === "indiv" && selectedFilterTag === "all" && <BarDropdown />}
        <div style={{ paddingTop: "10px" }}></div>
        <div id="chart-legend">{customLegend}</div>
        <div style={{ height: "600px" }}>
          {getOutputType(selectedOutputType) === "indiv" && (
            <Bar data={filteredData2} options={options2} key={JSON.stringify(data2)} />
          )}
          {getOutputType(selectedOutputType) === "group" && (
            <Bar data={filteredData} options={options} plugins={[separationPlugin]} key={JSON.stringify(data)} />
          )}
        </div>
      </div>
      
      <div style={{ paddingTop: "50px" }}></div>
      <div>
      {selectedFilterTag !== "all" && (
      <div style={{ minWidth: "600px", height: "1000px", textAlign: 'center' }}>
      
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          {noSelectedStatusOrHaving("infected_header") && (
            <div style={{ flex: 1, maxWidth: "600px", height: "500px", margin: "10px" }}>
              <h4>{t('CASE')}</h4>
              <MyPieChart
                selectedFilterTag={selectedFilterTag}
                labels={pieChartLabels}
                infectedData={infectedData}
              />
            </div>
          )}
          {noSelectedStatusOrHaving("deaths_header") && (
            <>
              <div style={{ marginLeft: "50px" }}></div>
              <div style={{ flex: 1, maxWidth: "600px", height: "500px", margin: "10px" }}>
                <h4>{t('DEAD')}</h4>
                <MyPieChart
                  selectedFilterTag={selectedFilterTag}
                  labels={pieChartLabels}
                  infectedData={deathData}
                />
              </div>
            </>
          )}
        </div>
        
      </div>
     )}
    </div>

    </div>
  );
}

export default CombinedEpidemicCharts;
