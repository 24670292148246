import React, { useMemo } from 'react';
import { Pie, Chart } from 'react-chartjs-2';
import { formatNumber } from "@/modules/common/utils";
import { useTranslation } from 'react-i18next';
import { useKeyTranslate } from '@/modules/common/utils/useKeyTranslate';

const ageToPieColor = {
  "0-4": "rgba(129,165,67)",
  "5-14": "rgba(50,106,95)",
  "15-24": "rgba(117,145,195)",
  "25-34": "rgba(66,92,198)",
  "35-44": "rgba(117,53,119)",
  "45-54": "rgba(171,45,38)",
  "55-64": "rgba(183,87,50)",
  "65+": "rgba(220,214,74)"
};

const genderToPieColor = {
  "ชาย": "rgba(19,21,128)",
  "หญิง": "rgba(164,39,143)",
};

const nationalityToPieColor = {
  "1 - ไทย": "rgba(129,165,67)",
  "2 - จีน/ฮ่องกง/ไต้หวัน": "rgba(50,106,95)",
  "3 - พม่า": "rgba(117,145,195)",
  "4 - มาเลเซีย": "rgba(66,92,198)",
  "5 - กัมพูชา": "rgba(117,53,119)",
  "6 - ลาว": "rgba(171,45,38)",
  "7 - เวียดนาม": "rgba(183,87,50)",
  "8 - อื่น ๆ": "rgba(220,214,74)",
  "รวมต่างชาติ": "rgba(201,220,74)",
};

const areaToPieColor = {
  "กลาง": "rgba(171,45,38)",
  "ตะวันออกเฉียงเหนือ": "rgba(129,165,67)",
  "เหนือ": "rgba(220,214,74)",
  "ใต้": "rgba(66,92,198)",
  "เขตสุขภาพที่ 1": "rgba(129,165,67)",
  "เขตสุขภาพที่ 2": "rgba(50,106,95)",
  "เขตสุขภาพที่ 3": "rgba(117,145,195)",
  "เขตสุขภาพที่ 4": "rgba(66,92,198)",
  "เขตสุขภาพที่ 5": "rgba(117,53,119)",
  "เขตสุขภาพที่ 6": "rgba(171,45,38)",
  "เขตสุขภาพที่ 7": "rgba(183,87,50)",
  "เขตสุขภาพที่ 8": "rgba(233,180,137)",
  "เขตสุขภาพที่ 9": "rgba(220,214,74)",
  "เขตสุขภาพที่ 10": "rgba(247,149,213)",
  "เขตสุขภาพที่ 11": "rgba(241,19,178)",
  "เขตสุขภาพที่ 12": "rgba(166,166,166)",
  "เขตสุขภาพที่ 13": "rgba(89,89,89)",
};
// For rest areas, same randomized color from interested
const getRandFromStr = (str, factor, mod) => {
  let num = 0;
  for(let i = 0; i < str.length; i++) {
    let c = str.charCodeAt(i);
    num = (num + c) * factor;
    num = num % mod;
  }
  return num;
}
const getAreaColor = (key) => {
  if (!areaToPieColor[key]) {
    // Generate a random color
    const hue = getRandFromStr(key, 113, 367) % 360;
    const saturation = 50 + (getRandFromStr(key, 331, 59) % 50);
    const lightness = 50; // Fixed lightness for consistency
    areaToPieColor[key] = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  }
  return areaToPieColor[key];
};

const MyPieChart = ({ selectedFilterTag, labels, infectedData }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const keyTranslate = useKeyTranslate(selectedFilterTag, t);

  const total = infectedData.reduce((acc, val) => acc + val, 0);

  const backgroundColor = useMemo(() => {
    switch(selectedFilterTag) {
      case 'age':
        return labels.map(label => ageToPieColor[label]);
      case 'gender':
        return labels.map(label => genderToPieColor[label]);
      case 'nationality':
        return labels.map(label => nationalityToPieColor[label]);
      case 'area':
      case 'health-area':
      default:
        return labels.map(label => getAreaColor(label))
    }
  }, [labels, selectedFilterTag]);

  const chartData = {
    labels: labels.map(keyTranslate),
    datasets: [{
      data: infectedData,
      backgroundColor,
    }]
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'right',
      labels: {
        generateLabels: function(chart) {
          const data = chart.data;
          if (data.labels.length && data.datasets.length) {
            return data.labels.map(function(label, i) {
              const meta = chart.getDatasetMeta(0);
              const ds = data.datasets[0];
              const arc = meta.data[i];
              const custom = arc && arc.custom || {};
              const getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
              const arcOpts = chart.options.elements.arc;
              const fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
              const stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
              const bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);
  
              // Calculate the total value and the value of this segment
              const total = ds.data.reduce((sum, val) => sum + val, 0);
              const value = ds.data[i];
              const percentage = ((value / total) * 100).toFixed(2) + '%';
  
              return {
                // String to be displayed in the legend
                text: `${label}: ${formatNumber(value)} (${percentage})`,
                fillStyle: fill,
                strokeStyle: stroke,
                lineWidth: bw,
                hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                index: i
              };
            });
          }
          return [];
        }
      }
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          const label = data.labels[tooltipItem.index] || '';
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || 0;
          const percentage = ((value / total) * 100).toFixed(2) + '%';
          return `${label}: ${formatNumber(value)} | ${t('ALL')}: ${formatNumber(total)} | (${percentage})`;
        }
      }
    }
  };
  


  return <Pie data={chartData} options={options}/>;
};

export default MyPieChart;
