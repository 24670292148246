import React from 'react';
import { Select, MenuItem, InputLabel  } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function DropdownComponent(props) {
    const { t } = useTranslation();
    const handleYearChange = (event) => {
        props.onYearChange(event.target.value);
    };
    const handleWeekChange = (event) => {
        props.onWeekChange(event.target.value);
    };
    const handleMonthChange = (event) => {
        props.onMonthChange(event.target.value);
    };
    const handleEndYearChange = (event) => {
        props.onEndYearChange(event.target.value);
    };
    const handleEndWeekChange = (event) => {
        props.onEndWeekChange(event.target.value);
    };
    const handleEndMonthChange = (event) => {
        props.onEndMonthChange(event.target.value);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', flex: 1, margin: '0 0px', textAlign: 'center' }}>
            
            {/* Always show start year label and selector */}
            <InputLabel id="year-label">{t('FROM_YEAR')}</InputLabel>
            <Select
                labelId="year-label"
                value={props.selectedYearValue}
                onChange={handleYearChange}
                style={{ margin: '0 10px' }}
            >
                {Array.from({ length: 9 }, (_, i) => i + 2017).map(year => (
                    <MenuItem key={year} value={year}>{year}</MenuItem>
                ))}
            </Select>

            {props.selectedCompareOption === "2" && (<><InputLabel id="year-label2">{t('TO_YEAR')}</InputLabel>
            <Select
                labelId="year-label2"
                value={props.selectedEndYearValue}
                onChange={handleEndYearChange}
                style={{ margin: '0 10px' }}
            >
                {Array.from({ length: 9 }, (_, i) => i + 2017).map(year => (
                    <MenuItem key={year} value={year}>{year}</MenuItem>
                ))}
            </Select></>)}
    
            {/* Conditionally show week or month selector based on timeframe */}
            {props.selectedTime === "3" && (
                <>
                    <InputLabel id="week-label">{t('WEEK')}</InputLabel>
                    <Select
                        labelId="week-label"
                        value={props.selectedWeekValue}
                        onChange={handleWeekChange}
                        style={{ margin: '0 10px' }}
                    >
                        {Array.from({ length: 53 }, (_, i) => i + 1).map(week => (
                            <MenuItem key={week} value={week}>{week}</MenuItem>
                        ))}
                    </Select>
                </>
            )}
            {props.selectedTime === "2" && (
                <>
                    <InputLabel id="month-label">{t('MONTH')}</InputLabel>
                    <Select
                        labelId="month-label"
                        value={props.selectedMonthValue}
                        onChange={handleMonthChange}
                        style={{ margin: '0 10px' }}
                    >
                        {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
                            <MenuItem key={month} value={month}>{month}</MenuItem>
                        ))}
                    </Select>
                </>
            )}
    
            {/* Always show end year label and selector */}
            {(props.selectedCompareOption === "1" || props.selectedCompareOption === undefined) && (<>
            <InputLabel id="year-label2">{t('TO_YEAR')}</InputLabel>
            <Select
                labelId="year-label2"
                value={props.selectedEndYearValue}
                onChange={handleEndYearChange}
                style={{ margin: '0 10px' }}
            >
                {Array.from({ length: 9 }, (_, i) => i + 2017).map(year => (
                    <MenuItem key={year} value={year}>{year}</MenuItem>
                ))}
            </Select>
            </>)}
    
            {/* Conditionally show end week or month selector based on timeframe */}
            {props.selectedTime === "3" && (
                <>
                    <InputLabel id="week-label2">{t('WEEK')}</InputLabel>
                    <Select
                        labelId="week-label2"
                        value={props.selectedEndWeekValue}
                        onChange={handleEndWeekChange}
                        style={{ margin: '0 10px' }}
                    >
                        {Array.from({ length: 53 }, (_, i) => i + 1).map(week => (
                            <MenuItem key={week} value={week}>{week}</MenuItem>
                        ))}
                    </Select>
                </>
            )}
            {props.selectedTime === "2" && (
                <>
                    <InputLabel id="month-label2">{t('MONTH')}</InputLabel>
                    <Select
                        labelId="month-label2"
                        value={props.selectedEndMonthValue}
                        onChange={handleEndMonthChange}
                        style={{ margin: '0 10px' }}
                    >
                        {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
                            <MenuItem key={month} value={month}>{month}</MenuItem>
                        ))}
                    </Select>
                </>
            )}
        </div>
    );
    
}

export default DropdownComponent;
