import { useState, useEffect } from "react";
import axios from "axios";
import * as React from "react";
import { DiseaseNotification, SelectDisplayInfectedType, SelectTimePeriod } from "@/modules/common/components";
import { SelectDisplayDiseaseType } from "./components";
import SpecificDiseasePieChart from "./components/specificDiseasePieChart";

const Content = ({ selectedFilterTag }) => {
  // Master data
  const [masterAreas, setMasterAreas] = useState([]);
  const [masterAreasCache, setMasterAreasCache] = useState({});

  // Diplay disease type
  const [epidemSelected, setEpidemSelected] = useState([]);
  const [selectedAreaType, setSelectedAreaType] = useState("area");
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedPatientStatuses, setSelectedPatientStatuses] = useState([]);

  // Time period
  const [selectedYearValue, setSelectedYearValue] = useState("");
  const [selectedWeekValue, setSelectedWeekValue] = useState("");
  const [selectedEndYearValue, setSelectedEndYearValue] = useState("");
  const [selectedEndWeekValue, setSelectedEndWeekValue] = useState("");
  const [selectedMonthValue, setSelectedMonthValue] = useState("");
  const [selectedEndMonthValue, setSelectedEndMonthValue] = useState("");
  const [selectedTime, setSelectedTime] = useState("1");

  // Display
  // TODO: Name meaning is misleading
  const [selectedDisplay, setSelectedDisplay] = useState("3");

  const [searchTrigger, setSearchTrigger] = useState(0);
  const [selectedCompareOption, setSelectedCompareOption] = useState("1");

  useEffect(() => {
    if(selectedAreaType in masterAreasCache) {
      setMasterAreas(masterAreasCache[selectedAreaType]);
      setSelectedAreas([]);
      return;
    }
    axios.get(`/api/master/${selectedAreaType}`).then(res => {
      const areas = res.data?.data;
      const areaObj = areas.reduce((obj, area) => ({...obj, [area.id]: area.name }), {});
      setMasterAreas(areaObj);
      setMasterAreasCache(cache => ({...cache, [selectedAreaType]: areaObj}));
      setSelectedAreas([]);
    });
  }, [selectedAreaType, masterAreasCache]);

  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <SelectDisplayDiseaseType
          masterAreas={masterAreas}
          epidemSelected={epidemSelected}
          setEpidemSelected={setEpidemSelected}
          selectedAreaType={selectedAreaType}
          setSelectedAreaType={setSelectedAreaType}
          selectedAreas={selectedAreas}
          setSelectedAreas={setSelectedAreas}
          selectedPatientStatuses={selectedPatientStatuses}
          setSelectedPatientStatuses={setSelectedPatientStatuses}
        />
        <SelectTimePeriod
          selectedYearValue={selectedYearValue}
          setSelectedYearValue={setSelectedYearValue}
          selectedWeekValue={selectedWeekValue}
          setSelectedWeekValue={setSelectedWeekValue}
          selectedEndYearValue={selectedEndYearValue}
          setSelectedEndYearValue={setSelectedEndYearValue}
          selectedEndWeekValue={selectedEndWeekValue}
          setSelectedEndWeekValue={setSelectedEndWeekValue}
          selectedMonthValue={selectedMonthValue}
          setSelectedMonthValue={setSelectedMonthValue}
          selectedEndMonthValue={selectedEndMonthValue}
          setSelectedEndMonthValue={setSelectedEndMonthValue}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          setSearchTrigger={setSearchTrigger}
          selectedCompareOption={selectedCompareOption}
          setSelectedCompareOption={setSelectedCompareOption}
        />
        <SelectDisplayInfectedType selectedDisplay={selectedDisplay} setSelectedDisplay={setSelectedDisplay} />
        <DiseaseNotification />
        {/* <PatientRatio/> */}
      </div>
      <div style={{ flex: 1 }}>
        <SpecificDiseasePieChart
          selectedYearValue={selectedYearValue}
          selectedWeekValue={selectedWeekValue}
          selectedEndYearValue={selectedEndYearValue}
          selectedEndWeekValue={selectedEndWeekValue}
          selectedMonthValue={selectedMonthValue}
          selectedEndMonthValue={selectedEndMonthValue}
          selectedTime={selectedTime}
          selectedOutputType="1"
          selectedFilterTag={selectedFilterTag}
          selectedAreaType={selectedAreaType}
          searchTrigger={searchTrigger}
          epidemSelected={epidemSelected}
          selectedDisplay={selectedDisplay}
          selectedAreas={selectedAreas}
          selectedPatientStatuses={selectedPatientStatuses}
          selectedCompareOption={selectedCompareOption}
        />
      </div>
    </React.Fragment>
  );
};

export default Content;
