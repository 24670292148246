import { Button, Divider, FormControlLabel, Paper, Radio, RadioGroup, Typography } from "@material-ui/core";
import { DropdownComponent } from "./components";
import i18n from "../../utils/i18n";
import { useTranslation } from "react-i18next";

const SelectTimePeriod = (props) => {
  const { t, i18n } = useTranslation();
  const handleRadioChange = (event) => {
    props.setSelectedTime(event.target.value);
    props.setSelectedYearValue('')
    props.setSelectedWeekValue('')
    props.setSelectedMonthValue('')
    props.setSelectedEndYearValue('')
    props.setSelectedEndWeekValue('')
    props.setSelectedEndMonthValue('')
  };

  const handleRadioChangeOption = (event) => {
    props.setSelectedCompareOption(event.target.value);
  };

  const handleSearchClick = (event) => {
    props.setSearchTrigger(prev => prev + 1);
  };

  const handleDownloadAll = () => {
    window.open('https://drive.google.com/drive/folders/1bZcStS_ncech6PVanRxPW_uL3z7HxSn0?usp=sharing', '_blank');
  };

  // let csvContent = "data:text/csv;charset=utf-8,";

  const convertToCSV = () => {
    let csvContent = "data:text/csv;charset=utf-8,";

    if (!Array.isArray(props.downloadData.data) || props.downloadData.data.length === 0) {
        return csvContent;
    }

    // Generate unique composite keys for time periods with additional attributes
    const timePeriodHeaders = Array.from(new Set(props.downloadData.data.flatMap(report => report.details.flatMap(detail => {
        const baseKey = detail.week ? `${detail.year}_W${detail.week}` : detail.month_id ? `${detail.year}_M${detail.month_id}` : detail.year.toString();
        const attributeKeys = [];
        if (detail.gender) attributeKeys.push(`${baseKey} (${t('GENDER')})`);
        if (detail.age_range) attributeKeys.push(`${baseKey} (${t('AGE')})`);
        if (detail.nationality) attributeKeys.push(`${baseKey} (${t('NATIONALITY')})`);
        if (detail.area) attributeKeys.push(`${baseKey} (${t('AREA')})`);
        return attributeKeys.length > 0 ? attributeKeys : [baseKey];
    })))).sort();

    // Header
    csvContent += `${t('EPIDEMIC')},` + timePeriodHeaders.map(header => `${header},,,,`).join('') + "\r\n";

    // Sub-header
    csvContent += "," + timePeriodHeaders.flatMap(() => [t('EPIDEMIC_ALERT_STATUS'), t('PATIENTS_COMPARED_TO_5_YEAR_MEDIAN'), t('CASE'), t('DEAD')].map(subHeader => `${subHeader},`)).join('') + "\r\n";
    
    // Rows
    props.downloadData.data.forEach(report => {
      const xKey = report.epidem_report_name_th ? `epidem_report_name_${i18n.language}` : `epidem_group_name_${i18n.language}`
      let row = `"${report[xKey].replace(/"/g, '""')}",`;
      timePeriodHeaders.forEach(header => {
          const [year, monthWeekOrAttribute] = header.split(/[_MW ]/);
          const isAttributeHeader = header.includes('(');
          const detailsForHeader = report.details.filter(d => {
              if (d.year !== parseInt(year, 10)) return false;
              if (!isAttributeHeader) return true;
              if (d.gender && header.includes(t('GENDER'))) return true;
              if (d.age_range && header.includes(t('AGE'))) return true;
              if (d.nationality && header.includes(t('NATIONALITY'))) return true;
              if (d.area && header.includes(t('AREA'))) return true;
              return false;
          });
          const detail = detailsForHeader.length > 0 ? detailsForHeader[0] : {};
          row += `${detail.flag || '-'},${detail.median || '-'},${detail.infected || '-'},${detail.death || '-'},`;
      });
      csvContent += row + "\r\n";
  });

    return csvContent;
};

const convertToCSVCompare = () => {
  let csvContent = "data:text/csv;charset=utf-8,";

  if (!Array.isArray(props.downloadData.data) || props.downloadData.data.length === 0) {
      return csvContent;
  }

  // Generate unique composite keys for time periods with additional attributes
  const timePeriodHeaders = Array.from(new Set(props.downloadData.data.flatMap(report => report.details.flatMap(detail => {
      const baseKey = detail.week ? `${detail.year}_W${detail.week}` : detail.month_id ? `${detail.year}_M${detail.month_id}` : detail.year.toString();
      const attributeKeys = [];
      if (detail.gender) attributeKeys.push(`${baseKey} (${t('GENDER')})`);
      if (detail.age_range) attributeKeys.push(`${baseKey} (${t('AGE')})`);
      if (detail.nationality) attributeKeys.push(`${baseKey} (${t('NATIONALITY')})`);
      if (detail.area) attributeKeys.push(`${baseKey} (${t('AREA')})`);
      return attributeKeys.length > 0 ? attributeKeys : [baseKey];
  })))).sort();

  // Header
  csvContent += `${t('EPIDEMIC')},` + timePeriodHeaders.map(header => `${header},,,,,,`).join('') + "\r\n";

  // Sub-header
  csvContent += "," + timePeriodHeaders.flatMap(() => [t('EPIDEMIC_ALERT_STATUS'), t('PATIENTS_COMPARED_TO_5_YEAR_MEDIAN'), t('TOTAL_INFECTED'), t('TREATED'), t('DEAD'), t('UNKNOWN')].map(subHeader => `${subHeader},`)).join('') + "\r\n";
  
  // Rows
  props.downloadData.data.forEach(report => {
    let row = `"${report.epidem_report_name_th.replace(/"/g, '""')}",`;
    timePeriodHeaders.forEach(header => {
        const [year, monthWeekOrAttribute] = header.split(/[_MW ]/);
        const isAttributeHeader = header.includes('(');
        const detailsForHeader = report.details.filter(d => {
            if (d.year !== parseInt(year, 10)) return false;
            if (!isAttributeHeader) return true;
            if (d.gender && header.includes(t('GENDER'))) return true;
            if (d.age_range && header.includes(t('AGE'))) return true;
            if (d.nationality && header.includes(t('NATIONALITY'))) return true;
            if (d.area && header.includes(t('AREA'))) return true;
            return false;
        });
        const detail = detailsForHeader.length > 0 ? detailsForHeader[0] : {};
        row += `${detail.flag || '-'},${detail.median || '-'},${detail.infected || '-'},${detail.treated || '-'},${detail.death || '-'},${detail.unknow || '-'},`;
    });
    csvContent += row + "\r\n";
});

  return csvContent;
};


  const handleDownload = () => {
    const csvData = props.page === 'overall' ? convertToCSV() : convertToCSVCompare();
    const encodedUri = encodeURI(csvData);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "epidemic_report.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Paper style={{ flex: 1, padding: '20px', textAlign: 'center' }}>
      <Typography variant="h5">{t('PERIOD_DISPLAY_FORMAT')}</Typography>
      <Divider style={{ margin: '10px 0' }} />
      <Typography variant="h6">{t('SELECT_PERIOD_DISPLAY_FORMAT')}</Typography>
      <RadioGroup name="time" value={props.selectedTime} onChange={handleRadioChange} row> 
        <FormControlLabel value="1" control={<Radio />} label={t('YEARLY')} />
        <FormControlLabel value="2" control={<Radio />} label={t('MONTHLY')} />
        <FormControlLabel value="3" control={<Radio />} label={t('WEEKLY')} />
      </RadioGroup>
      <Divider style={{ margin: '10px 0' }} />
      <Typography variant="h6">{t('DATA')}</Typography>
      <RadioGroup name="datarate" value={props.selectedCompareOption} defaultValue="1" onChange={handleRadioChangeOption} style={{minWidth: '380px'}} row>
        <FormControlLabel value="1" control={<Radio />} label={t('CONTINUOUS_DATA')} />
        <FormControlLabel value="2" control={<Radio disabled={!props.setSelectedCompareOption} />} label={t('COMPARATIVE_DATA')}/>
      </RadioGroup>
      <DropdownComponent
        selectedYearValue={props.selectedYearValue}
        onYearChange={props.setSelectedYearValue}
        selectedWeekValue={props.selectedWeekValue}
        onWeekChange={props.setSelectedWeekValue}
        selectedEndYearValue={props.selectedEndYearValue}
        onEndYearChange={props.setSelectedEndYearValue}
        selectedEndWeekValue={props.selectedEndWeekValue}
        onEndWeekChange={props.setSelectedEndWeekValue}
        selectedMonthValue={props.selectedMonthValue}
        onMonthChange={props.setSelectedMonthValue}
        selectedEndMonthValue={props.selectedEndMonthValue}
        onEndMonthChange={props.setSelectedEndMonthValue}
        selectedTime={props.selectedTime}
        selectedCompareOption={props.selectedCompareOption}
      />
      <Button
        variant="contained" 
        color="primary" 
        onClick={handleSearchClick} // You will need to define this function to perform the search
        style={{ marginTop: '20px' }}
      >
        {t('SEARCH')}
    </Button>
    
    {props.page === "overall" &&
    <>
      <Button
          variant="contained" 
          color="primary" 
          // You will need to define this function to perform the search
          style={{ marginTop: '20px', marginLeft: '10px', display: props.downloadData==null ? 'none' : '' }}
          onClick={handleDownload}
        >
          {t('DOWNLOAD')}
      </Button>
      <Button
          variant="contained" 
          color="primary" 
          // You will need to define this function to perform the search
          onClick={handleDownloadAll}
          style={{ marginTop: '20px', marginLeft: '10px' }}
        >
          {t('DOWNLOAD_ALL')}
      </Button>
      </>
    }
    </Paper>
  );
}

export default SelectTimePeriod;