import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translation files
import translationEN from './locales/en/translation.json';
import translationTH from './locales/th/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  th: {
    translation: translationTH
  }
};

const savedLanguage = localStorage.getItem('language') || 'th';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: savedLanguage, // language to use
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export const thToKey = Object.entries(translationTH).reduce((obj, [k,v]) => ({...obj, [v]: k}), {});
export const enToKey = Object.entries(translationEN).reduce((obj, [k,v]) => ({...obj, [v]: k}), {});

export default i18n;
