import React from 'react';
import './footer.css';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t, i18n } = useTranslation();

  return (
    <footer className="footer">
        <table className="footer-table">
        <tbody>
        <tr>
            <td colSpan="2" className="footer-heading">
              <h3>Contact Us</h3>
            </td>
          </tr>
          <tr>
            <td className="footer-body">
              <p>{t('FOOTER_THAI_EPIDEMIC')}</p>
              <p><img src="/phoneWeb.png" alt="phone" className="footer-image" />{t('FOOTER_TELEPHONE')}: xx-xxxxxxx</p>
              <p><img src="/mailWeb.png" alt="mail" className="footer-image" />E-mail: xxxx@xxxx.com</p>
            </td>
            <td className="footer-body">
              <p>{t('FOOTER_DISEASE_CONTROL_DEPARTMENT')}</p>
              <p><img src="/phoneWeb.png" alt="phone" className="footer-image" />{t('FOOTER_TELEPHONE')}: 02-5903000   {t('FOOTER_HOTLINE')}: 1422</p>
              <p><img src="/mailWeb.png" alt="mail" className="footer-image" />E-mail: ddc.moph@ddc.mail.go.th</p>
            </td>
          </tr>
        </tbody>
      </table>
    </footer>
  );
}

export default Footer;
