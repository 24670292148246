import React, { useState, useEffect, useContext, useCallback } from "react";
import { useSort } from '@/modules/common/hooks';
import axios from "axios";
import { AuthContext } from "@/App";
import { TableNotFound } from "@/modules/common/components";
import { useTranslation } from "react-i18next";
import { formatNumber } from "@/modules/common/utils";

function AlertComponent({
  selectedTime,
  searchTrigger,
  selectedYearValue,
  selectedMonthValue,
  selectedWeekValue,
  selectedFilterTag,
}) {
  const { t, i18n } = useTranslation();
  const [jsonData, setJsonData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [localSortDir, setLocalSortDir] = useState({});

  const getTimeFrame = (time) => {
    if (time === "1") return "year";
    if (time === "2") return "month";
    if (time === "3") return "week";
    return "week";
  };

  const { checkAndRedirect } = useContext(AuthContext);

  useEffect(() => {
    let timeoutId;
    checkAndRedirect()
      .then(() => {
        setJsonData(null);
        setIsLoading(true);

        timeoutId = setTimeout(() => {
          if (isLoading) {
            setIsLoading(false);
          }
        }, 5000);

        const requestBody = {
          timeFrame: getTimeFrame(selectedTime),
          year: parseInt(selectedYearValue),
          month: parseInt(selectedMonthValue),
          week: parseInt(selectedWeekValue),
        };

        axios
          .post("/api/smart-notification", requestBody)
          .then((response) => {
            setJsonData(response.data);
          })
          .catch((error) => {
            setIsLoading(false);
            console.error("Error fetching data:", error);
          })
          .finally(() => {
            setIsLoading(false); // Stop loading
          });
      })
      .catch(() => {});
    return () => timeoutId && clearTimeout(timeoutId);
  }, [searchTrigger]);

  const renderDataCells = useCallback((item) => {
    if (item.isDangerousEpidemic) {
      return <td className="red">{item[`epidem_report_name_${i18n.language}`]}</td>;
    } else {
      return <td>{item[`epidem_report_name_${i18n.language}`]}</td>;
    }
  }, [i18n.language]);

  if (isLoading && searchTrigger > 0) {
    return (
      <div className="loading-overlay">
        <div className="spinner"></div>
      </div>
    ); // Replace with your spinner component or styling
  }

  let data = null

  if (!jsonData || !jsonData.data || !Array.isArray(jsonData.data) || selectedFilterTag != "epidemic") {
    return <TableNotFound />;
  } else {
    data = jsonData.data
  }

  function flattenKeys(obj) {
    let resultString = '';

    function flatten(obj, prefix = '') {
        for (let key in obj) {
            if (!obj.hasOwnProperty(key)) continue;

            if (typeof obj[key] === 'object' && obj[key] !== null) {
                flatten(obj[key], prefix + key + '_');
            } else {
                resultString += `${prefix}${key}_${obj[key]} `;
            }
        }
    }

    flatten(obj);
    return resultString.trim(); // Remove trailing space 
}

const removeNullKeys = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
      // If obj is not an object or is null, return it as is
      return obj;
    }
  
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value && typeof value === 'object') {
        acc[key] = removeNullKeys(value); // Recursively process nested objects
      } else if (value !== null) {
        acc[key] = value; // Keep non-null values
      }
      return acc;
    }, {});
  };

  // const { sortedData, sortBy, sortDir, setSortBy, setSortDir } = useSort({ data });
  // const handleClickHeader = (header) => {
  //     console.log(header)
  //     let newDir = {}
  //     newDir[flattenKeys(header)] = localSortDir[flattenKeys(header)] === null || localSortDir[flattenKeys(header)] === "asc" ? "desc" : "asc";
  //     setLocalSortDir(newDir)
  //     setSortBy(header);
  //     setSortDir(localSortDir[flattenKeys(header)] == null ? "desc" : localSortDir[flattenKeys(header)] );
  //   }

  return (
    <div>
      {jsonData && jsonData.data && Array.isArray(jsonData.data) ? (
        <table id="epidemicReportTable">
          <thead>
            <tr>
              <th>{t('EPIDEMIC')}</th>
              <th>{t('EPIDEMIC_ALERT_STATUS')}</th>
              <th>{t('PATIENTS_COMPARED_TO_5_YEAR_MEDIAN')}</th>
              <th>{t('CASE')}</th>
              <th>{t('DEAD')}</th>
            </tr>
          </thead>
          <tbody>
            {jsonData.data.map((item, index) =>
              item.details.map((detail, detailIndex) => (
                <tr key={`${index}-${detailIndex}`}>
                  {renderDataCells(item)}
                  <td>{detail.flag > 0 ? "!".repeat(detail.flag) : "-"}</td>
                  <td>{formatNumber(detail.median)}</td>
                  <td>{formatNumber(detail.infected)}</td>
                  <td>{formatNumber(detail.death)}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      ) : (
        <p>Loading or no data available...</p>
      )}
    </div>
  );
}

export default AlertComponent;
