import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const DiseaseNotification = () => {
  const { t } = useTranslation();
  return (
    <Paper style={{ padding: '20px', flex: '1 1 0%', fontSize: '0.8rem', textAlign: 'left'}}>
      <Typography variant="h5">{t('EPIDEMIC_ALERT_STATUS')}</Typography>
      <p>! = {t('DISEASES_WITH_NO_STATISTICS_LAST_YEAR')}</p>
      <p>!! = {t('DISEASES_ABOVE_MEDIAN_PATIENTS')}</p>
      <p>!!! = {t('EMERGING_DISEASES')}</p>
    </Paper>
  );
};

export default DiseaseNotification;
