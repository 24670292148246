import { formatNumber } from '@/modules/common/utils';
import React, {useState, useCallback, useEffect} from 'react';
import { Divider, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, Select, Typography } from "@material-ui/core";
import ReactSelect from "react-select";
import { useSort } from '@/modules/common/hooks';
import { useTranslation } from 'react-i18next';
import { thToKey } from '@/modules/common/utils/i18n';
import { useKeyTranslate } from '@/modules/common/utils/useKeyTranslate';

const EpidemicReportTableMD5Gender = ({ data, selectedDisplay, selectedCompareOption, selectedStatus, setSelectedStatus, selectedGender, setSelectedGender }) => {
    const hasMonthData = data.some(report => report.details.some(detail => 'month' in detail));
    const hasWeekData = data.some(report => report.details.some(detail => 'week' in detail));

    const { t, i18n } = useTranslation();
    const keyTranslate = useKeyTranslate('gender', t);
    const currentLanguage = i18n.language;

    const [allGenders, setAllGenders] = useState([{
        "value": "ชาย",
        "label": t('MALE'),
        "key": "male"
    }, {
        "value": "หญิง",
        "label": t('FEMALE'),
        "key": "female"
    }]);

    const [allStatuses, setAllStatuses] = useState([{
        value: "infected_header",
        label: t('CASE')
    },
    {
        value: "deaths_header",
        label: t('DEAD')
    }]);

    const handleLanguageChanged = useCallback(() => {
        setAllStatuses([{
            value: "infected_header",
            label: t('CASE')
        },
        {
            value: "deaths_header",
            label: t('DEAD')
        }])

        setAllGenders([{
            "value": "ชาย",
            "label": t('MALE'),
            "key": "male"
        }, {
            "value": "หญิง",
            "label": t('FEMALE'),
            "key": "female"
        }])
    }, []);
    
    useEffect(() => {
        i18n.on('languageChanged', handleLanguageChanged);
        return () => {
            i18n.off('languageChanged', handleLanguageChanged);
        };
    }, [handleLanguageChanged])

    useEffect(() => {
        let status = [];
        for (let i = 0; i<allStatuses.length; i++) {
            for(let j = 0; j<selectedStatus.length; j++) {
                if (allStatuses[i].value == selectedStatus[j].value) {
                    status.push(allStatuses[i])
                    break;
                }
            }
        }
        setSelectedStatus(status);
    }, [allStatuses])

    useEffect(() => {
        let status = [];
        for (let i = 0; i<allGenders.length; i++) {
            for(let j = 0; j<selectedGender.length; j++) {
                if (allGenders[i].key == selectedGender[j].key) {
                    status.push(allGenders[i])
                    break;
                }
            }
        }
        setSelectedGender(status);
    }, [allGenders])

    const [localSortDir, setLocalSortDir] = useState({})

    function flattenKeys(obj) {
        let resultString = '';

        function flatten(obj, prefix = '') {
            for (let key in obj) {
                if (!obj.hasOwnProperty(key)) continue;
    
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    flatten(obj[key], prefix + key + '_');
                } else {
                    resultString += `${prefix}${key}_${obj[key]} `;
                }
            }
        }
    
        flatten(obj);
        return resultString.trim(); // Remove trailing space 
    }

    const thaiMonths = {
        'มกราคม': 1, 'กุมภาพันธ์': 2, 'มีนาคม': 3, 'เมษายน': 4, 
        'พฤษภาคม': 5, 'มิถุนายน': 6, 'กรกฎาคม': 7, 'สิงหาคม': 8, 
        'กันยายน': 9, 'ตุลาคม': 10, 'พฤศจิกายน': 11, 'ธันวาคม': 12
      };
      

    const getMonthNumber = (month) => {
        return thaiMonths[month] || parseInt(month, 10);
      };

    const customReactSelectStyles = {
        menu: (base) => ({
          ...base,
          zIndex: 11, // Set your desired zIndex value
        }),
      };

    // Process and group data by year, month/week, and gender
    const processAndGroupData = (data) => {
        const labels = new Set();
        let compareSortKey = 0
        data.forEach(report => {

            report.details.forEach(detail => {
                compareSortKey = 0;
                let labels = detail.year.toString();
                compareSortKey = detail.year
                if ('month' in detail) {
                    labels = `${detail.year}_${detail.month}`;
                    compareSortKey = detail.month_id * 10000 + detail.year
                } else if ('week' in detail) {
                    labels = `${detail.year}_${detail.week}`;
                    compareSortKey = detail.week * 10000 + detail.year
                }
                compareSortKey += (detail.gender || 0) * 1000000;

                if (detail.gender !== undefined) {
                    let genderLabel = detail.gender === 1 ? "ชาย" : "หญิง";
                    labels+= "+" + genderLabel;
                }
                detail.compareSortKey = compareSortKey;
                detail.label = labels;
                
            });
            if (selectedCompareOption != 1) {
                report.details.sort((a,b) => a.compareSortKey - b.compareSortKey);
            }

            report.details.forEach(detail => {
                labels.add(detail.label)
            });
            
        });
        // data.forEach(report => {
        //     report.details.forEach(detail => {
        //         let timePeriod = detail.year.toString();
        //         if ('month' in detail) {
        //             timePeriod = `${detail.year}_${detail.month}`;
        //         } else if ('week' in detail) {
        //             timePeriod = `${detail.year}_${detail.week}`;
        //         }
        //         timePeriods.add(timePeriod);

        //         if (detail.gender !== undefined) {
        //             genders.add(detail.gender === 1 ? 'ชาย' : 'หญิง');
        //         }
        //     });
        // });

       // return { timePeriods: Array.from(timePeriods), genders: Array.from(genders) };
       return Array.from(labels)
    };

    //const { timePeriods, genders } = processAndGroupData(data);
    const labels =  processAndGroupData(data)
   
    function setColorClass(colormd5) {
        switch(colormd5) {
            case 1: return 'lightgrey';
            case 2: return 'green';
            case 3: return 'yellow';
            case 4: return 'orange';
            case 5: return 'red';
            default: return '';
        }
    }

    function setColorClassInfected(color20, color100, selectedDisplay) {
        if(selectedDisplay == "1") {
            switch(color20) {
                case 1: return 'lightgrey';
                case 2: return 'green';
                case 3: return 'yellow';
                case 4: return 'orange';
                case 5: return 'red';
                default: return ''; // no additional class
            }
        }

        if(selectedDisplay == "2") {
            switch(color100) {
                case 1: return 'lightgrey';
                case 2: return 'green';
                case 3: return 'yellow';
                case 4: return 'orange';
                case 5: return 'red';
                default: return ''; // no additional class
            }
        }
        
        return '';
    }

    const handleChangeGender = (values) => {
        setSelectedGender(values);
      }

    const handleChangeStatus = (values) => {
        setSelectedStatus(values);
      }

    const shouldRenderHeader = (header_id) => {
        return selectedStatus.some(status => status.value === header_id);
    };

    const shouldRenderGender = (header_id) => {
        return selectedGender.some(status => status.value === header_id);
    };

    const renderSubHeaders = (subHeader, timePeriod) => {
        if (selectedDisplay === "3" || subHeader !== t('PATIENTS_COMPARED_TO_5_YEAR_MEDIAN')) {
            if((subHeader === t('CASE') && !shouldRenderHeader('infected_header')) || (subHeader === t('DEAD') && !shouldRenderHeader('deaths_header'))) {
                return null;
            }
            return <th key={`${timePeriod}-${subHeader}`}>{subHeader}</th>;
        }
        return null;
    };

    const renderDataCells = (detail, key) => {
        if (key === 'infected' && detail != undefined && shouldRenderHeader('infected_header')) {
            return <td className={setColorClassInfected(detail.color20, detail.color100, selectedDisplay)}>{formatNumber(detail[key]) || 0}</td>;
        }
        else if (selectedDisplay === "3" && detail != undefined && key === 'median') {
            return <td className={setColorClass(detail.colormd5)}>{formatNumber(detail[key]) || 0}</td>;
        }
        else if (((key === 'death' && shouldRenderHeader('deaths_header')) || key === 'unknown')  && detail != undefined) {
            return <td>{formatNumber(detail[key]) || 0}</td>;
        }
        else if (selectedDisplay !== "3") {
            return <td>0</td>;
        }
        
    };

    const renderTotalMedianCell = (report) => {
        if (selectedDisplay === "3") {
            return <td className={setColorClass(Math.max(...report.details.map(d => d.colormd5 || 0)))}>
                       {formatNumber(report.totalMedian) || 0}
                   </td>;
        }
        return null;
    };

    // const calculateTotalColSpan = () => {
    //     // Count the number of sub-headers per time period and gender
    //     let subHeaderCount = 0;
    //     timePeriods.forEach(() => {
    //         genders.forEach(() => {
    //             // Add 4 for each gender (flag, median, infected, death)
    //             // If selectedDisplay is not "3", don't count the median column
    //             subHeaderCount += (selectedDisplay === "3" ? 1 + selectedStatus.length : selectedStatus.length);
    //         });
    //     });
    //     return subHeaderCount;
    // };

      const removeNullKeys = (obj) => {
        if (typeof obj !== 'object' || obj === null) {
          // If obj is not an object or is null, return it as is
          return obj;
        }
      
        return Object.entries(obj).reduce((acc, [key, value]) => {
          if (value && typeof value === 'object') {
            acc[key] = removeNullKeys(value); // Recursively process nested objects
          } else if (value !== null) {
            acc[key] = value; // Keep non-null values
          }
          return acc;
        }, {});
      };

    const { sortedData, sortBy, sortDir, setSortBy, setSortDir } = useSort({ data });
    const getSortDetail = (headerCol, subHeaderCol) => {
        let detail;
        if(headerCol === null) {
            detail = subHeaderCol;
        } else {
            const splitTime = headerCol.split('_');
            if (splitTime.length >= 2) {
                detail = {
                    headerCol: {
                        year: splitTime[0].split('+')[0],
                        month_id: hasMonthData ? getMonthNumber(splitTime[1].split('+')[0]) : null,
                        week: hasWeekData ? getMonthNumber(splitTime[1].split('+')[0]) : null,
                        gender: splitTime[1].split('+')[1] === t('MALE') ? 1 : 2
                    },
                    subHeaderCol: subHeaderCol
                }
            } else {
                detail = {
                    headerCol: {
                        year: splitTime[0].split('+')[0],
                        gender: splitTime[0].split('+')[1] === t('MALE') ? 1 : 2
                    },
                    subHeaderCol: subHeaderCol
                }
            }
        }

        return detail;
    }

    const handleClickSubHeaderCol = (headerCol, subHeaderCol) => {
        const detail = removeNullKeys(getSortDetail(headerCol, subHeaderCol));

        if(!(flattenKeys(detail.headerCol) === flattenKeys(sortBy?.headerCol) && detail.subHeaderCol === sortBy?.subHeaderCol)) {
            setSortDir("asc");
        } else {
            setSortDir(dir => {
                
            if(dir === "asc") return "desc";
            else if(dir === "desc") return null;
            else return "asc";
            })
        }
        setSortBy(detail);
    }
    
    const getSortingColArrow = (headerCol, subHeaderCol) => {
        const detail = removeNullKeys(getSortDetail(headerCol, subHeaderCol));

        if(typeof(sortBy) === 'string' && typeof(detail) === 'string' && sortBy !== detail) return null;
        if(!(flattenKeys(sortBy?.headerCol) === flattenKeys(detail.headerCol) && sortBy?.subHeaderCol === detail.subHeaderCol)) return null;
        if(sortDir === "asc") return "↑";
        if(sortDir === "desc") return "↓";
        return null;
    }

    return (
        <div>
            <FormLabel style={{ display: "block", marginTop: "8px", marginBottom: "4px" }}>{t('STATUS_DISPLAY')}</FormLabel>
            <ReactSelect
                placeholder={t('STATUS_DISPLAY')}
                value={selectedStatus}
                onChange={handleChangeStatus}
                options={allStatuses}
                isMulti
                closeMenuOnSelect={false}
                styles={customReactSelectStyles}
            />
            <FormLabel style={{ display: "block", marginTop: "8px", marginBottom: "4px" }}>{t('GENDER_DISPLAY')}</FormLabel>
            <ReactSelect
                placeholder={t('GENDER_DISPLAY')}
                value={selectedGender}
                onChange={handleChangeGender}
                options={allGenders}
                isMulti
                closeMenuOnSelect={false}
                styles={customReactSelectStyles}
            />
        <table id="epidemicReportTable">
             <thead>
                <tr>
                    <th>{t('EPIDEMIC')}</th>
                    {  
                        labels.map(label => {
                            let [timePeriod, value] = label.split("+");
                            return shouldRenderGender(value) && (
                                <th key={`${label}`} colSpan={selectedDisplay === "3" ? (2 + selectedStatus.length) : (1 + selectedStatus.length)}>
                                {hasWeekData ? `${timePeriod.replaceAll('_', ` ${t('WEEK')} `)} (${keyTranslate(value)})` : hasMonthData ? `${timePeriod.split('_')[0]} ${t(thToKey[timePeriod.split('_')[1]])} (${keyTranslate(value)})` : `${timePeriod.replaceAll('_', ' ')} (${keyTranslate(value)})`}  
                                </th>
                            )
                            })
                        }

                    <th colSpan={selectedDisplay === "3" ? ((2 + selectedStatus.length)) : ((1+selectedStatus.length))}>{t('ALL')}</th>
                </tr>
                <tr>
                    <th></th>
                    {
                        labels.map(label => {
                            let [timePeriod, value] = label.split("+");
                            return shouldRenderGender(value) && (
                                <>
                                <th onClick={() => handleClickSubHeaderCol(label, 'flag')} key={`${label}-flag`}>{t('EPIDEMIC_ALERT_STATUS')}{getSortingColArrow(label, 'flag')}</th>
                                {selectedDisplay === "3" && <th onClick={() => handleClickSubHeaderCol(label, 'median')} key={`${label}-median`}>{t('PATIENTS_COMPARED_TO_5_YEAR_MEDIAN')}{getSortingColArrow(label, 'median')}</th>}
                                {shouldRenderHeader('infected_header') && <th onClick={() => handleClickSubHeaderCol(label, 'infected')} key={`${label}-infected`}>{t('CASE')}{getSortingColArrow(label, 'infected')}</th>}
                                {shouldRenderHeader('deaths_header') && <th onClick={() => handleClickSubHeaderCol(label, 'death')} key={`${label}-death`}>{t('DEAD')}{getSortingColArrow(label, 'death')}</th>}
                                </>
                            )
                            })
                        }
                        

                    <>
                    {selectedDisplay === "3" && <th onClick={() => handleClickSubHeaderCol(null, 'totalMedian')} key={`totalMedian`}>{t('PATIENTS_COMPARED_TO_5_YEAR_MEDIAN')}{getSortingColArrow(null, 'totalMedian')}</th>}
                    {shouldRenderHeader('infected_header') && <th onClick={() => handleClickSubHeaderCol(null, 'totalInfected')} id='infected_header'>{t('CASE')}{getSortingColArrow(null, 'totalInfected')}</th>}
                    {shouldRenderHeader('deaths_header') && <th onClick={() => handleClickSubHeaderCol(null, 'totalDeath')} id='deaths_header'>{t('DEAD')}{getSortingColArrow(null, 'totalDeath')}</th>}
                    </>
                </tr>
            </thead>

            <tbody>
            {
                sortedData.map(report => (
                    <tr key={report.epidem_report_id}>
                    <td>{currentLanguage == 'th' ? report.epidem_report_name_th: report.epidem_report_name_en}</td>
                    
                    {
                    labels.flatMap(label => {
                        const [timePeriod, gender] = label.split('+');
                        const [year, monthOrWeek] = timePeriod.split('_');
                        const genderValue = gender === t('MALE') ? 1 : 2;

                        const detail = report.details.find(d => {
                            const isCorrectYear = d.year === parseInt(year, 10);
                            const isCorrectGender = d.gender === genderValue;
                            let isCorrectMonthOrWeek = true;

                            if (hasMonthData) {
                            isCorrectMonthOrWeek = d.month === monthOrWeek;
                            }
                            if (hasWeekData) {
                            isCorrectMonthOrWeek = d.week === parseInt(monthOrWeek, 10);
                            }

                            return isCorrectYear && isCorrectGender && isCorrectMonthOrWeek;
                        });
                        return (
                            shouldRenderGender(gender) && (
                            <React.Fragment key={`${gender}-${timePeriod}`}>
                                <td>{detail ? (detail.flag > 0 ? '!'.repeat(detail.flag) : '-') : '-'}</td>
                                {selectedDisplay === "3" && renderDataCells(detail, 'median')}
                                {renderDataCells(detail, 'infected')}
                                {renderDataCells(detail, 'death')}
                            </React.Fragment>
                            )
                        );
                        })
                    
                    }

                 
                    
                    {renderTotalMedianCell(report)}
                    {shouldRenderHeader('infected_header') && <td className={setColorClassInfected(Math.max(...report.details.map(d => d.color20 || 0)), Math.max(...report.details.map(d => d.color100 || 0)), selectedDisplay)}>
                        {formatNumber(report.totalInfected)}
                    </td>}
                    {shouldRenderHeader('deaths_header') && <td>{formatNumber(report.totalDeath)}</td>}
                    </tr>
                ))
                }

            </tbody>
        </table>
        </div>
    );
};

export default EpidemicReportTableMD5Gender;
