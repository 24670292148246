import { formatNumber } from '@/modules/common/utils';
import React, { useState, useCallback, useEffect } from 'react';
import { Divider, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, Select, Typography } from "@material-ui/core";
import ReactSelect from "react-select";
import { useSort } from '@/modules/common/hooks';
import { useTranslation } from 'react-i18next';
import { thToKey } from '@/modules/common/utils/i18n';

const EpidemicReportTableMD5Group = ({ data, getGroupDetail, selectedDisplay, selectedCompareOption, selectedStatus, setSelectedStatus }) => {
    const [expandedRows, setExpandedRows] = useState(new Set());
    const [groupDetails, setGroupDetails] = useState({});

    const hasMonthData = data.some(report => report.details.some(detail => 'month' in detail));
    const hasWeekData = data.some(report => report.details.some(detail => 'week' in detail));

    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const [localSortDir, setLocalSortDir] = useState({})

    const [allStatuses, setAllStatuses] = useState([{
        value: "infected_header",
        label: t('CASE')
    },
    {
        value: "deaths_header",
        label: t('DEAD')
    }]);

    const handleLanguageChanged = useCallback(() => {
        setAllStatuses([{
            value: "infected_header",
            label: t('CASE')
        },
        {
            value: "deaths_header",
            label: t('DEAD')
        }])
        
    }, []);
    
    useEffect(() => {
        i18n.on('languageChanged', handleLanguageChanged);
        return () => {
            i18n.off('languageChanged', handleLanguageChanged);
        };
    }, [handleLanguageChanged])

    useEffect(() => {
        let status = [];
        for (let i = 0; i<allStatuses.length; i++) {
            for(let j = 0; j<selectedStatus.length; j++) {
                if (allStatuses[i].value == selectedStatus[j].value) {
                    status.push(allStatuses[i])
                    break;
                }
            }
        }
        setSelectedStatus(status);
    }, [allStatuses])


    function flattenKeys(obj) {
        let resultString = '';

        function flatten(obj, prefix = '') {
            for (let key in obj) {
                if (!obj.hasOwnProperty(key)) continue;
    
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    flatten(obj[key], prefix + key + '_');
                } else {
                    resultString += `${prefix}${key}_${obj[key]} `;
                }
            }
        }
    
        flatten(obj);
        return resultString.trim(); // Remove trailing space 
    }

    const customReactSelectStyles = {
        menu: (base) => ({
          ...base,
          zIndex: 11, // Set your desired zIndex value
        }),
      };

      const handleChangeStatus = (values) => {
        setSelectedStatus(values);
      }

    const shouldRenderHeader = (header_id) => {
        return selectedStatus.some(status => status.value === header_id);
    };

    const toggleRowExpansion = async (id) => {
        if (expandedRows.has(id)) {
            setExpandedRows(prev => new Set([...prev].filter(eid => eid !== id)));
        } else {
            setExpandedRows(prev => new Set(prev.add(id)));
            if (!groupDetails[id]) {
                const details = await getGroupDetail(id);
                setGroupDetails(prev => ({ ...prev, [id]: details }));
            }
        }
    };

    const isRowExpanded = (id) => expandedRows.has(id);

    // Function to create a unique identifier for each time period
    const getTimePeriod = (detail) => {
        const suffix = detail.isPrediction && !(detail.missing_flag) ? '+ ('+ t('PREDICTED') + ')' : '';
        if ('week' in detail) {
            const paddedWeek = String(detail.week).padStart(2, '0');
        return `${detail.year}_${paddedWeek}${suffix}`;
        } else if ('month' in detail) {
            return `${detail.year}_${detail.month}${suffix}`;
        } else {
            return `${detail.year.toString()}${suffix}`;
        }
    };

    // Parse the data to get unique time periods
    let uniqueTimePeriods = Array.from(
        new Set(data.flatMap(report => report.details.map(getTimePeriod)))
    );

    const thaiMonths = {
        'มกราคม': 1, 'กุมภาพันธ์': 2, 'มีนาคม': 3, 'เมษายน': 4, 
        'พฤษภาคม': 5, 'มิถุนายน': 6, 'กรกฎาคม': 7, 'สิงหาคม': 8, 
        'กันยายน': 9, 'ตุลาคม': 10, 'พฤศจิกายน': 11, 'ธันวาคม': 12
      };
      
      const getMonthNumber = (month) => {
        return thaiMonths[month] || parseInt(month, 10);
      };
      
      const sortArray = arr => {
        return arr.sort((a, b) => {
          const [yearA, monthA] = a.split('_');
          const [yearB, monthB] = b.split('_');
      
          const numericMonthA = getMonthNumber(monthA);
          const numericMonthB = getMonthNumber(monthB);
      
          if (numericMonthA === numericMonthB) {
            return parseInt(yearA, 10) - parseInt(yearB, 10);
          }
          return numericMonthA - numericMonthB;
        });
      };

    if(selectedCompareOption === "2") {
        uniqueTimePeriods = sortArray(uniqueTimePeriods);
    }

    const headers = uniqueTimePeriods.map(timePeriod => ({
        timePeriod,
        subHeaders: [
            t('EPIDEMIC_ALERT_STATUS'), 
            t('PATIENTS_COMPARED_TO_5_YEAR_MEDIAN'), 
            t('CASE'),
            t('DEAD')
        ]
    }));

    const setColorClass = (colormd5) => {
        switch(colormd5) {
            case 1: return 'lightgrey';
            case 2: return 'green';
            case 3: return 'yellow';
            case 4: return 'orange';
            case 5: return 'red';
            default: return '';
        }
    };

    function setColorClassInfected(color20, color100, selectedDisplay) {
        if(selectedDisplay == "1") {
            switch(color20) {
                case 1: return 'lightgrey';
                case 2: return 'green';
                case 3: return 'yellow';
                case 4: return 'orange';
                case 5: return 'red';
                default: return ''; // no additional class
            }
        }

        if(selectedDisplay == "2") {
            switch(color100) {
                case 1: return 'lightgrey';
                case 2: return 'green';
                case 3: return 'yellow';
                case 4: return 'orange';
                case 5: return 'red';
                default: return ''; // no additional class
            }
        }
        
        return '';
    }

    const renderSubHeaders = (subHeader, timePeriod) => {
        if (selectedDisplay === "3" || subHeader !== t('PATIENTS_COMPARED_TO_5_YEAR_MEDIAN')) {
            if((subHeader === t('CASE') && !shouldRenderHeader('infected_header')) || (subHeader === t('DEAD') && !shouldRenderHeader('deaths_header'))) {
                return null;
            }
            return <th onClick={() => handleClickSubHeaderCol(timePeriod, subHeaderToKey[subHeader])} data-column={subHeader} key={`${timePeriod} (${subHeader})`}>{subHeader}{getSortingColArrow(timePeriod, subHeaderToKey[subHeader])}</th>;
        }
        return null;
    };

    const renderDataCells = (detail, key) => {
        if (key === 'infected' && detail != undefined && shouldRenderHeader('infected_header')) {
            return <td className={setColorClassInfected(detail.color20, detail.color100, selectedDisplay)}>{formatNumber(detail[key]) || 0}</td>;
        }
        else if (selectedDisplay === "3" && detail != undefined && key === 'median') {
            return <td className={setColorClass(detail.colormd5)}>{formatNumber(detail[key]) || 0}</td>;
        }
        else if (((key === 'death' && shouldRenderHeader('deaths_header')) || key === 'unknown')  && detail != undefined) {
            return <td>{formatNumber(detail[key]) || 0}</td>;
        }
        else if (selectedDisplay !== "3" && key !== 'infected' && key !== 'death') {
            return <td>0</td>;
        }
        
    };

    const renderTotalMedianCell = (report) => {
        if (selectedDisplay === "3") {
            return <td className={setColorClass(Math.max(...report.details.map(d => d.colormd5 || 0)))}>
                       {formatNumber(report.totalMedian) || 0}
                   </td>;
        }
    };

    const subHeaderToKey = {
        [t('EPIDEMIC_ALERT_STATUS')]: 'flag', [t('PATIENTS_COMPARED_TO_5_YEAR_MEDIAN')]: 'median', [t('CASE')]: 'infected', [t('DEAD')]: 'death'
      };

      const removeNullKeys = (obj) => {
        if (typeof obj !== 'object' || obj === null) {
          // If obj is not an object or is null, return it as is
          return obj;
        }
      
        return Object.entries(obj).reduce((acc, [key, value]) => {
          if (value && typeof value === 'object') {
            acc[key] = removeNullKeys(value); // Recursively process nested objects
          } else if (value !== null) {
            acc[key] = value; // Keep non-null values
          }
          return acc;
        }, {});
      };

    const { sortedData, sortBy, sortDir, setSortBy, setSortDir } = useSort({ data });
    const getSortDetail = (headerCol, subHeaderCol) => {
        let detail;
        if(headerCol === null) {
            detail = subHeaderCol;
        } else {
            const splitTime = headerCol.split('_');
            if (splitTime.length >= 2) {
                detail = {
                    headerCol: {
                        year: splitTime[0].split('+')[0],
                        month_id: hasMonthData ? getMonthNumber(splitTime[1].split('+')[0]) : null,
                        week: hasWeekData ? getMonthNumber(splitTime[1].split('+')[0]) : null,
                    },
                    subHeaderCol: subHeaderCol
                }
            } else {
                detail = {
                    headerCol: {
                        year: splitTime[0].split('+')[0],
                    },
                    subHeaderCol: subHeaderCol
                }
            }
        }

        return detail;
    }

    const handleClickSubHeaderCol = (headerCol, subHeaderCol) => {
        const detail = removeNullKeys(getSortDetail(headerCol, subHeaderCol));

        if(!(flattenKeys(detail.headerCol) === flattenKeys(sortBy?.headerCol) && detail.subHeaderCol === sortBy?.subHeaderCol)) {
            setSortDir("asc");
        } else {
            setSortDir(dir => {
                
            if(dir === "asc") return "desc";
            else if(dir === "desc") return null;
            else return "asc";
            })
        }
        setSortBy(detail);
    }
    
    const getSortingColArrow = (headerCol, subHeaderCol) => {
        const detail = removeNullKeys(getSortDetail(headerCol, subHeaderCol));

        if(typeof(sortBy) === 'string' && typeof(detail) === 'string' && sortBy !== detail) return null;
        if(!(flattenKeys(sortBy?.headerCol) === flattenKeys(detail.headerCol) && sortBy?.subHeaderCol === detail.subHeaderCol)) return null;
        if(sortDir === "asc") return "↑";
        if(sortDir === "desc") return "↓";
        return null;
    }


    return (
        <div>
            <FormLabel style={{ display: "block", marginTop: "8px", marginBottom: "4px" }}>{t('STATUS_DISPLAY')}</FormLabel>
            <ReactSelect
                placeholder={t('STATUS_DISPLAY')}
                value={selectedStatus}
                onChange={handleChangeStatus}
                options={[
                {
                    value: "infected_header",
                    label: t('CASE')
                },
                {
                    value: "deaths_header",
                    label: t('DEAD')
                }
                ]}
                isMulti
                closeMenuOnSelect={false}
                styles={customReactSelectStyles}
            />
        <table id="epidemicReportTable">
            <thead>
                <tr>
                    <th>{t('EPIDEMIC')}</th>
                    {headers.map(header => (
                        <th key={header.timePeriod} colSpan={selectedDisplay === "3" ? 
                            header.subHeaders.length - 2 + selectedStatus.length : 
                            header.subHeaders.filter(sh => sh !== t('PATIENTS_COMPARED_TO_5_YEAR_MEDIAN')).length - 2 + selectedStatus.length}>
                            {hasWeekData ? `${header.timePeriod.replaceAll('_', ` ${t('WEEK')} `)}` : hasMonthData ? `${header.timePeriod.split('_')[0]} ${t(thToKey[header.timePeriod.split('_')[1]]).replaceAll('+', '')}` : `${header.timePeriod.replaceAll('_', ' ')}`}    
                        </th>
                    ))}
                    <th colSpan={selectedDisplay === "3" ? ((2 + selectedStatus.length)) : ((1+selectedStatus.length))}>{t('ALL')}</th>
                </tr>
                <tr>
                    <th></th>
                    {headers.flatMap(header =>
                        header.subHeaders.map(subHeader => (
                            typeof subHeader === 'object'
                                ? subHeader.subHeaders.map(sh => <th onClick={() => handleClickSubHeaderCol(header.timePeriod, subHeaderToKey[sh])}  key={`${header.timePeriod}-${subHeader.header}-${sh}`}>{sh}{getSortingColArrow(header.timePeriod, subHeaderToKey[sh])}</th>)
                                : renderSubHeaders(subHeader, header.timePeriod)
                        ))
                    )}
                    {selectedDisplay === "3" && <th onClick={() => handleClickSubHeaderCol(null, 'totalMedian')} key={`totalMedian`}>{t('PATIENTS_COMPARED_TO_5_YEAR_MEDIAN')}{getSortingColArrow(null, 'totalMedian')}</th>}
                    {shouldRenderHeader('infected_header') && <th onClick={() => handleClickSubHeaderCol(null, 'totalInfected')} id='infected_header'>{t('CASE')}{getSortingColArrow(null, 'totalInfected')}</th>}
                    {shouldRenderHeader('deaths_header') && <th onClick={() => handleClickSubHeaderCol(null, 'totalDeath')} id='deaths_header'>{t('DEAD')}{getSortingColArrow(null, 'totalDeath')}</th>}
                </tr>
            </thead>
            <tbody>
                {sortedData.map(report => (
                    <React.Fragment key={report.epidem_group_id}>
                        <tr>
                            <td>
                                <button onClick={() => toggleRowExpansion(report.epidem_group_id)}>
                                    {isRowExpanded(report.epidem_group_id) ? '-' : '+'}
                                </button>
                                {currentLanguage == 'th' ? report.epidem_group_name_th: report.epidem_group_name_en}
                            </td>
                            {uniqueTimePeriods.map(timePeriod => {
                                const detail = report.details.find(d => getTimePeriod(d) === timePeriod) || {};
                                return (
                                    <React.Fragment key={`${report.epidem_group_id}-${timePeriod}`}>
                                        <td>{detail.flag > 0 ? '!'.repeat(detail.flag) : '-'}</td>
                                        {selectedDisplay === "3" && renderDataCells(detail, 'median')}
                                        {renderDataCells(detail, 'infected')}
                                        {renderDataCells(detail, 'death')}
                                    </React.Fragment>
                                );
                            })}
                            {renderTotalMedianCell(report)}
                            {shouldRenderHeader('infected_header') && <td className={setColorClassInfected(Math.max(...report.details.map(d => d.color20 || 0)), Math.max(...report.details.map(d => d.color100 || 0)), selectedDisplay)}>
                                {formatNumber(report.totalInfected)}
                            </td>}
                            {shouldRenderHeader('deaths_header') && <td>{formatNumber(report.totalDeath)}</td>}
                        </tr>
                        {isRowExpanded(report.epidem_group_id) && groupDetails[report.epidem_group_id] && 
                            groupDetails[report.epidem_group_id].data.data.map(detail => (
                                <tr key={detail.epidem_report_id}>
                                    <td className='expandbg'>  - {currentLanguage == 'th' ? detail.epidem_report_name_th: detail.epidem_report_name_en}</td>
                                    {uniqueTimePeriods.map(timePeriod => {
                                        const detailx = detail.details.find(d => getTimePeriod(d) === timePeriod) || {};
                                        return (
                                            <React.Fragment key={`${detail.epidem_report_id}-${timePeriod}`}>
                                                <td>{detailx.flag > 0 ? '!'.repeat(detailx.flag) : '-'}</td>
                                                {selectedDisplay === "3" && renderDataCells(detailx, 'median')}
                                                {renderDataCells(detailx, 'infected')}
                                                {renderDataCells(detailx, 'death')}
                                            </React.Fragment>
                                        );
                                    })}
                                    {renderTotalMedianCell(report)}
                                    {shouldRenderHeader('infected_header') && <td className={setColorClassInfected(Math.max(...report.details.map(d => d.color20 || 0)), Math.max(...report.details.map(d => d.color100 || 0)), selectedDisplay)}>
                                        {formatNumber(detail.totalInfected)}
                                    </td>}
                                    {shouldRenderHeader('deaths_header') && <td>{formatNumber(detail.totalDeath)}</td>}
                                </tr>
                            ))
                        }
                    </React.Fragment>
                ))}
            </tbody>
        </table>
        </div>
    );
};

export default EpidemicReportTableMD5Group;
