import React, { useMemo, useState } from "react";

// For specific Epidemic AI interested data client sorting
const useSort = ({ data = [], defaultSortKey = null, defaultSortDir = null }) => {
  // Can be either:
  //  - string (for fixed column)
  //    example: "totalInfected"
  //  - an object describes Header column and sub-header column (for dynamic columns spanned by time period, category)
  //    example:
  //    {
  //      headerCol: "2019_พฤษภาคม"
  //      subHeaderCol: "infected"
  //    }
  const [sortBy, setSortBy] = useState(defaultSortKey);

  // Can be null, "asc" or "desc"
  const [sortDir, setSortDir] = useState(defaultSortDir);

  const sortedData = useMemo(() => {
    if (!(sortBy && sortDir)) return data;
    const sorted = [...data];
    sorted?.sort((left, right) => {
      if (sortDir === "desc") {
        let tmp = left;
        left = right;
        right = tmp;
      }
      const candidate = (side) => {
        return side?.[sortBy?.headerCol]?.[sortBy?.subHeaderCol];
      };
      const leftCandidate = candidate(left[1]);
      const rightCandidate = candidate(right[1]);
      if (leftCandidate < rightCandidate) return -1;
      else if (leftCandidate > rightCandidate) return 1;
      else return 0;
    });
    return sorted;
  }, [data, sortBy, sortDir]);

  return {
    sortBy,
    setSortBy,
    sortDir,
    setSortDir,
    sortedData,
  };
};

export default useSort;
