import {
  DiseaseNotification,
  SelectDisplayDiseaseType,
  SelectDisplayInfectedType,
  SelectTimePeriod,
} from "@/modules/common/components";
import { useState } from "react";
import { CompareChart } from "./components";

const Compare = () => {
  const [selectedYearValue, setSelectedYearValue] = useState("");
  const [selectedWeekValue, setSelectedWeekValue] = useState("");
  const [selectedEndYearValue, setSelectedEndYearValue] = useState("");
  const [selectedEndWeekValue, setSelectedEndWeekValue] = useState("");
  const [selectedMonthValue, setSelectedMonthValue] = useState("");
  const [selectedEndMonthValue, setSelectedEndMonthValue] = useState("");
  const [selectedTime, setSelectedTime] = useState("1");
  const [selectedOutputType, setSelectedOutputType] = useState("1");
  const [epidemSelected, setEpidemSelected] = useState([]);
  const [searchTrigger, setSearchTrigger] = useState(0);
  const [selectedDisplay, setSelectedDisplay] = useState("3");
  const [downloadData, setDownloadData] = useState(null)
  const [selectedCompareOption, setSelectedCompareOption] = useState("1");

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      {" "}
      {/* This container has a maximum width of 1200px and is centered */}
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <SelectDisplayDiseaseType
          selectedOutputType={selectedOutputType}
          setSelectedOutputType={setSelectedOutputType}
          canSelectDisease={true}
          epidemSelected={epidemSelected}
          setEpidemSelected={setEpidemSelected}
          multiple={true}
        />
        <SelectTimePeriod
          selectedYearValue={selectedYearValue}
          setSelectedYearValue={setSelectedYearValue}
          selectedWeekValue={selectedWeekValue}
          setSelectedWeekValue={setSelectedWeekValue}
          selectedEndYearValue={selectedEndYearValue}
          setSelectedEndYearValue={setSelectedEndYearValue}
          selectedEndWeekValue={selectedEndWeekValue}
          setSelectedEndWeekValue={setSelectedEndWeekValue}
          selectedMonthValue={selectedMonthValue}
          setSelectedMonthValue={setSelectedMonthValue}
          selectedEndMonthValue={selectedEndMonthValue}
          setSelectedEndMonthValue={setSelectedEndMonthValue}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          setSearchTrigger={setSearchTrigger}
          downloadData={downloadData}
          page={'compare'}
          selectedCompareOption={selectedCompareOption}
          setSelectedCompareOption={setSelectedCompareOption}
        />
        <SelectDisplayInfectedType selectedDisplay={selectedDisplay} setSelectedDisplay={setSelectedDisplay} />
        {/* <PatientRatio/> */}
        <DiseaseNotification />
      </div>
      <div style={{ flex: 1 }}>
        <CompareChart
          selectedYearValue={selectedYearValue}
          selectedWeekValue={selectedWeekValue}
          selectedEndYearValue={selectedEndYearValue}
          selectedEndWeekValue={selectedEndWeekValue}
          selectedMonthValue={selectedMonthValue}
          selectedEndMonthValue={selectedEndMonthValue}
          selectedTime={selectedTime}
          selectedOutputType={selectedOutputType}
          epidemSelected={epidemSelected}
          searchTrigger={searchTrigger}
          selectedDisplay={selectedDisplay}
          setDownloadData={setDownloadData}
          selectedCompareOption={selectedCompareOption}
        />
      </div>
    </div>
  );
};

export default Compare;