import React from "react";
import { Select, MenuItem, InputLabel } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function AlertDropdownTime(props) {
  const { t } = useTranslation();
  const handleYearChange = (event) => {
    props.setSelectedYearValue(event.target.value);
  };
  const handleWeekChange = (event) => {
    props.setSelectedWeekValue(event.target.value);
  };
  const handleMonthChange = (event) => {
    props.setSelectedMonthValue(event.target.value);
  };

  return (
    <div style={{ display: "flex", alignItems: "center", margin: "0 0px", textAlign: "center" }}>
      {/* Always show start year label and selector */}
      <InputLabel id="year-label">{t('YEAR')}</InputLabel>
      <Select
        labelId="year-label"
        value={props.selectedYearValue}
        onChange={handleYearChange}
        style={{ margin: "0 10px" }}
      >
        {Array.from({ length: 9 }, (_, i) => i + 2016).map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>

      {/* Conditionally show week or month selector based on timeframe */}
      {props.selectedTime === "3" && (
        <>
          <InputLabel id="week-label">{t('WEEK')}</InputLabel>
          <Select
            labelId="week-label"
            value={props.selectedWeekValue}
            onChange={handleWeekChange}
            style={{ margin: "0 10px" }}
          >
            {Array.from({ length: 53 }, (_, i) => i + 1).map((week) => (
              <MenuItem key={week} value={week}>
                {week}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
      {props.selectedTime === "2" && (
        <>
          <InputLabel id="month-label">{t('MONTH')}</InputLabel>
          <Select
            labelId="month-label"
            value={props.selectedMonthValue}
            onChange={handleMonthChange}
            style={{ margin: "0 10px" }}
          >
            {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
              <MenuItem key={month} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    </div>
  );
}

export default AlertDropdownTime;
