import { useMemo, useState } from "react";
import { TagSelector } from "@/modules/common/components";
import tags from "./tags";

const Interested = () => {
  const [selectedTag, setSelectedTag] = useState(tags[0]);
  const Content = useMemo(() => selectedTag.Content, [selectedTag]);

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      {/* This container has a maximum width of 1200px and is centered */}
      <TagSelector tags={tags} setSelectedTag={setSelectedTag} />
      <Content selectedFilterTag={selectedTag}/>
    </div>
  );
};

export default Interested;
