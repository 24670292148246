import { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Tabs } from "@material-ui/core";
import StyledTab from "../common/components/styledTab";
import { useTranslation } from "react-i18next";

function TagSelector({
  tags,
  setSelectedTag
}) {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, value) => {
    setSelectedTab(value);
    setSelectedTag(tags[value]);
  };

  return (
    <Container>
      <Tabs style={{marginBottom: '20px'}} value={selectedTab} onChange={handleTabChange} centered>
        {tags.map(tag => (
          <StyledTab key={tag.key} label={t(tag.label)} component={Link} to={`#${tag.key}`} />
        ))}
        {/* <StyledTab label="เพศ" component={Link} to="#gender"/>
        <StyledTab label="กลุ่มอายุ" component={Link} to="#age"/>
        <StyledTab label="เชื้อชาติ" component={Link} to="#nationality"/>
        <StyledTab label="พื้นที่" component={Link} to="#area"/> */}
      </Tabs>
    </Container>
  );
}

export default TagSelector;