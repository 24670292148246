import React, { useCallback, useEffect, useMemo } from "react";
import { formatNumber } from "@/modules/common/utils";
import { useSort } from "@/modules/interested/routes/page/tags/common/hooks";
import { useTranslation } from "react-i18next";
import { thToKey } from "@/modules/common/utils/i18n";

const TableSpecificDisease = ({ data, selectedDisplay, selectedNationalities, selectedPatientStatuses }) => {
  const { t } = useTranslation();
  const { details } = data[0];

  // Updated function to determine the time period
  const getTimePeriod = (detail) => {
    if ("week" in detail) {
      const paddedWeek = String(detail.week).padStart(2, "0");
      return `${detail.year}_${paddedWeek}`;
    } else if ("month" in detail) {
      return `${detail.year}_${t(thToKey?.[detail.month])}`;
    } else {
      return `${detail.year}`;
    }
  };

  // Get all unique time periods
  const allTimePeriods = Array.from(new Set(details.map(getTimePeriod)));

  const rowData = details.reduce((acc, detail) => {
    const key = detail.nationality;
    const timePeriod = getTimePeriod(detail);
    const periodData = acc[key] || {};

    periodData[timePeriod] = {
      median: detail.median,
      infected: detail.infected,
      treated: detail.treated,
      death: detail.death,
      unknown: detail.unknown,
      colormd5: detail.colormd5,
      color20: detail.color20,
      color100: detail.color100,
      flag: detail.flag,
    };

    acc[key] = periodData;
    return acc;
  }, {});

  const setColorClass = (colormd5) => {
    switch (colormd5) {
      case 1:
        return "lightgrey";
      case 2:
        return "green";
      case 3:
        return "yellow";
      case 4:
        return "orange";
      case 5:
        return "red";
      default:
        return "";
    }
  };

  function setColorClassInfected(color20, color100, selectedDisplay) {
    if (selectedDisplay == "1") {
      switch (color20) {
        case 1:
          return "lightgrey";
        case 2:
          return "green";
        case 3:
          return "yellow";
        case 4:
          return "orange";
        case 5:
          return "red";
        default:
          return ""; // no additional class
      }
    }

    if (selectedDisplay == "2") {
      switch (color100) {
        case 1:
          return "lightgrey";
        case 2:
          return "green";
        case 3:
          return "yellow";
        case 4:
          return "orange";
        case 5:
          return "red";
        default:
          return ""; // no additional class
      }
    }

    return "";
  }

  const renderDataCells = (detail, key) => {
    if (key === "infected" && detail) {
      return (
        <td className={setColorClassInfected(detail.color20, detail.color100, selectedDisplay)}>
          {formatNumber(detail[key]) || 0}
        </td>
      );
    } else if (selectedDisplay === "3" && detail && key === "median") {
      return <td className={setColorClass(detail.colormd5)}>{formatNumber(detail[key]) || 0}</td>;
    } else if ((key === "death" || key === "unknown") && detail) {
      return <td>{formatNumber(detail[key]) || 0}</td>;
    } else if (selectedDisplay !== "3" && key === "median") {
      return null;
    }
    return <td>0</td>;
  };

  const renderTotalMedianCell = (report) => {
    if (selectedDisplay === "3") {
      return (
        <td className={setColorClass(Math.max(...report.details.map((d) => d.colormd5 || 0)))}>
          {report.totalMedian || 0}
        </td>
      );
    }
    return null;
  };

  const selectedRowData = useMemo(() => {
    if (selectedNationalities.length === 0) return rowData;
    const _selectedRowData = { ...rowData };
    Object.keys(_selectedRowData)
      .filter((key) => {
        return selectedNationalities.every((nationality) => {
          if(t(thToKey?.[key]) === t('TOTAL_FOREIGNER')) {
            return nationality.label !== t('TOTAL_FOREIGNER')
          }
          const splitted = key.split("-");
          return !(nationality.value.toString() === splitted[0].trim() && nationality.label === t(thToKey?.[splitted[1].trim()]));
        });
      })
      .forEach((key) => delete _selectedRowData[key]);
    return _selectedRowData;
  }, [rowData, selectedNationalities, t]);

  const patientStatusesHas = useCallback(
    (status) => {
      if (selectedPatientStatuses.length === 0) return true;
      return !!selectedPatientStatuses.find((s) => s.value === status);
    },
    [selectedPatientStatuses]
  );

  const patientStatusSpan = useMemo(() => {
    if (selectedPatientStatuses.length === 0) return 3;
    return selectedPatientStatuses.length;
  }, [selectedPatientStatuses]);

  const { sortBy, sortDir, setSortBy, setSortDir, sortedData } = useSort({
    data: Object.entries(selectedRowData),
  });

  const handleClickSubHeaderCol = (headerCol, subHeaderCol) => {
    if(!(headerCol === sortBy?.headerCol && subHeaderCol === sortBy?.subHeaderCol)) {
      setSortDir("asc");
    } else {
      setSortDir(dir => {
        if(dir === "asc") return "desc";
        else if(dir === "desc") return null;
        else return "asc";
      })
    }
    setSortBy({ headerCol, subHeaderCol });
  }

  const getSortingColArrow = (headerCol, subHeaderCol) => {
    if(!(sortBy?.headerCol === headerCol && sortBy?.subHeaderCol === subHeaderCol)) return null;
    if(sortDir === "asc") return "↑";
    if(sortDir === "desc") return "↓";
    return null;
  }


  return (
    <table id="epidemicReportTable">
      <thead>
        <tr>
          <th>{t('NATIONALITY')}</th>
          {allTimePeriods.map((timePeriod) => (
            <th colSpan={(selectedDisplay === "3" ? 3 : 2) + patientStatusSpan} key={timePeriod}>
              {timePeriod.replaceAll("_", " ")}
            </th>
          ))}
        </tr>
        <tr>
          <th></th>
          {allTimePeriods.flatMap((timePeriod) => (
            <React.Fragment key={timePeriod}>
              <th onClick={() => handleClickSubHeaderCol(timePeriod, 'flag')}>{t('EPIDEMIC_ALERT_STATUS')}{getSortingColArrow(timePeriod, 'flag')}</th>
              {selectedDisplay === "3" && <th onClick={() => handleClickSubHeaderCol(timePeriod, 'median')}>{t('PATIENTS_COMPARED_TO_5_YEAR_MEDIAN')}{getSortingColArrow(timePeriod, 'median')}</th>}
              <th onClick={() => handleClickSubHeaderCol(timePeriod, "infected")}>{t('TOTAL_INFECTED')}{getSortingColArrow(timePeriod, 'infected')}</th>
              {patientStatusesHas("treated") && <th onClick={() => handleClickSubHeaderCol(timePeriod, 'treated')}>{t('TREATED')}{getSortingColArrow(timePeriod, 'treated')}</th>}
              {patientStatusesHas("death") && <th onClick={() => handleClickSubHeaderCol(timePeriod, 'death')}>{t('DEAD')}{getSortingColArrow(timePeriod, 'death')}</th>}
              {patientStatusesHas("unknown") && <th onClick={() => handleClickSubHeaderCol(timePeriod, 'unknown')}>{t('UNKNOWN')}{getSortingColArrow(timePeriod, 'unknown')}</th>}
            </React.Fragment>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedData.map(([key, periodData]) => {
          let keyStr = t(thToKey?.[key]);
          if(key.includes('-')) {
            const keySplit = key.split('-');
            keyStr = `${keySplit[0].trim()} - ${t(thToKey?.[keySplit[1].trim()])}`;
          }
          return (
            <tr key={key}>
              <td>{keyStr}</td>
              {allTimePeriods.map((timePeriod) => (
                <React.Fragment key={timePeriod}>
                  <td>{periodData[timePeriod]?.flag > 0 ? "!".repeat(periodData[timePeriod]?.flag) : "-"}</td>
                  {selectedDisplay === "3" && (
                    <td className={setColorClass(periodData[timePeriod]?.colormd5)}>
                      {formatNumber(periodData[timePeriod]?.median) || 0}
                    </td>
                  )}
                  {renderDataCells(periodData[timePeriod], "infected")}
                  {patientStatusesHas("treated") && <td>{formatNumber(periodData[timePeriod]?.treated) || 0}</td>}
                  {patientStatusesHas("death") && <td>{formatNumber(periodData[timePeriod]?.death) || 0}</td>}
                  {patientStatusesHas("unknown") && <td>{formatNumber(periodData[timePeriod]?.unknown) || 0}</td>}
                </React.Fragment>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableSpecificDisease;
