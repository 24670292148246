import { thToKey } from "./i18n";

export const useKeyTranslate = (tag, t) => (key) => {
  switch (tag) {
    case "gender": return key === "ชาย" ? t('MALE') : t('FEMALE');
    case "age_range":
    case "age": return key;
    case "nationality": {
      if(key === "รวมต่างชาติ") {
        return t('TOTAL_FOREIGNER');
      }
      const [num = '', nationality = ''] = key?.split('-') || [];
      return `${num.trim()} - ${t(thToKey?.[nationality.trim()] || nationality.trim())}`
    }
    case "area": return t(thToKey?.[key] || key)
    case "health_area":
    case "health-area": {
      const [, no = ''] = key?.split(' ') || [];
      return `${t('HEALTH_AREA_NO')} ${no}`
    }
    default:
      return null;
  }
}