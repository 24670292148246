import React from 'react';
import { useTranslation } from 'react-i18next';

const TableNotFound = () => {
  const { t } = useTranslation();
  return (
    <div style={{ textAlign: 'center', marginTop: '20px', fontSize: '18px', paddingBottom: '20px' }}>
      {t('NO_RESULTS_FOUND')}
    </div>
  );
};

export default TableNotFound;
