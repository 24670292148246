import React, { useCallback, useMemo, useState } from "react";
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps";
import { geoWinkel3 } from "d3-geo-projection";
import { scaleLinear } from "d3-scale";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import ThaiProvinces from "../../utils/thai_province.json";
import { formatNumber } from "../../utils";
import { useTranslation } from "react-i18next";

export const MapChartMode = {
  REGION: "region",
  PROVINCE: "province",
};

const MapChartModeSpec = Object.freeze({
  [MapChartMode.REGION]: {
    dataUrl: "/th-all-reg.topo.json",
    geoPropKey: "region",
    pascalToThai: {
      Northern: "เหนือ",
      Central: "กลาง",
      Eastern: "ตะวันออก",
      Northeastern: "ตะวันออกเฉียงเหนือ",
      Western: "ตะวันตก",
      Southern: "ใต้",
    },
  },
  [MapChartMode.PROVINCE]: {
    dataUrl: "/thailand.json",
    geoPropKey: "CHA_NE",
    pascalToThai: ThaiProvinces.reduce(
      (obj, province) => ({
        ...obj,
        [province.name]: province.province,
      }),
      {}
    ),
  },
});

export default function MapChart({
  mode = MapChartMode.PROVINCE,
  valueTypeLabel = "ติดเชื้อ",
  data = {},
  width = 960,
  height = 500,
  scale = 2000,
  colorRange = ["#f5c4c4", "#8b0000"],
  viewBox = "0 0 800 600",
  style,
}) {
  const { t, i18n } = useTranslation();
  const [content, setContent] = useState("");
  const projection = useMemo(
    () =>
      geoWinkel3()
        .scale(scale)
        // Customize the projection to make the center of Thailand become the center of the map
        .rotate([-100.6331, -13.2])
        .translate([width/2 - width*0.1,height/2]),
    [width, height, scale]
  );

  const getColor = useCallback(
    (value) => {
      const values = Object.values(data);
      const minVal = Math.min(...values);
      const maxVal = Math.max(...values);
      const colorScale = scaleLinear().domain([minVal, maxVal]).range(colorRange);
      const result = colorScale(value);
      return result;
    },
    [data, colorRange]
  );

  const spec = useMemo(() => {
    return MapChartModeSpec[mode];
  }, [mode]);

  return (
    <>
      <ComposableMap projection={projection}  viewBox={viewBox} style={style}>
        <Geographies geography={spec.dataUrl}>
          {({ geographies }) => {
            return geographies.map((geo) => {
              const pascal = geo.properties?.[spec.geoPropKey];
              let thaiName = spec.pascalToThai?.[pascal] || "";
              const value = data?.[thaiName] || 0;
              if(mode === MapChartMode.PROVINCE) {
                const province = ThaiProvinces.find(p => p.province === thaiName);
                thaiName = province?.[`province_${i18n.language}`];
              }
              return (
                <Geography
                  data-tooltip-id="map-tooltip"
                  key={geo.rsmKey}
                  onMouseEnter={() => {
                    setContent(`${thaiName} ${valueTypeLabel} ${formatNumber(value)}`);
                  }}
                  onMouseLeave={() => {
                    setContent("");
                  }}
                  geography={geo}
                  fill={getColor(value)}
                />
              );
            });
          }}
        </Geographies>
      </ComposableMap>
      <ReactTooltip id="map-tooltip" place="bottom">
        {content}
      </ReactTooltip>
    </>
  );
}
