// formatUtils.js
export const formatNumber = (number) => {
  try {
    return number.toLocaleString();
  } catch (error) {
    console.error('Error in formatting number:', error);
    // Return the original number or some default value in case of error
    return number;
  }
};

// You can add more utility functions here if needed
