import React, { useState, useEffect } from "react";
import { DiseaseNotification, SelectDisplayInfectedType, SelectTimePeriod } from "@/modules/common/components";
import { SelectDisplayDiseaseType } from "./components";
import SpecificDiseasePieChart from "./components/specificDiseasePieChart";
import axios from "axios";

const Content = ({ selectedFilterTag }) => {
  // Master data
  const [masterAges, setMasterAges] = useState([]);
  useEffect(() => {
    axios.get("/api/master/age").then(res => {
      const ages = res.data?.data;
      const ageObj = ages.reduce((obj, age) => ({...obj, [age.id]: age.name }), {});
      setMasterAges(ageObj);
    });
  }, []);
  
  // Diplay disease type
  const [epidemSelected, setEpidemSelected] = useState([]);
  const [selectedAges, setSelectedAges] = useState([]);
  const [selectedPatientStatuses, setSelectedPatientStatuses] = useState([]);

  // Time period
  const [selectedYearValue, setSelectedYearValue] = useState("");
  const [selectedWeekValue, setSelectedWeekValue] = useState("");
  const [selectedEndYearValue, setSelectedEndYearValue] = useState("");
  const [selectedEndWeekValue, setSelectedEndWeekValue] = useState("");
  const [selectedMonthValue, setSelectedMonthValue] = useState("");
  const [selectedEndMonthValue, setSelectedEndMonthValue] = useState("");
  const [selectedTime, setSelectedTime] = useState("1");

  // Display
  // TODO: Name meaning is misleading
  const [selectedDisplay, setSelectedDisplay] = useState("3");

  const [searchTrigger, setSearchTrigger] = useState(0);
  const [selectedCompareOption, setSelectedCompareOption] = useState("1");

  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <SelectDisplayDiseaseType
          masterAges={masterAges}
          epidemSelected={epidemSelected}
          setEpidemSelected={setEpidemSelected}
          selectedAges={selectedAges}
          setSelectedAges={setSelectedAges}
          selectedPatientStatuses={selectedPatientStatuses}
          setSelectedPatientStatuses={setSelectedPatientStatuses}
        />
        <SelectTimePeriod
          selectedYearValue={selectedYearValue}
          setSelectedYearValue={setSelectedYearValue}
          selectedWeekValue={selectedWeekValue}
          setSelectedWeekValue={setSelectedWeekValue}
          selectedEndYearValue={selectedEndYearValue}
          setSelectedEndYearValue={setSelectedEndYearValue}
          selectedEndWeekValue={selectedEndWeekValue}
          setSelectedEndWeekValue={setSelectedEndWeekValue}
          selectedMonthValue={selectedMonthValue}
          setSelectedMonthValue={setSelectedMonthValue}
          selectedEndMonthValue={selectedEndMonthValue}
          setSelectedEndMonthValue={setSelectedEndMonthValue}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          setSearchTrigger={setSearchTrigger}
          selectedCompareOption={selectedCompareOption}
          setSelectedCompareOption={setSelectedCompareOption}
        />
        <SelectDisplayInfectedType selectedDisplay={selectedDisplay} setSelectedDisplay={setSelectedDisplay} />
        <DiseaseNotification />
        {/* <PatientRatio/> */}
      </div>
      <div style={{ flex: 1 }}>
        <SpecificDiseasePieChart
          selectedYearValue={selectedYearValue}
          selectedWeekValue={selectedWeekValue}
          selectedEndYearValue={selectedEndYearValue}
          selectedEndWeekValue={selectedEndWeekValue}
          selectedMonthValue={selectedMonthValue}
          selectedEndMonthValue={selectedEndMonthValue}
          selectedTime={selectedTime}
          selectedOutputType="1"
          selectedFilterTag={selectedFilterTag}
          searchTrigger={searchTrigger}
          epidemSelected={epidemSelected}
          selectedDisplay={selectedDisplay}
          selectedAges={selectedAges}
          selectedPatientStatuses={selectedPatientStatuses}
          selectedCompareOption={selectedCompareOption}
        />
      </div>
    </React.Fragment>
  );
};

export default Content;
