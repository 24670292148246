import { useState } from "react";
import {
  DiseaseNotification,
  GroupSelector,
  SelectDisplayDiseaseType,
  SelectDisplayInfectedType,
  SelectTimePeriod
} from "@/modules/common/components";
import { CombinedEpidemicCharts, SelectAreaType } from "./components";
import { useTranslation } from "react-i18next";

const Overall = () => {
  const { t } = useTranslation();
  const [selectedYearValue, setSelectedYearValue] = useState("");
  const [selectedWeekValue, setSelectedWeekValue] = useState("");
  const [selectedEndYearValue, setSelectedEndYearValue] = useState("");
  const [selectedEndWeekValue, setSelectedEndWeekValue] = useState("");
  const [selectedMonthValue, setSelectedMonthValue] = useState("");
  const [selectedEndMonthValue, setSelectedEndMonthValue] = useState("");
  const [selectedTime, setSelectedTime] = useState("1");
  const [selectedOutputType, setSelectedOutputType] = useState("1");
  const [selectedFilterTag, setSelectedFilterTag] = useState("all");
  const [searchTrigger, setSearchTrigger] = useState(0);
  const [selectedDisplay, setSelectedDisplay] = useState("3");
  const [selectedCompareOption, setSelectedCompareOption] = useState("1");
  const [downloadData, setDownloadData] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState([{
    "value": "infected_header",
    "label": t('CASE'),
  }, {
      "value": "deaths_header",
      "label": t('DEAD'),
  }]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      {/* This container has a maximum width of 1200px and is centered */}
      <GroupSelector setSelectedFilterTag={setSelectedFilterTag} style={{ flex: 1 }} />
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <div style={{display: "flex", flexDirection: "column", gap: "16px"}}>
          <SelectDisplayDiseaseType
            selectedOutputType={selectedOutputType}
            setSelectedOutputType={setSelectedOutputType}
          />
          {['area', 'health-area'].includes(selectedFilterTag) && (
            <SelectAreaType 
              selectedFilterTag={selectedFilterTag}
              setSelectedFilterTag={setSelectedFilterTag}
            />
          )}
        </div>
        <SelectTimePeriod
          selectedYearValue={selectedYearValue}
          setSelectedYearValue={setSelectedYearValue}
          selectedWeekValue={selectedWeekValue}
          setSelectedWeekValue={setSelectedWeekValue}
          selectedEndYearValue={selectedEndYearValue}
          setSelectedEndYearValue={setSelectedEndYearValue}
          selectedEndWeekValue={selectedEndWeekValue}
          setSelectedEndWeekValue={setSelectedEndWeekValue}
          selectedMonthValue={selectedMonthValue}
          setSelectedMonthValue={setSelectedMonthValue}
          selectedEndMonthValue={selectedEndMonthValue}
          setSelectedEndMonthValue={setSelectedEndMonthValue}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          selectedFilterTag={selectedFilterTag}
          setSearchTrigger={setSearchTrigger}
          selectedCompareOption={selectedCompareOption}
          setSelectedCompareOption={setSelectedCompareOption}
          downloadData={downloadData}
          page={'overall'}
        />
        <SelectDisplayInfectedType selectedDisplay={selectedDisplay} setSelectedDisplay={setSelectedDisplay} />
        {/* <PatientRatio/> */}
        <DiseaseNotification />
      </div>
      <div style={{ flex: 1 }}>
        <CombinedEpidemicCharts
          selectedYearValue={selectedYearValue}
          selectedWeekValue={selectedWeekValue}
          selectedEndYearValue={selectedEndYearValue}
          selectedEndWeekValue={selectedEndWeekValue}
          selectedMonthValue={selectedMonthValue}
          selectedEndMonthValue={selectedEndMonthValue}
          selectedTime={selectedTime}
          selectedOutputType={selectedOutputType}
          selectedFilterTag={selectedFilterTag}
          searchTrigger={searchTrigger}
          selectedDisplay={selectedDisplay}
          selectedCompareOption={selectedCompareOption}
          setDownloadData={setDownloadData}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        />
      </div>
    </div>
  );
};

export default Overall;