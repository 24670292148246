import { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Tabs } from "@material-ui/core";
import StyledTab from "../common/components/styledTab";
import i18n from "../../utils/i18n";
import { useTranslation } from "react-i18next";

function GroupSelector({
  omitOverall,
  setSelectedFilterTag
}) {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);


  const handleTabChange = (event, newValue) => {
    let filterTags = ['all', 'gender', 'age', 'nationality',  'area']
    if(omitOverall) {
      filterTags = filterTags.slice(1);
    }
    setSelectedTab(newValue);
    setSelectedFilterTag(filterTags[newValue]);
  };

  return (
    <Container>
      <Tabs style={{marginBottom: '20px'}} value={selectedTab} onChange={handleTabChange} centered>
        {!omitOverall && <StyledTab label={t('OVERVIEW')} component={Link} to="#overview" />}
        <StyledTab label={t('GENDER')} component={Link} to="#gender"/>
        <StyledTab label={t('AGE')} component={Link} to="#age"/>
        <StyledTab label={t('NATIONALITY')} component={Link} to="#nationality"/>
        <StyledTab label={t('AREA')} component={Link} to="#area"/>
      </Tabs>
    </Container>
  );
}

export default GroupSelector;