import { Divider, FormControlLabel, Paper, Radio, RadioGroup, Typography } from "@material-ui/core";
import React from "react";
import i18n from "@/modules/common/utils/i18n";
import { useTranslation } from "react-i18next";

const SelectAreaType = ({ selectedFilterTag, setSelectedFilterTag }) => {
  const { t } = useTranslation();
  const handleRadioChange = (event) => {
    setSelectedFilterTag(event.target.value);
  }

  return (
    <Paper style={{ flex: 1, margin: "0 10px", padding: "20px", textAlign: "center" }}>
      <Typography variant="h5">{t('AREA_DISPLAY_FORMAT')}</Typography>
      <Divider style={{ margin: "10px 0" }} />
      <RadioGroup name="display" value={selectedFilterTag} onChange={handleRadioChange}>
        <FormControlLabel value="area" control={<Radio />} label={t('REGION')} />
        <FormControlLabel value="health-area" control={<Radio />} label={t('HEALTH_AREA')} />
      </RadioGroup>
    </Paper>
  );
};

export default SelectAreaType;
