import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Divider, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, Select, Typography } from "@material-ui/core";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";
import { thToKey } from "@/modules/common/utils/i18n";
import thaiProvinces from '@/modules/common/utils/thai_province.json';

const customReactSelectStyles = {
  menu: (base) => ({
    ...base,
    zIndex: 11, // Set your desired zIndex value
  }),
};

// NOTE: Originally duplicated from @/modules/common/components/selectDisplayDiseaseType
// with extra additions; area type, areas, patient statuses
const SelectDisplayDiseaseType = ({
  masterAreas,
  selectedAreaType = "area",
  setSelectedAreaType = () => {},
  selectedAreas = [],
  setSelectedAreas = () => {},
  selectedPatientStatuses = [],
  setSelectedPatientStatuses = () => {},
  epidemSelected,
  setEpidemSelected,
}) => {
  const { t, i18n } = useTranslation();
  const [diseaseData, setDiseaseData] = useState([]);

  useEffect(() => {
    axios
      .get("/api/epidemic")
      .then((response) => {
        if (response.data && Array.isArray(response.data.data)) {
          setDiseaseData(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching disease data:", error);
      });
  }, []);

  const handleChangeDisease = (option) => {
    setEpidemSelected(option.value);
  };

  const epidemOptions = useMemo(() => {
    if (!diseaseData) return [];
    return diseaseData.map((disease) => ({
      label: disease[`epidem_report_name_${i18n.language}`],
      value: disease.epidem_report_id,
    }));
  }, [diseaseData, i18n.language]);

  const epidemValueToOption = useMemo(() => {
    return epidemOptions.reduce(
      (obj, option) => ({
        ...obj,
        [option.value]: option,
      }),
      {}
    );
  }, [epidemOptions]);

  const epidemValues = useMemo(() => {
    if (!epidemSelected) return null;
    return epidemValueToOption[epidemSelected];
  }, [epidemValueToOption, epidemSelected]);

  const handleChangePatientStatuses = (values) => {
    setSelectedPatientStatuses(values);
  };

  const handleChangeAreaType = (event) => {
    setSelectedAreaType(event.target.value);
  };

  const handleChangeAreas = (values) => {
    setSelectedAreas(values);
  };

  const areaOptions = useMemo(() => {
    return Object.entries(masterAreas).map(([key, value]) => {
      let translated = value;
      if(selectedAreaType === 'area') {
        translated = t(thToKey?.[value]);
      } else if(selectedAreaType === 'health-area') {
        translated = `${t('HEALTH_AREA_NO')} ${value?.split(' ')[1]?.trim()}`
      } else if(selectedAreaType === 'chw') {
        const province = thaiProvinces.find(p => p.province === value);
        translated = province?.[`province_${i18n.language}`];
      } else if(selectedAreaType === 'amp') {
        let [pre, inParen] = value?.split('(');
        inParen = inParen?.slice(0,inParen.length-1);
        const province = thaiProvinces.find(p => p.province === pre);
        translated = `${province?.[`province_${i18n.language}`]}(${t(thToKey?.[inParen])})`;
      }
      return {label: translated, value: key, origLabel: value}
    });
  }, [masterAreas, selectedAreaType, t, i18n.language]);

  useEffect(() => {
    setSelectedAreas((genders) =>
      genders.map((gender) => areaOptions.find((option) => option.value === gender.value))
    );
  }, [i18n.language, areaOptions, setSelectedAreas]);

  const statusOptions = useMemo(() => (
    [
      {
        value: "treated",
        label: t("TREATED"),
      },
      {
        value: "death",
        label: t("DEAD"),
      },
      {
        value: "unknown",
        label: t("UNKNOWN"),
      },
    ]
  ), [t]);

  useEffect(() => {
    setSelectedPatientStatuses((statuses) =>
      statuses.map((status) => statusOptions.find((option) => option.value === status.value))
    );
  }, [i18n.language, setSelectedPatientStatuses, statusOptions]);

  return (
    <Paper style={{ flex: 1, margin: "0 10px", padding: "20px" }}>
      <Typography variant="h5" align="center">
        {t("DATA_DISPLAY_FORMAT")}
      </Typography>
      <Divider style={{ margin: "10px 0" }} />
      <FormLabel style={{ display: "block", marginTop: "8px", marginBottom: "4px" }}>{t("SELECT_DISEASE")}</FormLabel>
      <ReactSelect
        placeholder={t("SELECT_DISEASE")}
        value={epidemValues}
        onChange={handleChangeDisease}
        options={epidemOptions}
        styles={customReactSelectStyles}
      />
      <FormLabel style={{ display: "block", marginTop: "8px", marginBottom: "4px" }}>{t("SELECT_STATUS")}</FormLabel>
      <ReactSelect
        placeholder={t("SELECT_STATUS")}
        value={selectedPatientStatuses}
        onChange={handleChangePatientStatuses}
        isMulti
        options={statusOptions}
        closeMenuOnSelect={false}
        styles={customReactSelectStyles}
      />
      <FormLabel style={{ display: "block", marginBottom: "4px", marginTop: "8px" }}>
        {t("SELECT_AREA_TYPE")}
      </FormLabel>
      <RadioGroup row name="display" value={selectedAreaType} onChange={handleChangeAreaType}>
        <FormControlLabel value="area" control={<Radio />} label={t("REGION")} />
        <FormControlLabel value="health-area" control={<Radio />} label={t("HEALTH_AREA")} />
        <FormControlLabel value="chw" control={<Radio />} label={t('PROVINCE')} />
        <FormControlLabel value="amp" control={<Radio />} label={t('DISTRICT')} />
      </RadioGroup>
      <FormLabel style={{ display: "block", marginTop: "8px", marginBottom: "4px" }}>{t('SELECT_AREAS')}</FormLabel>
      <ReactSelect
        placeholder={t('SELECT_AREAS')}
        value={selectedAreas}
        onChange={handleChangeAreas}
        options={areaOptions}
        isMulti
        closeMenuOnSelect={false}
        styles={customReactSelectStyles}
      />
    </Paper>
  );
};

export default SelectDisplayDiseaseType;
