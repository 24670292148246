import React, { useState, useEffect, useMemo, createContext, useCallback, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Link as RouterLink, useLocation, Link, useSearchParams, useNavigate } from 'react-router-dom';
import { AppBar, Tabs, Tab, Container, Toolbar  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import modules from './modules';
import Footer from './modules/footer';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    marginBottom: theme.spacing(3),
    background: '#0f5807',
  },
  tab: {
      '&:hover': {
          backgroundColor: '#fcca33',
          color: '#0e5706',
          transition: 'background-color 0.3s'
      }
  },
  subnav: {
    backgroundColor: '#E4E4E4',
    marginBottom: theme.spacing(3),
  },
  toolbar: {
    justifyContent: 'center',
  },
  title: {
    flexGrow: 1,
    textAlign: 'left',
    textDecoration: 'none',
    color: 'inherit',
  }
}));

export const AuthContext = createContext();

function MainNav() {

  const classes = useStyles();
  const { isUnAuthPage, authUser, setAuthUser, checkAndRedirect } = useContext(AuthContext);
  const [value, setValue] = React.useState(0);
  const [headerUrl, setHeaderUrl] = useState("/header.jpg")
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setValue(modules.findIndex(module => module.routes.to === location.pathname) ?? 0);
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    checkAndRedirect().then(() => {
      setValue(newValue);
    }).catch(() => {});
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };

  useEffect(() => {
    setHeaderUrl(i18n.language === "th" ? "/header.jpg" : "header_en.jpg")
  }, [i18n.language]);

  return (
    <div className="body" style={{ position: 'relative' }}>
      {/* Image */}
      <img src={headerUrl} alt="Header" style={{ width: '100%', height: 'auto', display: 'block' }} />

      <div style={{ 
        position: 'absolute', 
        top: '10px',
        right: '10px',
        fontSize: '16px',
        color: 'white',
        fontWeight: 'bold'
      }}>
        <button 
              style={{ 
                  background: 'none', 
                  border: 'none', 
                  color: 'white',
                  cursor: 'pointer',
                  marginRight: '5px' // space between TH and |
              }}
              onClick={() => {
                changeLanguage('th');
            }}
          >
              TH
          </button>
          |
          <button 
              style={{ 
                  background: 'none', 
                  border: 'none', 
                  color: 'white',
                  cursor: 'pointer',
                  marginLeft: '5px' // space between | and EN
              }}
              onClick={() => {
                changeLanguage('en');
            }}
          >
              EN
          </button>
          {authUser && (
            <span>
              <span style={{marginLeft: '16px', fontWeight: 'normal'}}>{authUser.name}</span>
              <button 
                style={{ 
                    background: 'none', 
                    border: 'none', 
                    color: 'white',
                    cursor: 'pointer',
                    marginLeft: '5px' // space between | and EN
                }}
                onClick={() => {
                    axios.get("/api/auth/google/logout").then(() => {
                      setAuthUser(null);
                      navigate("/unauthorized");
                    });
                }}
              >
                  {t('LOGOUT')}
              </button>
            </span>
          )}
      </div>
      {!isUnAuthPage && <AppBar position="static" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <Tabs value={value} onChange={handleChange}>
            {modules.map(module => <Tab label={t(module.routes.name)} to={module.routes.to} component={RouterLink} className={classes.tab} />)}
          </Tabs>
        </Toolbar>
      </AppBar>}
    </div>
    
  );
}

function App() {
  const classes = useStyles();

  const location = useLocation();
  const [authUser, setAuthUser] = useState(false);
  const [searchParams] = useSearchParams();

  const isUnAuthPage = useMemo(() => {
    return location.pathname === "/unauthorized";
  }, [location.pathname]);

  const checkAndRedirect = useCallback(async () => {
    if(isUnAuthPage) return;
    const postUrl = '/api/auth'; 

    return new Promise((resolve, reject) => {
      axios
        .get(postUrl)
        .then((response) => {
          console.log("Success:", response.data);
          setAuthUser(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            const redirectUrl = "/api/auth/google/login";
            console.log(`Redirecting to: ${redirectUrl}`);
            // Perform your redirection logic here if needed
            window.location.href = redirectUrl;
          } else {
            console.error("Error:", error.message);
          }
          reject(error);
        });
    });
  }, [isUnAuthPage]);

  useEffect(() => {
    checkAndRedirect().catch(() => {});
  }, [checkAndRedirect]);

  if(!isUnAuthPage && !authUser) {
    return null;
  }
  
  return (
    <AuthContext.Provider value={{isUnAuthPage, authUser, setAuthUser, checkAndRedirect}}>
      <div className={classes.root}>
        <MainNav />
        <Container maxWidth={false}>
          <Routes>
            <Route
              path="/unauthorized"
              element={
                <div style={{marginTop: '8px', fontSize: '18px'}}>
                  {searchParams.get('bad_user') && "{ไม่พบผู้ใช้นี้ในระบบ} "}โปรด
                  <Link to="/">เข้าสู่ระบบ</Link>
                  ก่อนใช้งาน
                </div>
              }
            />
            {
              modules.map(module => {
                const routes = module.routes;
                const { Page, to } = routes;
                return <Route path={to} element={<Page />} />
              })
            }
          </Routes>
        </Container>
      </div>
      <Footer />
    </AuthContext.Provider>
  );
}

const RouterWrappedApp = () => {
  return (
    <Router>
      <App />
    </Router>
  )
}

export default RouterWrappedApp;
