import { Divider, FormControlLabel, Paper, Radio, RadioGroup, Typography } from "@material-ui/core";
import { Bar } from "./components";
import i18n from "../../utils/i18n";
import { useTranslation } from "react-i18next";

const SelectDisplayInfectedType = (props) => {
  const { t } = useTranslation();
  const handleRadioChange = (event) => {
    props.setSelectedDisplay(event.target.value);
  }

  return (
    <Paper style={{ flex: 1, margin: '0 10px', padding: '20px', textAlign: 'center' }}>
      <Typography variant="h5">{t('EPIDEMIC_STATUS_DISPLAY_FORMAT')}</Typography>
      <Divider style={{ margin: '10px 0' }} />
      <RadioGroup name="dp1" defaultValue={props.selectedDisplay} onChange={handleRadioChange} >
        <FormControlLabel value="1" control={<Radio />} label={t('PATIENTS_PER_100K_OVER_20')} />
        <Bar    
          // title="อัตราผู้ป่วยต่อประชากร 100,000 คน (> 20)"
          sections={['0', '0-5', '5-10', '10-20', '> 20']}
          colors={['lightgray', '#30bf30', '#ffe414', 'orange', 'red']}
        />
        <FormControlLabel value="2" control={<Radio />} label={t('PATIENTS_PER_100K_OVER_100')} />
        <Bar
          // title="อัตราผู้ป่วยต่อประชากร 100,000 คน (> 100)"
          sections={['0', '0-50', '50-100', '> 100']}
          colors={['lightgray', '#30bf30', '#ffe414', 'red']}
        />
        <FormControlLabel value="3" control={<Radio />} label={t('PATIENTS_COMPARED_TO_5_YEAR_MEDIAN')} />
        <Bar
          // title="จำนวนผู้ป่วยเทียบ (m = มัธยฐาน 5 ปี)"
          sections={['0', '< m', '= m', '> m']}
          colors={['lightgray', '#30bf30', '#ffe414', 'red']}
        />
      </RadioGroup>
    </Paper>
  );
}

export default SelectDisplayInfectedType;