const Bar = ({ title, sections, colors }) => {
  return (
    <div style={{ marginBottom: '20px' }}>
      <div style={{ marginBottom: '5px', fontWeight: 'bold' }}>{title}</div>
      <div style={{ display: 'flex', width: '100%' }}>
        {sections.map((section, index) => (
          <div
            key={index}
            style={{
              backgroundColor: colors[index],
              color: 'black',
              padding: '5px',
              textAlign: 'center',
              fontSize: '14px',
              flex: `1 0 0%`, // Each section takes equal space
            }}
          >
            {section}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Bar;