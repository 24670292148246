import React, { useState } from "react";
import { formatNumber } from "@/modules/common/utils";
import { useSort } from '@/modules/common/hooks';
import { useTranslation } from 'react-i18next';

const EpidemicReportTableMD5GroupCompare = ({ data, getGroupDetail, selectedDisplay, selectedStatus }) => {
  const { t, i18n } = useTranslation();
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [groupDetails, setGroupDetails] = useState({});

  const hasMonthData = data.some((report) => report.details.some((detail) => "month_id" in detail));
  const hasWeekData = data.some((report) => report.details.some((detail) => "week" in detail));

  const toggleRowExpansion = async (id) => {
    if (expandedRows.has(id)) {
      setExpandedRows((prev) => new Set([...prev].filter((eid) => eid !== id)));
    } else {
      setExpandedRows((prev) => new Set(prev.add(id)));
      if (!groupDetails[id]) {
        const details = await getGroupDetail(id);
        setGroupDetails((prev) => ({ ...prev, [id]: details }));
      }
    }
  };

  const isRowExpanded = (id) => expandedRows.has(id);

  // Function to create a unique identifier for each time period
  const getTimePeriod = (detail) => {
    const suffix = detail.isPrediction && !(detail.missing_flag) ? '+ (คาดการณ์)' : '';
    if (hasWeekData && "week" in detail) {
      const paddedWeek = String(detail.week).padStart(2, "0");
      return `${detail.year}_${paddedWeek}${suffix}`;
    } else if (hasMonthData && "month_id" in detail) {
      return `${detail.year}_${detail.month_id}${suffix}`;
    } else {
      return `${detail.year.toString()}${suffix}`;
    }
  };

  // Parse the data to get unique time periods
  const uniqueTimePeriods = Array.from(new Set(data.flatMap((report) => report.details.map(getTimePeriod))));

  const headers = uniqueTimePeriods.map((timePeriod) => ({
    timePeriod,
    subHeaders: [
      t("EPIDEMIC_ALERT_STATUS"),//"สถานะการแจ้งเตือนโรคระบาด",
      ...(selectedDisplay === "3" ? [ t("PATIENTS_COMPARED_TO_5_YEAR_MEDIAN")] : []),
      {
        header: t("NUMBER_OF_PATIENT"),//"จำนวนผู้ป่วย",
        subHeaders: [t("TOTAL_INFECTED"), t("TREATED"), t("DEAD"), t("UNKNOWN")],
      },
    ],
  }));

  const setColorClass = (colormd5) => {
    switch (colormd5) {
      case 1:
        return "lightgrey";
      case 2:
        return "green";
      case 3:
        return "yellow";
      case 4:
        return "orange";
      case 5:
        return "red";
      default:
        return "";
    }
  };

  function setColorClassInfected(color20, color100, selectedDisplay) {
    if (selectedDisplay == "1") {
      switch (color20) {
        case 1:
          return "lightgrey";
        case 2:
          return "green";
        case 3:
          return "yellow";
        case 4:
          return "orange";
        case 5:
          return "red";
        default:
          return ""; // no additional class
      }
    }

    if (selectedDisplay == "2") {
      switch (color100) {
        case 1:
          return "lightgrey";
        case 2:
          return "green";
        case 3:
          return "yellow";
        case 4:
          return "orange";
        case 5:
          return "red";
        default:
          return ""; // no additional class
      }
    }

    return "";
  }

  const getMonthKey = (timePeriod) => {
    const MONTH_KEY = ["", "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];
    let monthId = parseInt(timePeriod.split("_")[1], 10)
    return MONTH_KEY[monthId];
  }
  
  const subHeaderToKey = {
    //'สถานะการแจ้งเตือนโรคระบาด': 'flag', 'จำนวนผู้ป่วยเทียบมัธยฐาน 5 ปี': 'median', 'ติดเชื้อทั้งหมด': 'infected', 'เสียชีวิต': 'death', 'รักษาหาย': 'treated', 'ไม่ทราบ': 'unknown'
    [t("EPIDEMIC_ALERT_STATUS")]: 'flag', [t("PATIENTS_COMPARED_TO_5_YEAR_MEDIAN")]: 'median', [t("TOTAL_INFECTED")]: 'infected', [t("DEAD")]: 'death', [t("TREATED")]: 'treated', [t("UNKNOWN")]: 'unknown'
  };



  const shouldRenderHeader = (header_id) => {
    return selectedStatus.some(status => status.value === header_id);
};

const renderSubHeaders = (subHeader, timePeriod) => {
  if (selectedDisplay === "3" || subHeader !== t("PATIENTS_COMPARED_TO_5_YEAR_MEDIAN")) {
      if((subHeader === t("TOTAL_INFECTED") && !shouldRenderHeader('infected_header')) || (subHeader === t("DEAD") && !shouldRenderHeader('deaths_header')) || (subHeader === t("TREATED") && !shouldRenderHeader('treated_header')) || (subHeader === t("UNKNOWN") && !shouldRenderHeader('unknown_header'))) {
          return null;
      }
      return <th onClick={() => handleClickSubHeaderCol(timePeriod, subHeaderToKey[subHeader])} data-column={subHeader} key={`${timePeriod}-${subHeader}`}>{subHeader}{getSortingColArrow(timePeriod, subHeaderToKey[subHeader])}</th>;
  }
  return null;
};

  const renderDataCells = (detail, key) => {
    if (key === "infected" && detail != undefined) {
      return (
        <td className={setColorClassInfected(detail.color20, detail.color100, selectedDisplay)}>
          {formatNumber(detail[key]) || 0}
        </td>
      );
    } else if (selectedDisplay === "3" && detail != undefined && key === "median") {
      return <td className={setColorClass(detail.colormd5)}>{formatNumber(detail[key]) || 0}</td>;
    } else if ((key === "death" || key === "unknown") && detail != undefined) {
      return <td>{formatNumber(detail[key]) || 0}</td>;
    } else if (selectedDisplay !== "3") {
      return <td>0</td>;
    }
  };

  function flattenKeys(obj) {
    let resultString = '';

    function flatten(obj, prefix = '') {
        for (let key in obj) {
            if (!obj.hasOwnProperty(key)) continue;

            if (typeof obj[key] === 'object' && obj[key] !== null) {
                flatten(obj[key], prefix + key + '_');
            } else {
                resultString += `${prefix}${key}_${obj[key]} `;
            }
        }
    }

    flatten(obj);
    return resultString.trim(); // Remove trailing space 
}

const removeNullKeys = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
      // If obj is not an object or is null, return it as is
      return obj;
    }
  
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value && typeof value === 'object') {
        acc[key] = removeNullKeys(value); // Recursively process nested objects
      } else if (value !== null) {
        acc[key] = value; // Keep non-null values
      }
      return acc;
    }, {});
  };

  const getReportGroupKey = () => {
    return i18n.language === "th"? "epidem_group_name_th": "epidem_group_name_en";
  }
  const getReportKey = () => {
    return i18n.language === "th"? "epidem_report_name_th": "epidem_report_name_en";
  }

  const { sortedData, sortBy, sortDir, setSortBy, setSortDir } = useSort({ data });
  const getSortDetail = (headerCol, subHeaderCol) => {
    let detail;
    if(headerCol === null) {
        detail = subHeaderCol;
    } else {
        const splitTime = headerCol.split('_');
        if (splitTime.length >= 2) {
            detail = {
                headerCol: {
                    year: splitTime[0].split('+')[0],
                    month_id: hasMonthData ? splitTime[1].split('+')[0] : null,
                    week: hasWeekData ? splitTime[1].split('+')[0] : null
                },
                subHeaderCol: subHeaderCol
            }
        } else {
            detail = {
                headerCol: {
                    year: splitTime[0].split('+')[0]
                },
                subHeaderCol: subHeaderCol
            }
        }
    }

    return detail;
  }

  const handleClickSubHeaderCol = (headerCol, subHeaderCol) => {
      const detail = removeNullKeys(getSortDetail(headerCol, subHeaderCol));

      if(!(flattenKeys(detail.headerCol) === flattenKeys(sortBy?.headerCol) && detail.subHeaderCol === sortBy?.subHeaderCol)) {
          setSortDir("asc");
      } else {
          setSortDir(dir => {
              
          if(dir === "asc") return "desc";
          else if(dir === "desc") return null;
          else return "asc";
          })
      }
      setSortBy(detail);
  }

  const getSortingColArrow = (headerCol, subHeaderCol) => {
      const detail = removeNullKeys(getSortDetail(headerCol, subHeaderCol));

      if(typeof(sortBy) === 'string' && typeof(detail) === 'string' && sortBy !== detail) return null;
      if(!(flattenKeys(sortBy?.headerCol) === flattenKeys(detail.headerCol) && sortBy?.subHeaderCol === detail.subHeaderCol)) return null;
      if(sortDir === "asc") return "↑";
      if(sortDir === "desc") return "↓";
      return null;
  }


  return (
    <div>
    
    <table id="epidemicReportTable">
      <thead>
        <tr>
          <th>{t("EPIDEMIC")}</th>
          {headers.map((header) => (
            <th
              key={header.timePeriod}
              colSpan={header.subHeaders.reduce((total, sh) => total + (sh.subHeaders ? sh.subHeaders.length - 4 + selectedStatus.length : 1), 0)}
            >
              {hasWeekData ? header.timePeriod.replaceAll("_", ` ${t("WEEK")} `).replaceAll('+', '') : `${header.timePeriod.split("_")[0]} ${t(getMonthKey(header.timePeriod.replaceAll('+', '')))}`}
            </th>
          ))}
        </tr>
        <tr>
          <th></th>
          {headers.flatMap((header) =>
            header.subHeaders.map((subHeader) =>
              typeof subHeader === "object" ? subHeader.subHeaders.map((sh) => renderSubHeaders(sh, header.timePeriod))
              : renderSubHeaders(subHeader, header.timePeriod)
            )
          )}
        </tr>
      </thead>
      <tbody>
        {sortedData.map((report) => (
          <React.Fragment key={report.epidem_group_id}>
            <tr>
              <td>
                <button onClick={() => toggleRowExpansion(report.epidem_group_id)}>
                  {isRowExpanded(report.epidem_group_id) ? "-" : "+"}
                </button>
                {report[getReportGroupKey()]}
              </td>
              {uniqueTimePeriods.map((timePeriod) => {
                const detail = report.details.find((d) => getTimePeriod(d) === timePeriod) || {};
                return (
                  <React.Fragment key={`${report.epidem_group_id}-${timePeriod}`}>
                    <td>{detail.flag > 0 ? "!".repeat(detail.flag) : "-"}</td>
                    {selectedDisplay === "3" && (
                      <td className={setColorClass(detail.colormd5)}>{formatNumber(detail.median) || 0}</td>
                    )}
                    {shouldRenderHeader('infected_header') && renderDataCells(detail, "infected")}
                    {shouldRenderHeader('treated_header') &&<td>{formatNumber(detail.treated) || 0}</td>}
                    {shouldRenderHeader('deaths_header') &&renderDataCells(detail, "death")}
                    {shouldRenderHeader('unknown_header') &&<td>{formatNumber(detail.unknown) || 0}</td>}
                  </React.Fragment>
                );
              })}
            </tr>
            {isRowExpanded(report.epidem_group_id) &&
              groupDetails[report.epidem_group_id] &&
              groupDetails[report.epidem_group_id].data.data.map((detail) => (
                <tr key={detail.epidem_report_id}>
                  <td className="expandbg"> - {detail[getReportKey()]}</td>
                  {uniqueTimePeriods.map((timePeriod) => {
                    const detailx = detail.details.find((d) => getTimePeriod(d) === timePeriod) || {};
                    return (
                      <React.Fragment key={`${detail.epidem_report_id}-${timePeriod}`}>
                        <td>{detailx.flag > 0 ? "!".repeat(detailx.flag) : "-"}</td>
                        {selectedDisplay === "3" && (
                          <td className={setColorClass(detailx.colormd5)}>{formatNumber(detailx.median) || 0}</td>
                        )}
                        {shouldRenderHeader('infected_header') &&renderDataCells(detailx, "infected")}
                        {shouldRenderHeader('treated_header') &&<td>{formatNumber(detailx.treated) || 0}</td>}
                        {shouldRenderHeader('deaths_header') && renderDataCells(detailx, "death")}
                        {shouldRenderHeader('unknown_header') &&<td>{formatNumber(detailx.unknown) || 0}</td>}
                      </React.Fragment>
                    );
                  })}
                </tr>
              ))}
          </React.Fragment>
        ))}
      </tbody>
    </table>
    </div>
  );
};

export default EpidemicReportTableMD5GroupCompare;
