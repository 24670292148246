import React ,{ useState, useEffect, useContext, useCallback} from 'react';
import axios from 'axios';
import { Bar, Chart } from 'react-chartjs-2';
import { TableNotFound } from '@/modules/common/components';
import { AuthContext } from '@/App';
import { EpidemicReportTableMD5Compare, EpidemicReportTableMD5GroupCompare } from './components';
import ReactSelect from "react-select";
import { Divider, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, Select, Typography } from "@material-ui/core";
import { formatNumber } from "@/modules/common/utils";
import { useTranslation } from 'react-i18next';

function CompareChart({
    selectedYearValue, 
    selectedWeekValue,
    selectedMonthValue, 
    selectedEndYearValue, 
    selectedEndWeekValue,
    selectedEndMonthValue,
    selectedTime,
    selectedOutputType,
    epidemSelected,
    searchTrigger,
    selectedDisplay,
    setDownloadData,
    selectedCompareOption
}) {
    const { t, i18n } = useTranslation();
    const [jsonData, setJsonData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [chartData, setChartData] = useState({ labels: [], datasets: [], highestValue: 0, sumMap: {} });
    const [options, setOptions] = useState({});
    const legendHtml = `
    <div style="text-align: center;">
     
        <span style="display: inline-block; margin-right: 10px;">
            <span style="background-color: rgba(129, 165, 67); width: 20px; height: 20px; display: inline-block;"></span> ${t("TREATED")}
        </span>
        <span style="display: inline-block; margin-right: 10px;">
            <span style="background-color: rgba(99,99,99); width: 20px; height: 20px; display: inline-block;"></span> ${t("DEAD")}
        </span>
        <span style="display: inline-block;">
            <span style="background-color: rgba(117,53,120); width: 20px; height: 20px; display: inline-block;"></span> ${t("UNKNOWN")}
        </span>
    </div>
`;
    const [customLegend, setCustomLegend] = useState(legendHtml);
    const [allStatuses, setAllStatuses] = useState([{
        "value": "infected_header",
        "label": t("TOTAL_INFECTED")//"ติดเชื้อทั้งหมด"
      }, {
        "value": "treated_header",
        "label": t("TREATED") //"รักษาหาย"
      }, {
        "value": "deaths_header",
        "label": t("DEAD") //"เสียชีวิต"
      }, {
          "value": "unknown_header",
          "label": t("UNKNOWN") //"ไม่ทราบ"
      }]);
    const [selectedStatus, setSelectedStatus] = useState(allStatuses);
    const handleLanguageChanged = useCallback(() => {
        setAllStatuses([{
            "value": "infected_header",
            "label": t("TOTAL_INFECTED")//"ติดเชื้อทั้งหมด"
          }, {
            "value": "treated_header",
            "label": t("TREATED") //"รักษาหาย"
          }, {
            "value": "deaths_header",
            "label": t("DEAD") //"เสียชีวิต"
          }, {
              "value": "unknown_header",
              "label": t("UNKNOWN") //"ไม่ทราบ"
          }])
          const legendHtml = `
                <div style="text-align: center;">
                
                    <span style="display: inline-block; margin-right: 10px;">
                        <span style="background-color: rgba(129, 165, 67); width: 20px; height: 20px; display: inline-block;"></span> ${t("TREATED")}
                    </span>
                    <span style="display: inline-block; margin-right: 10px;">
                        <span style="background-color: rgba(99,99,99); width: 20px; height: 20px; display: inline-block;"></span> ${t("DEAD")}
                    </span>
                    <span style="display: inline-block;">
                        <span style="background-color: rgba(117,53,120); width: 20px; height: 20px; display: inline-block;"></span> ${t("UNKNOWN")}
                    </span>
                </div>
            `;
          setCustomLegend(legendHtml);
        
    }, []);

    
    useEffect(() => {
        i18n.on('languageChanged', handleLanguageChanged);
        return () => {
            i18n.off('languageChanged', handleLanguageChanged);
        };
    }, [handleLanguageChanged])

    useEffect(() => {
        let status = [];
        for (let i = 0; i<allStatuses.length; i++) {
            for(let j = 0; j<selectedStatus.length; j++) {
                if (allStatuses[i].value == selectedStatus[j].value) {
                    status.push(allStatuses[i])
                    break;
                }
            }
        }
        setSelectedStatus(status);
    }, [allStatuses])

    const customReactSelectStyles = {
        menu: (base) => ({
          ...base,
          zIndex: 11, // Set your desired zIndex value
        }),
      };


    const getTimeFrame = (time) => {
        if (time === "1") return "year";
        if (time === "2") return "month";
        if (time === "3") return "week";
        return "week";  // default value
    }    

    const getOutputType = (type) => {
        if (type === "1") return "indiv";
        return "group";
    }

    const handleChangeStatus = (values) => {
        setSelectedStatus(values);
    }

    const getGroupDetail = async (groupId) => {
        const requestBody = {
            "timeFrame": getTimeFrame(selectedTime),    // year, month, week
            "startYear": parseInt(selectedYearValue),
            "startWeek": parseInt(selectedWeekValue),
            "startMonth": parseInt(selectedMonthValue),
            "endYear": parseInt(selectedEndYearValue),
            "endWeek": parseInt(selectedEndWeekValue),
            "endMonth": parseInt(selectedEndMonthValue),
            "epidemSelected": epidemSelected,    
            "outputType": "indiv",   // indiv or group
            "filterEpidemByGroupId": groupId
        };
    
        try {
            const response = await axios.post('/api/compare-epidemic', requestBody);
            return {
                data: response.data
            };
        } catch (error) {
            console.error('Error fetching group details:', error);
            return {
                data: `Details for group ${groupId}`
            };
        }
    };

    const getMonthKeyFromId = (monthId) => {
        const MONTH_KEY = ["", "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];
        return MONTH_KEY[monthId];
    }


    const processData = (apiData) => {
        if (!apiData || !apiData.data) return { labels: [], datasets: [], highestValue: 0, sumMap: {} };
        apiData.data.forEach((d) => {
          d.details.forEach((detail) => {
            let sortKey = 0;
            if (selectedCompareOption == 2) {
              if ("month" in detail) {
                sortKey = detail.year + detail.month_id * 10000;
              } else if ("week" in detail) {
                sortKey = detail.year + detail.week * 10000;
              }
            } else {
              if ("month" in detail) {
                sortKey = detail.year*100 + detail.month_id;
              } else if ("week" in detail) {
                sortKey = detail.year*100 + detail.week;
              }
            }
            detail.sortKey = sortKey;
          });
        });
        apiData.data.forEach((d) => {
            d.details.sort((a, b) => a.sortKey - b.sortKey);
        });

        const labels = Array.from(new Set(apiData.data.flatMap(d => {
          return d.details.map(detail => {
            if('month' in detail) {
              return `${detail.year} ${t(getMonthKeyFromId(detail.month_id))}`;
            } else if('week' in detail) {
              return `${detail.year} ${t("WEEK")} ${detail.week}`;
            }
            return detail.year
          });
        })));

        const labelKeys = Array.from(new Set(apiData.data.flatMap(d => {
          return d.details.map(detail => {
            if('month' in detail) {
              return `${detail.year}_${detail.month_id}`;
            } else if('week' in detail) {
              return `${detail.year}_${detail.week}`;
            }
            return detail.year.toString();
          });
        })))
    
        const categories = ['treated', 'death', 'unknown'];
        const colorMapping = {
            'infected': '#FF5733',
            'treated': 'rgba(129, 165, 67)',
            'death': 'rgba(99,99,99)',
            'unknown': 'rgba(117,53,120)'
        };
    
        const datasets = [];
        let highestValue = 0;

        const categoryToLabel = {
          'treated': t("TREATED"),//'รักษาหาย',
          'death': t("DEAD"),//'เสียชีวิต',
          'unknown': t("UNKNOWN") //'ไม่ทราบ'
        }
        let sumMap = {};
        const filteredCategories = (categories) => {
            let result = [];
            for(let i = 0;i<categories.length;i++) {
                for(let j = 0; j<selectedStatus.length; j++) {
                    if (selectedStatus[j].value.split("_")[0] === categories[i]) {
                        result.push(categories[i])
                        break;
                    }
                }
            }
        
            return result;
        }

        const getReportKey = () => {
            return i18n.language === "th"? "epidem_report_name_th": "epidem_report_name_en";
        }

        const getReportGroupKey = () => {
            return i18n.language === "th"? "epidem_group_name_th": "epidem_group_name_en";
        }
        
       
        apiData.data.forEach(disease => {
            let report = disease.epidem_report_id;
            if (selectedOutputType !== "1") {
                report = disease.epidem_group_id;
            }
            const diseaseDatasets = filteredCategories(categories).map(category => {
                return {
                    label: `${disease[getReportKey()] == undefined ? disease[getReportGroupKey()] : disease[getReportKey()]} - ${categoryToLabel[category]}`, 
                    data: labelKeys.map(timePeriodKey => {
                        const detailForTimePeriod = disease.details.find(detail => {
                          const [year, monthOrWeek] = timePeriodKey.split('_');
                          if('month' in detail)
                            return detail.month_id == monthOrWeek && detail.year == year;
                          if('week' in detail)
                            return detail.week == monthOrWeek && detail.year == year;
                          return detail.year == year;
                        });
                        return detailForTimePeriod ? detailForTimePeriod[category] : 0;
                    }),
                    backgroundColor: colorMapping[category],
                    datasetId: report,
                    stack: disease[getReportKey()] == undefined ? disease[getReportGroupKey()] : disease[getReportKey()] // This ensures each disease's data stacks together for each year.
                };
            });

            sumMap[report] = [];

            for (let i = 0;i < diseaseDatasets.length; i++) {
                for (let j = 0; j < diseaseDatasets[i].data.length; j++) {
                    if (sumMap[report][j] === undefined) {
                        sumMap[report][j] = diseaseDatasets[i].data[j];
                    } else {
                        sumMap[report][j] += diseaseDatasets[i].data[j];
                    }
                    if (sumMap[report][j] > highestValue) {
                        highestValue = sumMap[report][j]
                    }
                }
            }

            datasets.push(...diseaseDatasets);
        });
       
    
        return { labels, datasets, highestValue, sumMap };
    };    
    

    useEffect(() => {
        setJsonData(null);
        setChartData({ labels: [], datasets: [], highestValue: 0, sumMap: {} })
        setDownloadData(null);
    }, [searchTrigger, selectedTime, epidemSelected, selectedOutputType, selectedCompareOption]);

    useEffect(() => {
        const data = processData(jsonData);
        setChartData(data);
        const options = {
            tooltips: {
                enabled: true,
                mode: 'point',
                callbacks: {
                    label: function(tooltipItem, data) {
                        let dataset = data.datasets[tooltipItem.datasetIndex];
                        let index = tooltipItem.index;
                        let label = data.datasets[tooltipItem.datasetIndex].label || '';
                        let value = tooltipItem.yLabel;
                        let customInfo = dataset.data[index] / data.sumMap[dataset.datasetId][index];
        
                        return `${label}: ${formatNumber(value)} | ${t('ALL')}: ${formatNumber(data.sumMap[dataset.datasetId][index])} | ${roundToTwo(customInfo * 100)} % ${t('NUMBER_OF_PATIENT')}`;
                    }
                }
            },
            scales: {
                xAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: t("TIME_PERIOD"),
                    }
                }],
                x: {
                    stacked: true,
                },
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: t("INFECTED"),
                    },
                    ticks: {
                        beginAtZero: true,
                        max: data.highestValue * 1.5, // Set the maximum y-axis value
                      },
                }],
                y: {
                    stacked: true,
                 //   max: data.highestValue * 2.5
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: false,
            plugins: []
        };
        setOptions(options);
    }, [jsonData, selectedStatus, selectedOutputType])

    const { checkAndRedirect } = useContext(AuthContext);

    useEffect(() => {
      let timeoutId;
      checkAndRedirect().then(() => {
        setIsLoading(true);
        timeoutId = setTimeout(() => {
          if (isLoading) {
            setIsLoading(false);
          }
        }, 5000); 
        const requestBody = {
            "timeFrame": getTimeFrame(selectedTime),    // year, month, week
            "startYear": parseInt(selectedYearValue),
            "startWeek": parseInt(selectedWeekValue),
            "startMonth": parseInt(selectedMonthValue),
            "endYear": parseInt(selectedEndYearValue),
            "endWeek": parseInt(selectedEndWeekValue),
            "endMonth": parseInt(selectedEndMonthValue),
            "epidemSelected": epidemSelected,   
            "isCompareOption": selectedCompareOption == 2,
            "outputType": getOutputType(selectedOutputType)   // indiv or group
        };

        // Fetch data from the API when the component mounts
        axios.post('/api/compare-epidemic', requestBody)
        .then(response => {
            setDownloadData(response.data);
            setJsonData(response.data);
        })
        .catch(error => {
            setIsLoading(false);
            console.error("Error fetching data:", error);
        }).finally(() => {
            setIsLoading(false); // Stop loading
        });

      }).catch(() => {});
      return () => timeoutId && clearTimeout(timeoutId);
    }, [searchTrigger]);

    let infectedDataArray = {};


    const textPlugin = {
      beforeDraw: function (chart) {
        const ctx = chart.ctx;
        ctx.clearRect(0, 0, chart.width, chart.height);
      },
      afterDraw: function (chart) {
       
        if (chart.config.type === "bar") {
          const ctx = chart.ctx;
          ctx.fillStyle = "#444"; // text color
          ctx.font = "regular 8px Sukhumvit";
          ctx.textBaseline = "middle";
          let isRenderLabel = {};
          chart.data.datasets.forEach(function (dataset, datasetIndex) {
            const meta = chart.getDatasetMeta(datasetIndex);
            const label = dataset.stack;
            meta.data.forEach(function (bar, index) {
              if (!(isRenderLabel[label] || false)) {
               
                ctx.save();
                ctx.translate(bar._model.x, chart.chartArea.top + 140);
                ctx.rotate(-Math.PI / 2); // Rotate text
                ctx.fillText(label, 0, 0);
                ctx.restore();
              }
            });
            isRenderLabel[label] = true;
          });
        }
    
      },
    };
   
    const roundToTwo = (num) => {
        return +(Math.round(num + "e+2")  + "e-2");
    }
   

    if (isLoading && searchTrigger > 0) {
        return (
        <div className="loading-overlay">
            <div className="spinner"></div>
        </div>
    ); // Replace with your spinner component or styling
    }   


    if (!jsonData || !jsonData.data || !Array.isArray(jsonData.data)) {
        return <TableNotFound />;
    }



    return (
        <div>
               <FormLabel style={{ display: "block", marginTop: "8px", marginBottom: "4px" }}>{t("STATUS_DISPLAY")}</FormLabel>
                <ReactSelect
                    placeholder={t("STATUS_DISPLAY")}
                    value={selectedStatus}
                    onChange={handleChangeStatus}
                    options={[
                    {
                        "value": "infected_header",
                        "label": t("TOTAL_INFECTED")//"ติดเชื้อทั้งหมด"
                    }, {
                        "value": "treated_header",
                        "label": t("TREATED") //"รักษาหาย"
                    }, {
                        "value": "deaths_header",
                        "label": t("DEAD") //"เสียชีวิต"
                    }, {
                        "value": "unknown_header",
                        "label": t("UNKNOWN") //"ไม่ทราบ"
                    }
                    ]}
                    isMulti
                    closeMenuOnSelect={false}
                    styles={customReactSelectStyles}
                />
            <div style={{ width: '100%', overflowX: 'scroll', maxHeight: '800px' }}>
                {getOutputType(selectedOutputType) === "indiv" && (
                    <EpidemicReportTableMD5Compare data={jsonData.data} selectedDisplay={selectedDisplay} selectedStatus={selectedStatus} />
                )}
                {getOutputType(selectedOutputType) === "group" && (
                    <EpidemicReportTableMD5GroupCompare data={jsonData.data} getGroupDetail={getGroupDetail} selectedDisplay={selectedDisplay} selectedStatus={selectedStatus} />
                )}
                
            </div>
            <div style={{paddingTop: '50px'}}></div>
            <div style={{ minWidth: '1000px', minHeight: '400px' }}>
                    <div id="chart-legend" dangerouslySetInnerHTML={{ __html: customLegend }}></div>
                    <Bar data={chartData} options={options} plugins={[textPlugin]} />
                </div>
        </div>
    );
        

}

export default CompareChart;
