import { useState } from "react";
import { DiseaseNotification } from "@/modules/common/components";
import { AlertComponent, SectionAlert } from "./components";

const Alert = () => {
  const [selectedFilterTag, setSelectedFilterTag] = useState("epidemic");
  const [selectedTime, setSelectedTime] = useState("3");
  const [searchTrigger, setSearchTrigger] = useState(0);
  const currentDateInfo = getCurrentDateInfo();
  const [selectedYearValue, setSelectedYearValue] = useState(currentDateInfo.year);
  const [selectedWeekValue, setSelectedWeekValue] = useState(currentDateInfo.week);
  const [selectedMonthValue, setSelectedMonthValue] = useState(currentDateInfo.month);

  function getCurrentDateInfo() {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const pastDaysOfYear = (now - startOfYear) / 86400000; // 86400000 milliseconds in a day

    return {
        year: now.getFullYear(),
        month: now.getMonth() + 1, // January is 0
        week: Math.ceil(pastDaysOfYear / 7)
    };
  }

  return (
    <div style={{ width: '100%', margin: '0 auto' }}> 
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: "20px" }}>
        <DiseaseNotification/>
        <SectionAlert selectedTime={selectedTime} setSelectedTime={setSelectedTime} setSearchTrigger={setSearchTrigger}
        setSelectedYearValue={setSelectedYearValue} setSelectedWeekValue={setSelectedWeekValue} setSelectedMonthValue={setSelectedMonthValue} 
        selectedYearValue={selectedYearValue} selectedWeekValue={selectedWeekValue} selectedMonthValue={selectedMonthValue} />
      </div>

      <div style={{ flex: 1, marginBottom: "20px" }}>
        <AlertComponent
          selectedTime={selectedTime}
          searchTrigger={searchTrigger}
          selectedYearValue={selectedYearValue} 
          selectedWeekValue={selectedWeekValue} 
          selectedMonthValue={selectedMonthValue}
          selectedFilterTag={selectedFilterTag}
        />
      </div>
    </div>
  );
};

export default Alert;