import { Button, Divider, FormControlLabel, Paper, Radio, RadioGroup, Typography } from "@material-ui/core";
import { AlertDropdownTime } from "./components";
import { useTranslation } from "react-i18next";

const SectionAlert = (props) => {
  const { t } = useTranslation();
  const handleRadioChange = (event) => {
    props.setSelectedTime(event.target.value);
  };

  const handleSearchClick = (event) => {
    props.setSearchTrigger((prev) => prev + 1);
  };

  return (
    <Paper style={{ flex: '1 1 0%', padding: "20px", textAlign: "center" }}>
      <Typography variant="h5">{t('PERIOD_DISPLAY_FORMAT')}</Typography>
      <Divider style={{ margin: "10px 0" }} />

      <div style={{ display: 'flex', alignItems: 'center'}}>

      <RadioGroup name="time" value={props.selectedTime} onChange={handleRadioChange} row>
        <FormControlLabel value="1" control={<Radio />} label={t('YEARLY')} />
        <FormControlLabel value="2" control={<Radio />} label={t('MONTHLY')} />
        <FormControlLabel value="3" control={<Radio />} label={t('WEEKLY')} />
      </RadioGroup>

      <AlertDropdownTime
        selectedTime={props.selectedTime}
        setSelectedYearValue={props.setSelectedYearValue}
        setSelectedWeekValue={props.setSelectedWeekValue}
        setSelectedMonthValue={props.setSelectedMonthValue}
        selectedYearValue={props.selectedYearValue}
        selectedWeekValue={props.selectedWeekValue}
        selectedMonthValue={props.selectedMonthValue}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleSearchClick} // You will need to define this function to perform the search
        style={{marginLeft: "10px"}}
      >
        {t('SEARCH')}
      </Button>

      </div>

    </Paper>
  );
};

export default SectionAlert;