import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Divider, FormControlLabel, Paper, Radio, RadioGroup, Select, Typography } from "@material-ui/core";
import ReactSelect from "react-select";
import i18n from "../../utils/i18n";
import { useTranslation } from "react-i18next";

const SelectDisplayDiseaseType = (props) => {
  const { t, i18n } = useTranslation();
  const [diseaseData, setDiseaseData] = useState([]);

  useEffect(() => {
    axios
      .get("/api/epidemic", {
        headers: {
          Authorization:
            "ya29.a0AfB_byClA5ly7MsmkrDp-tETkJ4ZrJUP_Gh7sNhANQUIV7QsUhDBO79HPA8Ukg1oiN10Sl1Zw1Fonw3hUBcNCA_vK0Z95k5zyXLTf4SpfCJcCgLyDc1dNShndjsXZSDoqIDASt7HJsTMqTLCNDxi0bDeyF7ozzUJUdW9aCgYKARkSARESFQGOcNnCzndUPp9S-7xiD1wMRErtsw0171",
        },
      })
      .then((response) => {
        if (response.data && Array.isArray(response.data.data)) {
          setDiseaseData(response.data.data);
          if (props.selectedOutputType === "2") {
            const uniqueDiseases = [];
            const uniqueIds = [];

            response.data.data.forEach((disease) => {
              if (!uniqueIds.includes(disease.epidem_group_id)) {
                uniqueDiseases.push(disease);
                uniqueIds.push(disease.epidem_group_id);
              }
            });

            setDiseaseData(uniqueDiseases);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching disease data:", error);
      });
  }, [props.selectedOutputType]);

  const handleRadioChange = (event) => {
    props.setSelectedOutputType(event.target.value);
    if (props.canSelectDisease) {
      props.setEpidemSelected([]);
    }
  };

  const handleSelectChange = (singleOrMultiVal) => {
    if (props.multiple) {
      props.setEpidemSelected(singleOrMultiVal.map((v) => v.value));
    } else {
      props.setEpidemSelected(singleOrMultiVal.value);
    }
  };

  const epidemOptions = useMemo(() => {
    if (!diseaseData) return [];
    if (props.selectedOutputType === "1") {
      // Individual disease
      return diseaseData.map((disease) => ({
        label: disease[`epidem_report_name_${i18n.language}`],
        value: disease.epidem_report_id,
      }));
    } else {
      // Group disease
      return diseaseData.map((disease) => ({
        label: disease[`epidem_group_name_${i18n.language}`],
        value: disease.epidem_group_id,
      }));
    }
  }, [diseaseData, props.selectedOutputType, i18n.language]);

  const epidemValueToOption = useMemo(() => {
    return epidemOptions.reduce(
      (obj, option) => ({
        ...obj,
        [option.value]: option,
      }),
      {}
    );
  }, [epidemOptions]);

  const epidemValues = useMemo(() => {
    if (props.multiple) {
      if (!props.epidemSelected) return [];
      return props.epidemSelected.map((value) => epidemValueToOption[value]);
    }
    if (!props.epidemSelected) return null;
    return epidemValueToOption[props.epidemSelected];
  }, [epidemValueToOption, props.epidemSelected, props.multiple]);

  return (
    <Paper style={{ flex: 1, margin: "0 10px", padding: "20px", textAlign: "center" }}>
      <Typography variant="h5">{t('DATA_DISPLAY_FORMAT')}</Typography>
      <Divider style={{ margin: "10px 0" }} />
      <RadioGroup name="display" value={props.selectedOutputType} onChange={handleRadioChange}>
        <FormControlLabel value="1" control={<Radio />} label={t('DISPLAY_EACH_DISEASE')} />
        <FormControlLabel
          value="2"
          control={<Radio />}
          label={t('DISPLAY_DISEASE_GROUPS')}
          disabled={props.disableRadio}
        />
      </RadioGroup>
      {props.canSelectDisease && (
        <>
          <Divider style={{ margin: "10px 0" }} />
          <ReactSelect
            placeholder={t('SELECT_DISEASE')}
            isMulti={props.multiple}
            value={epidemValues}
            onChange={handleSelectChange}
            options={epidemOptions}
            closeMenuOnSelect={!props.multiple}
          />
        </>
      )}
    </Paper>
  );
};

export default SelectDisplayDiseaseType;