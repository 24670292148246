import React, {useState, useMemo, useRef, useCallback, useEffect} from 'react';
import { formatNumber } from '@/modules/common/utils';
import { Divider, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, Select, Typography } from "@material-ui/core";
import ReactSelect from "react-select";
import { useSort } from '@/modules/common/hooks';
import { useTranslation } from 'react-i18next';
import { thToKey } from '@/modules/common/utils/i18n';
import { useKeyTranslate } from "@/modules/common/utils/useKeyTranslate";

const EpidemicReportTableMD5Nationality = ({ data, selectedDisplay, selectedCompareOption, selectedStatus, setSelectedStatus, selectedNationality, setSelectedNationality }) => {
    const FIELD_KEY = 'nationality';
    const hasMonthData = data.some(report => report.details.some(detail => 'month' in detail));
    const hasWeekData = data.some(report => report.details.some(detail => 'week' in detail));

    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const keyTranslate = useKeyTranslate(FIELD_KEY, t);

    const [localSortDir, setLocalSortDir] = useState({})

    const _groupValues = useMemo(() => Array.from(
        new Set(data.flatMap(report => report.details.map(detail => FIELD_KEY === 'gender' ? (detail.gender === 1 ? "ชาย" : "หญิง") : detail[FIELD_KEY] )))
    ), [data, FIELD_KEY]);

    // Construct group options
    const groupOptions = useMemo(() => {
      return _groupValues.map(item => {
        // By default all group values from backend will be in Thai (mostly)
        // These values need to be translated before showing as label in options
        let translated = keyTranslate(item);
        return { label: translated, value: item }
      });
    }, [_groupValues, keyTranslate]);

    const prevGroupField = useRef();
    const updateSelectedOptions = useCallback(() => {
      if(FIELD_KEY === prevGroupField.current) {
        // If group field is not changed, update selected with updated groupOptions
        // This may be come from switching language, etc.
        setSelectedNationality(selectedOptions => selectedOptions.map(selectedOption => {
          const found = groupOptions.find(groupOption => (
            groupOption.value === selectedOption.value
          ))
          if(found) return found;
          else return selectedOption;
        }))
      } else {
        // If group field is changed, select all options
        setSelectedNationality(groupOptions);
      }
      prevGroupField.current = FIELD_KEY;
    }, [FIELD_KEY, setSelectedNationality, groupOptions]);

    // Run update selected options when it's necessary
    useEffect(() => {
        updateSelectedOptions();
    }, [updateSelectedOptions]);

    const [allStatuses, setAllStatuses] = useState([{
        value: "infected_header",
        label: t('CASE')
    },
    {
        value: "deaths_header",
        label: t('DEAD')
    }]);

    const handleLanguageChanged = useCallback(() => {
        setAllStatuses([{
            value: "infected_header",
            label: t('CASE')
        },
        {
            value: "deaths_header",
            label: t('DEAD')
        }])
    }, []);
    
    useEffect(() => {
        i18n.on('languageChanged', handleLanguageChanged);
        return () => {
            i18n.off('languageChanged', handleLanguageChanged);
        };
    }, [handleLanguageChanged])

    useEffect(() => {
        let status = [];
        for (let i = 0; i<allStatuses.length; i++) {
            for(let j = 0; j<selectedStatus.length; j++) {
                if (allStatuses[i].value == selectedStatus[j].value) {
                    status.push(allStatuses[i])
                    break;
                }
            }
        }
        setSelectedStatus(status);
    }, [allStatuses])

    function flattenKeys(obj) {
        let resultString = '';

        function flatten(obj, prefix = '') {
            for (let key in obj) {
                if (!obj.hasOwnProperty(key)) continue;
    
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    flatten(obj[key], prefix + key + '_');
                } else {
                    resultString += `${prefix}${key}_${obj[key]} `;
                }
            }
        }
    
        flatten(obj);
        return resultString.trim(); // Remove trailing space 
    }

    const customReactSelectStyles = {
        menu: (base) => ({
          ...base,
          zIndex: 11, // Set your desired zIndex value
        }),
      };

    const handleChangeStatus = (values) => {
        setSelectedStatus(values);
    }

    const shouldRenderStatus = (header_id) => {
        return selectedStatus.some(status => status.value === header_id);
    };

    const handleChangeHeader = (values) => {
        setSelectedNationality(values);
    }

    const shouldRenderHeaderField = (header_id) => {
        return selectedNationality.some(header => header.value === header_id);
    };

    // Process and group data by year and dynamic fields
    const getTimePeriod = (detail) => {
        if ('week' in detail) {
            return `${detail.year}_${detail.week}`;
        } else if ('month' in detail) {
            return `${detail.year}_${detail.month}`;
        } else {
            return detail.year.toString();
        }
    };

    // Process and group data by dynamic time periods and fields
    const processAndGroupData = (data) => {
        // const timePeriods = new Set();
        // const dynamicFields = { gender: new Set(), age_range: new Set(), nationality: new Set(), area: new Set() };
        const labels = new Set();
        data.forEach(report => {
            report.details.forEach(detail => {
                // timePeriods.add(getTimePeriod(detail));
                // Object.keys(dynamicFields).forEach(field => {
                //     if (detail[field] !== undefined) {
                //         dynamicFields[field].add(detail[field]);
                //     }
                // });
                let timePeriod = getTimePeriod(detail);
                let field = FIELD_KEY
                let fieldValue;
                if (detail[field] !== undefined) {
                    fieldValue = detail[field];
                }
                detail.label = timePeriod+"+"+fieldValue;
                labels.add(detail.label);
            });
        });

       // return { timePeriods: Array.from(timePeriods), dynamicFields };
        return Array.from(labels);
    };

    let labels = processAndGroupData(data);
    //let {timePeriods, dynamicFields} = processAndGroupData(data);
    const thaiMonths = {
        'มกราคม': 1, 'กุมภาพันธ์': 2, 'มีนาคม': 3, 'เมษายน': 4, 
        'พฤษภาคม': 5, 'มิถุนายน': 6, 'กรกฎาคม': 7, 'สิงหาคม': 8, 
        'กันยายน': 9, 'ตุลาคม': 10, 'พฤศจิกายน': 11, 'ธันวาคม': 12
      };
      
      const getMonthNumber = (month) => {
        return thaiMonths[month] || parseInt(month, 10);
      };
      
    //   const sortArray = arr => {
    //     return arr.sort((a, b) => {
    //       const [yearA, monthA ] = a.split('_');
    //       const [yearB, monthB ] = b.split('_');
      
    //       const numericMonthA = getMonthNumber(monthA);
    //       const numericMonthB = getMonthNumber(monthB);
      
    //       if (numericMonthA === numericMonthB) {
    //         return parseInt(yearA, 10) - parseInt(yearB, 10);
    //       }
    //       return numericMonthA - numericMonthB;
    //     });
    //   };

    // if(selectedCompareOption === "2") {
    //     timePeriods = sortArray(timePeriods);
    // }

    function setColorClass(colormd5) {
        switch(colormd5) {
            case 1: return 'lightgrey';
            case 2: return 'green';
            case 3: return 'yellow';
            case 4: return 'orange';
            case 5: return 'red';
            default: return '';
        }
    }

    function setColorClassInfected(color20, color100, selectedDisplay) {
        if(selectedDisplay == "1") {
            switch(color20) {
                case 1: return 'lightgrey';
                case 2: return 'green';
                case 3: return 'yellow';
                case 4: return 'orange';
                case 5: return 'red';
                default: return ''; // no additional class
            }
        }

        if(selectedDisplay == "2") {
            switch(color100) {
                case 1: return 'lightgrey';
                case 2: return 'green';
                case 3: return 'yellow';
                case 4: return 'orange';
                case 5: return 'red';
                default: return ''; // no additional class
            }
        }
        
        return '';
    }

    const renderDataCells = (detail, key) => {
        if (key === 'infected' && detail != undefined && shouldRenderStatus('infected_header')) {
            return <td className={setColorClassInfected(detail.color20, detail.color100, selectedDisplay)}>{formatNumber(detail[key]) || 0}</td>;
        }
        else if (selectedDisplay === "3" && detail != undefined && key === 'median') {
            return <td className={setColorClass(detail.colormd5)}>{formatNumber(detail[key]) || 0}</td>;
        }
        else if (((key === 'death' && shouldRenderStatus('deaths_header')) || key === 'unknown')  && detail != undefined) {
            return <td>{formatNumber(detail[key]) || 0}</td>;
        }
        else if (selectedDisplay !== "3" && key !== 'infected' && key !== 'death') {
            return <td>0</td>;
        }
        
    };

      const removeNullKeys = (obj) => {
        if (typeof obj !== 'object' || obj === null) {
          // If obj is not an object or is null, return it as is
          return obj;
        }
      
        return Object.entries(obj).reduce((acc, [key, value]) => {
          if (value && typeof value === 'object') {
            acc[key] = removeNullKeys(value); // Recursively process nested objects
          } else if (value !== null) {
            acc[key] = value; // Keep non-null values
          }
          return acc;
        }, {});
      };

    const { sortedData, sortBy, sortDir, setSortBy, setSortDir } = useSort({ data });
    const getSortDetail = (headerCol, subHeaderCol) => {
        let detail;
        if(headerCol === null) {
            detail = subHeaderCol;
        } else {
            const splitTime = headerCol.split('_');
            if (splitTime.length >= 2) {
                detail = {
                    headerCol: {
                        year: splitTime[0].split('+')[0],
                        month_id: hasMonthData ? getMonthNumber(splitTime[1].split('+')[0]) : null,
                        week: hasWeekData ? getMonthNumber(splitTime[1].split('+')[0]) : null,
                        nationality: splitTime[1].split('+')[1]
                    },
                    subHeaderCol: subHeaderCol
                }
            } else {
                detail = {
                    headerCol: {
                        year: splitTime[0].split('+')[0],
                        nationality: splitTime[0].split('+')[1]
                    },
                    subHeaderCol: subHeaderCol
                }
            }
        }

        return detail;
    }

    const handleClickSubHeaderCol = (headerCol, subHeaderCol) => {
        const detail = removeNullKeys(getSortDetail(headerCol, subHeaderCol));

        if(!(flattenKeys(detail.headerCol) === flattenKeys(sortBy?.headerCol) && detail.subHeaderCol === sortBy?.subHeaderCol)) {
            setSortDir("asc");
        } else {
            setSortDir(dir => {
                
            if(dir === "asc") return "desc";
            else if(dir === "desc") return null;
            else return "asc";
            })
        }
        setSortBy(detail);
    }
    
    const getSortingColArrow = (headerCol, subHeaderCol) => {
        const detail = removeNullKeys(getSortDetail(headerCol, subHeaderCol));

        if(typeof(sortBy) === 'string' && typeof(detail) === 'string' && sortBy !== detail) return null;
        if(!(flattenKeys(sortBy?.headerCol) === flattenKeys(detail.headerCol) && sortBy?.subHeaderCol === detail.subHeaderCol)) return null;
        if(sortDir === "asc") return "↑";
        if(sortDir === "desc") return "↓";
        return null;
    }

    return (
        <div>
            <FormLabel style={{ display: "block", marginTop: "8px", marginBottom: "4px" }}>{t('STATUS_DISPLAY')}</FormLabel>
            <ReactSelect
                placeholder={t('STATUS_DISPLAY')}
                value={selectedStatus}
                onChange={handleChangeStatus}
                options={[
                {
                    value: "infected_header",
                    label: t('CASE')
                },
                {
                    value: "deaths_header",
                    label: t('DEAD')
                }
                ]}
                isMulti
                closeMenuOnSelect={false}
                styles={customReactSelectStyles}
            />
            <FormLabel style={{ display: "block", marginTop: "8px", marginBottom: "4px" }}>{t('NATIONALITY_DISPLAY')}</FormLabel>
            <ReactSelect
                placeholder={t('NATIONALITY_DISPLAY')}
                value={selectedNationality}
                onChange={handleChangeHeader}
                options={groupOptions}
                isMulti
                closeMenuOnSelect={false}
                styles={customReactSelectStyles}
            />
        <table id="epidemicReportTable">
            <thead>
                <tr>
                    <th>{t('EPIDEMIC')}</th>
                    {
                        labels.map(label => {
                            let [timePeriod, value] = label.split("+");
                            let field = FIELD_KEY;
                            let renderValue = t(thToKey[value.split(' - ')[1]]) || t(thToKey[value])
                            return  shouldRenderHeaderField(value) && (
                                <th key={`${field}-${value}-${timePeriod}`} colSpan={selectedDisplay === "3" ? (2 + selectedStatus.length) : (1 + selectedStatus.length)}>
                                    {hasWeekData ? `${timePeriod.replaceAll('_', ` ${t('WEEK')} `)} (${renderValue})` : hasMonthData ? `${timePeriod.split('_')[0]} ${t(thToKey[timePeriod.split('_')[1]])} (${renderValue})` : `${timePeriod.replaceAll('_', ' ')} (${renderValue})`} 
                                </th>
                                )
                        })

                    }
                    <th colSpan={selectedDisplay === "3" ? ((2 + selectedStatus.length)) : ((1+selectedStatus.length))}>{t('ALL')}</th>
                </tr>
                <tr>
                    <th></th>
                    {
                    labels.map(label => {
                        let [timePeriod, value] = label.split('+');
                        let field = FIELD_KEY;
                        return shouldRenderHeaderField(value) && (
                            <>
                                <th onClick={() => handleClickSubHeaderCol(label, 'flag')} key={`${field}-${value}-${timePeriod}-flag`}>{t('EPIDEMIC_ALERT_STATUS')}{getSortingColArrow(label, 'flag')}</th>
                                {selectedDisplay === "3" && <th onClick={() => handleClickSubHeaderCol(label, 'median')} key={`${field}-${value}-${timePeriod}-median`}>{t('PATIENTS_COMPARED_TO_5_YEAR_MEDIAN')}{getSortingColArrow(label, 'median')}</th>}
                                {shouldRenderStatus('infected_header') && <th onClick={() => handleClickSubHeaderCol(label, 'infected')} key={`${field}-${value}-${timePeriod}-infected`}>{t('CASE')}{getSortingColArrow(label, 'infected')}</th>}
                                {shouldRenderStatus('deaths_header') && <th onClick={() => handleClickSubHeaderCol(label, 'death')} key={`${field}-${value}-${timePeriod}-death`}>{t('DEAD')}{getSortingColArrow(label, 'death')}</th>}
                            </>
                            )
                        })         
                    }

                    {selectedDisplay === "3" && <th onClick={() => handleClickSubHeaderCol(null, 'totalMedian')} key={`totalMedian`}>{t('PATIENTS_COMPARED_TO_5_YEAR_MEDIAN')}{getSortingColArrow(null, 'totalMedian')}</th>}
                    {shouldRenderStatus('infected_header') && <th onClick={() => handleClickSubHeaderCol(null, 'totalInfected')} id='infected_header'>{t('CASE')}{getSortingColArrow(null, 'totalInfected')}</th>}
                    {shouldRenderStatus('deaths_header') && <th onClick={() => handleClickSubHeaderCol(null, 'totalDeath')} id='deaths_header'>{t('DEAD')}{getSortingColArrow(null, 'totalDeath')}</th>}
                </tr>
            </thead>
            <tbody>
            {
                sortedData.map(report => (
                    <tr key={report.epidem_report_id}>
                    <td>{currentLanguage == 'th' ? report.epidem_report_name_th: report.epidem_report_name_en}</td>
                    {
                        labels.map(label => {
                            const [timePeriod, value] = label.split('+');
                            let field = FIELD_KEY;
                            const detail = report.details.find(d => getTimePeriod(d) === timePeriod && d[field] === value);

                            return (
                                shouldRenderHeaderField(value) && (
                                    <React.Fragment key={`${field}-${value}-${timePeriod}`}>
                                    <td>{detail ? (detail.flag > 0 ? '!'.repeat(detail.flag) : '-') : '-'}</td>
                                    {selectedDisplay === "3" && <td className={setColorClass(detail ? detail.colormd5 : 0)}>
                                        {detail ? formatNumber(detail.median) || 0 : 0}
                                    </td>}
                                    {renderDataCells(detail, 'infected')}
                                    {renderDataCells(detail, 'death')}
                                    </React.Fragment>
                                )
                                ); 
                        })
                    }
                    {selectedDisplay === "3" &&  <td className={setColorClass(Math.max(...report.details.map(d => d.colormd5 || 0)))}>
                        {formatNumber(report.totalMedian) || 0}
                    </td>}
                    {shouldRenderStatus('infected_header') && <td className={setColorClassInfected(Math.max(...report.details.map(d => d.color20 || 0)), Math.max(...report.details.map(d => d.color100 || 0)), selectedDisplay)}>
                        {formatNumber(report.totalInfected)}
                    </td>}
                    {shouldRenderStatus('deaths_header') && <td>{formatNumber(report.totalDeath)}</td>}
                    </tr>
                ))
                }
            </tbody>
        </table>
        </div>
    );
};

export default EpidemicReportTableMD5Nationality;
