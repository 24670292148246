import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import axios from "axios";
import { Pie, Bar, Chart } from "react-chartjs-2";
import { AuthContext } from "@/App";
import { TableNotFound } from "@/modules/common/components";
import { TableSpecificDisease } from "./components";
import { useTranslation } from "react-i18next";

function SpecificDiseasePieChart({
  masterGenders,
  selectedYearValue,
  selectedWeekValue,
  selectedMonthValue,
  selectedEndYearValue,
  selectedEndWeekValue,
  selectedEndMonthValue,
  selectedTime,
  selectedOutputType,
  epidemSelected,
  selectedFilterTag,
  searchTrigger,
  selectedDisplay,
  selectedGenders,
  selectedPatientStatuses,
  selectedCompareOption,
}) {
  const { t } = useTranslation();
  const [jsonData, setJsonData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getTimeFrame = (time) => {
    if (time === "1") return "year";
    if (time === "2") return "month";
    if (time === "3") return "week";
    return "week";
  };

  useEffect(() => {
    setJsonData(null);
  }, [searchTrigger, selectedTime, epidemSelected, selectedOutputType, selectedCompareOption]);

  const { checkAndRedirect } = useContext(AuthContext);

  useEffect(() => {
    let timeoutId;
    checkAndRedirect()
      .then(() => {
        setIsLoading(true);
        setJsonData(null);

        timeoutId = setTimeout(() => {
          if (isLoading) {
            setIsLoading(false);
          }
        }, 5000);

        const requestBody = {
          timeFrame: getTimeFrame(selectedTime),
          startYear: parseInt(selectedYearValue),
          startWeek: parseInt(selectedWeekValue),
          startMonth: parseInt(selectedMonthValue),
          endYear: parseInt(selectedEndYearValue),
          endWeek: parseInt(selectedEndWeekValue),
          endMonth: parseInt(selectedEndMonthValue),
          epidemSelected: epidemSelected,
          filterTag: selectedFilterTag.key,
          isCompareOption: selectedCompareOption == 2
        };

        axios
          .post("/api/focus-epidemic", requestBody)
          .then((response) => {
            sortData(response.data);
            setJsonData(response.data);
          })
          .catch((error) => {
            setIsLoading(false);
            console.error("Error fetching data:", error);
          })
          .finally(() => {
            setIsLoading(false); // Stop loading
          });
      })
      .catch(() => {});
    return () => timeoutId && clearTimeout(timeoutId);
  }, [searchTrigger]);

  const sortData = (apiData) => {
    apiData.data.forEach((d) => {
      d.details.forEach((detail) => {
        let sortKey = 0;
        if (selectedCompareOption == 2) {
          if ("month" in detail) {
            sortKey = detail.year + detail.month_id * 10000;
          } else if ("week" in detail) {
            sortKey = detail.year + detail.week * 10000;
          }
        } else {
          if ("month" in detail) {
            sortKey = detail.year*100 + detail.month_id;
          } else if ("week" in detail) {
            sortKey = detail.year*100 + detail.week;
          }
        }
        detail.sortKey = sortKey;
      });
    });
    apiData.data.forEach((d) => {
        d.details.sort((a, b) => a.sortKey - b.sortKey);
    });
  }

  const processDataForPieChart = useCallback(
    (data) => {
      let sums = {
        infected: {},
        death: {},
        treated: {},
        unknown: {},
      };

      let filteredDetails = data.details;
      if (selectedGenders.length > 0) {
        filteredDetails = data.details.filter((detail) => {
          return selectedGenders.some((gender) => gender.value === detail.gender.toString());
        });
      }

      filteredDetails.forEach((detail) => {
        const key = masterGenders[detail.gender];

        if (!sums.infected[key]) {
          sums.infected[key] = 0;
          sums.death[key] = 0;
          sums.treated[key] = 0;
          sums.unknown[key] = 0;
        }

        sums.infected[key] += detail.infected;
        sums.death[key] += detail.death;
        sums.treated[key] += detail.treated;
        sums.unknown[key] += detail.unknown;
      });

      if(selectedPatientStatuses.length === 0) {
        return sums;
      }

      const finalSums = selectedPatientStatuses.reduce(
        (obj, status) => ({
          ...obj,
          [status.value]: sums[status.value],
        }),
        {}
      );

      finalSums.infected = sums.infected;

      return finalSums;
    },
    [selectedGenders, selectedPatientStatuses, masterGenders]
  );

  const processDataForBarChart = useCallback(
    (data) => {
      let barData = {
        labels: [],
        datasets: [],
        sumMap: {},
        timePeriodKeys: [],
      };

      const statuses = ["unknown", "death", "treated"];

      const statusToLabel = {
        infected: t('INFECTED'),
        death: t('DEAD'),
        treated: t('TREATED'),
        unknown: t('UNKNOWN'),
      };

      const getTimePeriod = (detail) => {
        let label, key;
        if ("week" in detail) {
          label = `${detail.year} สัปดาห์ที่ ${detail.week}`;
          key = `${detail.year}_${detail.week}`;
        } else if ("month" in detail) {
          label = `${detail.year} ${detail.month}`;
          key = `${detail.year}_${detail.month_id}`;
        } else {
          key = `${detail.year}`;
          label = `${detail.year}`;
        }
        return { key, label };
      };

      const timePeriodToLabel = new Map();

      let filteredDetails = data.details;
      if (selectedGenders.length > 0) {
        filteredDetails = data.details.filter((detail) => {
          return selectedGenders.some((gender) => gender.value === detail.gender.toString());
        });
      }

      filteredDetails.map((detail) => {
        const { key, label } = getTimePeriod(detail);
        timePeriodToLabel.set(key, label);
      });

      const allTimePeriodKeys = [...timePeriodToLabel.keys()];
      barData.labels = allTimePeriodKeys.map((key) => timePeriodToLabel.get(key));

      const genderStatusToMap = new Map();

      const statusToColor = {
        "treated": "rgba(129, 165, 67)",
        "death": "rgba(99,99,99)",
        "unknown": "rgba(117,53,120)"
      }

      const sumMap = {};

      filteredDetails.forEach((detail) => {
        statuses.forEach((status, index) => {
          if (selectedPatientStatuses.length > 0 && selectedPatientStatuses.every((patientStatus) => patientStatus.value !== status)) return;
          const key = `${detail.gender}_${status}`;
          if (!genderStatusToMap.has(key)) {
            const map = new Map();
            map.set("stack", detail.gender);
            map.set("backgroundColor", statusToColor?.[status]);
            map.set("timePeriodToValue", new Map());
            map.set("label", `${t(masterGenders[detail.gender])} - ${statusToLabel[status]}`);
            map.set("stackLabel", t(masterGenders[detail.gender]))
            genderStatusToMap.set(key, map);
          }

          const value = genderStatusToMap.get(key);
          const timePeriodToValue = value.get("timePeriodToValue");
          timePeriodToValue.set(getTimePeriod(detail).key, detail[status]);
        });
        sumMap[`${detail.gender}_${getTimePeriod(detail).key}`] = detail.infected;
      });

      genderStatusToMap.forEach((value, index) => {
        barData.datasets.push({
          stack: value.get("stack"),
          label: value.get("label"),
          stackLabel: value.get("stackLabel"),
          backgroundColor: value.get("backgroundColor"),
          data: allTimePeriodKeys.map((key) => value.get("timePeriodToValue").get(key)),
        });
      });

      barData.sumMap=sumMap;
      barData.timePeriodKeys = allTimePeriodKeys;

      return barData;
    },
    [masterGenders, selectedGenders, selectedPatientStatuses, t]
  );

  const barData = useMemo(() => {
    if (!jsonData || !jsonData?.data) return null;
    return processDataForBarChart(jsonData.data[0]);
  }, [jsonData, processDataForBarChart]);

  const sums = useMemo(() => {
    if (!jsonData || !jsonData?.data) return null;
    return processDataForPieChart(jsonData.data[0]);
  }, [jsonData, processDataForPieChart]);

  if (isLoading && searchTrigger > 0) {
    return (
      <div className="loading-overlay">
        <div className="spinner"></div>
      </div>
    ); // Replace with your spinner component or styling
  }

  if (!jsonData || !jsonData.data || !Array.isArray(jsonData.data)) {
    return <TableNotFound />;
  }

  const pieChartStyle = {
    width: "400px",
    height: "230px",
    margin: "20px",
    textAlign: "center",
  };

  const genderToPieColor = {
    MALE: "rgba(19,21,128)",
    FEMALE: "rgba(164,39,143)"
  };

  const getColorForKey = (key) => {
    return genderToPieColor[key];
  };

  const renderPieChart = (category) => {
    let labels = Object.keys(sums[category]);
    let values = labels.map((label) => sums[category][label]);

    const categoryMap = { infected: t('INFECTED'), death: t('DEAD'), treated: t('TREATED'), unknown: t('UNKNOWN') };

    // Use the getColorForKey function to assign colors
    let colors = labels.map((label) => getColorForKey(label));

    const dataForPieChart = {
      labels: labels.map(t),
      datasets: [
        {
          data: values,
          backgroundColor: colors,
        },
      ],
    };

    return (
      <div style={pieChartStyle}>
        {/* <h3>{category.charAt(0).toUpperCase() + category.slice(1)}</h3> */}
        <h3>{categoryMap[category]}</h3>
        <Pie
          data={dataForPieChart}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
          }}
        />
      </div>
    );
  };

  const barChartStyle = {
    width: "100%",
    minHeight: "400px",
    margin: "20px",
    textAlign: "center",
  };

  const textPlugin = {
    beforeDraw: function(chart) {
      const ctx = chart.ctx;
      ctx.clearRect(0, 0, chart.width, chart.height);
    },
    afterDraw: function(chart) {
      if (chart.config.type === 'bar') {
        const ctx = chart.ctx;
        ctx.fillStyle = '#444'; // text color
        ctx.font = 'regular 8px Sukhumvit';
        ctx.textBaseline = 'middle';
  
        const excludedLabels = [t('TREATED'), t('DEAD'), t('UNKNOWN')];
        chart.data.datasets.forEach(function(dataset, datasetIndex) {
          const meta = chart.getDatasetMeta(datasetIndex);
          meta.data.forEach(function(bar, index) {
            const label = dataset.stackLabel;
            if (!excludedLabels.includes(label)) {
              ctx.save();
              ctx.translate(bar._model.x, chart.chartArea.top + 60); // Adjust vertical position
              ctx.rotate(-Math.PI / 2); // Rotate text
              ctx.fillText(label, 0, 0);
              ctx.restore();
            }
          });
        });
      }
    }
  };

  const renderBarChart = (barData) => {
    let highestValue = 0;
    barData.datasets.forEach((dataset, datasetIndex) => {
      dataset.data.forEach((value, index) => {
        if (value > highestValue) {
          highestValue = value;
        }
     
      });
      
    });
    
    // Increase the highest value by 80%
    const maxYValue = highestValue * 1.8;
  
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: true,
        mode: 'point',
        callbacks: {
          label: function(tooltipItem, data) {
            const datasetIndex = tooltipItem.datasetIndex;
            const index = tooltipItem.index;
            const dataset = data.datasets[datasetIndex];
            const key = `${dataset.stack}_${data.timePeriodKeys[index]}`;
            const sum = data.sumMap[key];
            return `${t('ALL')} ${sum} | ${dataset.label} ${data.datasets[datasetIndex].data[index]}`
          }
        }
      },
     legend: {
    display: true,
    position: 'right',
    labels: {
      generateLabels: function(chart) {
        const labelsMap = {}; // Object to keep track of unique labels

        return chart.data.datasets.map(dataset => {
          // Split the label by the last hyphen and use the part after it
          const splitIndex = dataset.label.lastIndexOf('-');
          const legendLabel = splitIndex !== -1 ? dataset.label.substring(splitIndex + 1).trim() : dataset.label;

          if (!labelsMap[legendLabel]) {
            labelsMap[legendLabel] = true; // Mark this label as processed
            return {
              text: legendLabel,
              fillStyle: dataset.backgroundColor,
              // Additional properties as needed
            };
          }
          return null; // Skip duplicate labels
        }).filter(label => label !== null); // Remove null entries (duplicates)
      }
    }
  },
      layout: {
        padding: {
          left: 20, // Adjust as needed
          right: 20 // Adjust as needed
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              max: maxYValue, // Set the maximum y-axis value
            },
            scaleLabel: {
              display: true,
              labelString: t('QUANTITY'),
            },
          },
        ],
        xAxes: [
          {
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: t('TIME_PERIOD'),
            },
          },
        ],
      }
    };
  
    return (
      <div style={barChartStyle}>
        <Bar data={barData} options={options} plugins={[textPlugin]} />
      </div>
    );
  };

  function capitalize(str) {
    if (!str) return str; // return original string if it's empty or not a string
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const allValues = Array.from(
    new Set(
      Object.entries(sums).flatMap(([status, statusObj]) => {
        return Object.keys(statusObj);
      })
    )
  );

  return (
    <div>
      <div style={{ width: "100%", overflowX: "scroll", maxHeight: "800px" }}>
        <TableSpecificDisease
          masterGenders={masterGenders}
          data={jsonData.data}
          tag={capitalize(selectedFilterTag.key)}
          selectedDisplay={selectedDisplay}
          selectedGenders={selectedGenders}
          selectedPatientStatuses={selectedPatientStatuses}
        />
      </div>

      <div style={{ width: "100%", padding: "10px" }}>{barData && renderBarChart(barData)}</div>
      <div style={{ display: "flex", flexDirection: "row", minHeight: "400px", width: "100%", overflowX: "auto" }}>
        
        <div style={{ width: "100%", display: "flex", flexDirection: "column", flexWrap: "wrap", position: "relative" }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginBottom: "20px" }}>
            {sums &&
              Object.keys(sums)
                .slice(0, 2)
                .map((category) => renderPieChart(category))}
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            {sums &&
              Object.keys(sums)
                .slice(2)
                .map((category) => renderPieChart(category))}
          </div>
          <div style={{ border: '1px solid black', padding: '16px', position: "absolute", top: "50%", left: "50%", transform: "translate(360px, -50%)" }}>
            {allValues.map((value) => (
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <div style={{ backgroundColor: genderToPieColor[value], width: "16px", height: "16px" }} />
                <div>{t(value)}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpecificDiseasePieChart;
